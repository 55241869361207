import { Logger } from '_common/services';
import ReduxInterface from '../ReduxInterface/ReduxInteface';
import { CommandFactory } from './Commands';
import { Descendant } from 'slate';
import { ClipboardManager } from '../Clipboard';
import { NormalManipulator, SuggestionManipulator } from './Manipulators';
import { ELEMENTS } from '../consts';
import { EditorDOMElements, EditorDOMUtils } from '../_Common/DOM';
import { EditorSelectionUtils } from '../_Common/Selection';
import { NodeDataBuilder } from '../DataManager';
import DOMNormalizer from '../DOMUtilities/DOMNormalizer/DOMNormalizer';
import HistoryManager from '../HistoryManager/HistoryManager';
import { StylesManager } from './Managers';

// interface EditionEvents {
//   //? edition events
// }

class CommandQueue {
  protected running: boolean;
  protected commandsList: Editor.Edition.Command[];

  constructor() {
    this.commandsList = [];
    this.running = false;
  }

  protected async execCommands() {
    this.running = true;

    while (this.commandsList.length) {
      const command = this.commandsList.shift();
      if (command) {
        await command.exec();
      }
    }

    this.running = false;
  }

  clear() {
    this.commandsList = [];
  }

  addCommand(command: Editor.Edition.Command) {
    this.commandsList.push(command);
    if (!this.running) {
      this.execCommands();
    }
  }
}

export default class EditionManager {
  private commandFactory: Editor.Edition.CommandFactory;
  private context: Editor.Edition.Context;

  private commandQueue: CommandQueue;

  constructor() {
    this.keyDown = this.keyDown.bind(this);
    this.keyUp = this.keyUp.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
    this.mouseOver = this.mouseOver.bind(this);
    this.mouseDown = this.mouseDown.bind(this);
    this.cut = this.cut.bind(this);
    this.copy = this.copy.bind(this);
    this.paste = this.paste.bind(this);
    this.drop = this.drop.bind(this);
    this.input = this.input.bind(this);
    this.compositionEnd = this.compositionEnd.bind(this);

    this.context = {
      plataformInfo: ReduxInterface.getPlatformInfo(),
      editionMode: 'DISABLED',
      debug: true,
      untrackedActionWarning: this.untrackedActionWarning.bind(this),
    };

    this.commandFactory = new CommandFactory(this.context);
    this.context.commandFactory = this.commandFactory;

    this.commandQueue = new CommandQueue();
  }

  private setupEventListeners() {
    if (this.context.eventsManager) {
      this.context.eventsManager.addListener('KEY_DOWN', this.keyDown);
      this.context.eventsManager.addListener('KEY_UP', this.keyUp);
      this.context.eventsManager.addListener('KEY_PRESS', this.keyPress);
      this.context.eventsManager.addListener('MOUSE_DOWN', this.mouseDown);
      this.context.eventsManager.addListener('MOUSE_UP', this.mouseUp);
      this.context.eventsManager.addListener('MOUSE_OVER', this.mouseOver);
      this.context.eventsManager.addListener('PASTE', this.paste);
      this.context.eventsManager.addListener('CUT', this.cut);
      this.context.eventsManager.addListener('COPY', this.copy);
      this.context.eventsManager.addListener('DROP', this.drop);
      this.context.eventsManager.addListener('INPUT', this.input);
      this.context.eventsManager.addListener('COMPOSITION_END', this.compositionEnd);
    }
  }

  private removeEventListeners() {
    if (this.context.eventsManager) {
      this.context.eventsManager.removeListener('KEY_DOWN', this.keyDown);
      this.context.eventsManager.removeListener('KEY_UP', this.keyUp);
      this.context.eventsManager.removeListener('KEY_PRESS', this.keyPress);
      this.context.eventsManager.removeListener('MOUSE_DOWN', this.mouseDown);
      this.context.eventsManager.removeListener('MOUSE_UP', this.mouseUp);
      this.context.eventsManager.removeListener('MOUSE_OVER', this.mouseOver);
      this.context.eventsManager.removeListener('PASTE', this.paste);
      this.context.eventsManager.removeListener('CUT', this.cut);
      this.context.eventsManager.removeListener('COPY', this.copy);
      this.context.eventsManager.removeListener('DROP', this.drop);
      this.context.eventsManager.removeListener('INPUT', this.input);
      this.context.eventsManager.removeListener('COMPOSITION_END', this.compositionEnd);
    }
  }

  start(editorContext: Editor.Context) {
    this.context.documentContainer = editorContext.documentContainer;
    this.context.navigationManager = editorContext.navigationManager;
    this.context.documentParser = editorContext.documentParser;
    this.context.eventsManager = editorContext.eventsManager;
    this.context.DataManager = editorContext.DataManager;
    this.context.VisualizerManager = editorContext.visualizerManager;
    this.context.selection = editorContext.selection;

    this.context.stylesHandler = editorContext.stylesHandler;

    if (editorContext.DataManager) {
      this.context.stylesManager = new StylesManager(this.context);
      this.context.stylesManager.initialize();
    }

    if (
      editorContext.documentContainer &&
      editorContext.stylesHandler &&
      editorContext.DataManager &&
      editorContext.visualizerManager &&
      editorContext.documentParser
    ) {
      this.context.clipboard = new ClipboardManager(
        editorContext.documentContainer,
        editorContext.stylesHandler,
        editorContext.DataManager,
        editorContext.visualizerManager,
        editorContext.documentParser,
      );
    }

    //? this.selectionManager = editorContext.selectionManager;
    //? this.changeTracker = editorContext.changeTracker;

    //? edition events controller ???

    // //! TEMP testing
    // editorContext.DataManager?.on(
    //   'SELECTION_UPDATED',
    //   (ranges: Editor.Selection.RangeData[], source: Realtime.Core.RealtimeSourceType) => {
    //     if (source === 'LOCAL_RENDER' || source === 'LOCAL_RENDER_OLD' || source === true) {
    //       if (ranges[0]) {
    //         const baseModel = editorContext.DataManager?.nodes.getNodeModelById(ranges[0].start.b);
    //         const baseData = baseModel?.selectedData();
    //         if (baseData && this.context.stylesManager) {
    //           const styles = this.context.stylesManager.getStylesAppliedToContent(
    //             baseData,
    //             ranges[0].start.p,
    //             this.context.stylesManager.STYLES,
    //           );
    //         }
    //       }
    //     }
    //   },
    // );

    this.setupEventListeners();

    if (this.context.debug) {
      Logger.info('EditionManager version 2 started!');
    }
  }

  destroy() {
    this.removeEventListeners();
    if (this.context.clipboard) {
      this.context.clipboard.destroy();
    }
    if (this.context.stylesManager) {
      this.context.stylesManager.destroy();
    }
  }

  setEditionMode(mode: Editor.Edition.Mode) {
    switch (mode) {
      case 'NORMAL':
        this.context.editionMode = 'NORMAL';
        // set content manipulator
        this.context.contentManipulator = new NormalManipulator(this.context);
        break;
      case 'SUGGESTIONS':
        this.context.editionMode = 'SUGGESTIONS';
        // set content manipulator
        this.context.contentManipulator = new SuggestionManipulator(this.context);
        break;
      case 'DISABLED':
        this.context.editionMode = 'DISABLED';
        // remove content manipulator
        delete this.context.contentManipulator;
        break;
    }
  }

  /**
   * @deprecated temporary function for retro compatibility remove after refactor and use setEditionMode directly
   */
  disableEdition() {
    this.setEditionMode('DISABLED');
  }

  /**
   * @deprecated temporary function for retro compatibility remove after refactor and use setEditionMode directly
   */
  enableSuggestionMode() {
    this.setEditionMode('SUGGESTIONS');
  }

  /**
   * @deprecated temporary function for retro compatibility remove after refactor and use setEditionMode directly
   */
  enableNormalMode() {
    this.setEditionMode('NORMAL');
  }

  protected untrackedActionWarning() {
    if (this.context.editionMode === 'SUGGESTIONS') {
      return window.confirm('This action will not be tracked. Proceed?');
    }
    return true;
  }

  // #################################################
  //                  event handlers
  // #################################################
  private keyDown(event: KeyboardEvent) {
    if (this.context.debug) {
      Logger.trace('EditionManager keyDown', event);
    }

    if (!this.commandFactory.isKeyDefaultAllowed(event)) {
      event.preventDefault();
      event.stopPropagation();

      // this if is here just because firefox in linux is an idiot sometimes
      const lastKeyDown = this.context.lastKeyDown;
      if (
        !lastKeyDown ||
        (lastKeyDown !== 'Dead' && lastKeyDown !== 'Undifined' && lastKeyDown !== 'Unidentified')
      ) {
        let command = this.commandFactory.getCommand('KEYDOWN', event);

        if (command) {
          // let action = new ActionContext(event);

          // command.exec();
          this.commandQueue.addCommand(command);
          return true;
        }
      }

      const platform = this.context.plataformInfo;
      if (platform.os.linux && platform.browser.firefox) {
        this.context.lastKeyDown = event.key;
      }
    }
  }

  private keyUp(event: KeyboardEvent) {
    // nothing to do here
  }

  private keyPress(event: KeyboardEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  private mouseUp(eventData: Parameters<Editor.Events.EventTypes['MOUSE_UP']>[0]) {
    const target = eventData.event.target;
    if (target instanceof Node) {
      const closest = EditorDOMUtils.closest(target, [ELEMENTS.HyperlinkElement.TAG, 'IMG']);
      if (
        closest?.nodeName === 'IMG' &&
        eventData.mousedownTarget === target &&
        this.context.editionMode !== 'DISABLED'
      ) {
        // closest image

        this.context.VisualizerManager?.selection.stopSelectionTracker();
        try {
          EditorSelectionUtils.selectNode(closest);
          this.context.VisualizerManager?.selection.triggerSelectionChanged();
        } finally {
          this.context.VisualizerManager?.selection.debounceStartSelectionTracker();
        }
      } else if (EditorDOMElements.isHyperlinkElement(closest)) {
        // closest hyperlink

        const platform = this.context.plataformInfo;
        if (
          (!platform.os.mac && eventData.event.ctrlKey) ||
          (platform.os.mac && eventData.event.metaKey)
        ) {
          // EditorManager.getInstance().openHyperlink();
          const data = EditorSelectionUtils.getLinkData();

          if (data?.url) {
            const urlIsExternal =
              data.url
                .replace('http://', '')
                .replace('https://', '')
                .split('/')[0]
                .toLowerCase() !==
              window.location.host
                .replace('http://', '')
                .replace('https://', '')
                .split('/')[0]
                .toLowerCase();

            if (urlIsExternal) {
              ReduxInterface.openAndUpdateModal({
                modal: 'OpenHyperlinkModal',
                data: {
                  url: data.url,
                },
              });
            } else {
              window.open(data.url, '_blank');
            }
          }
        }
      }
    }
  }

  private mouseOver(eventData: Parameters<Editor.Events.EventTypes['MOUSE_OVER']>[0]) {
    eventData.event.preventDefault();
  }

  private mouseDown(event: MouseEvent) {
    if (this.context.editionMode !== 'DISABLED' && event.target instanceof HTMLElement) {
      const tableOptions = event.target.closest('[data-id="table-options-container"]');
      if (!tableOptions) {
        const td = EditorDOMUtils.closest(event.target, ELEMENTS.TableCellElement.TAG);
        if (event.button === 2) {
          if (event.shiftKey) {
            const platform = this.context.plataformInfo;
            if (platform.os.mac) {
              const range = EditorSelectionUtils.getRange();
              if (range && this.context.selection?.modifiers) {
                this.context.selection.modifiers.modify(range, 'move', 'word', 'backward');
                this.context.selection.modifiers.modify(range, 'expand', 'word', 'forward');
                EditorSelectionUtils.applyRangeToSelection(range);
              }
            }
          } else {
            if (EditorDOMElements.isTableCellElement(td) && td.isSelected) {
              return;
            }
          }
        }

        const closestTable = EditorDOMUtils.closest(td, ELEMENTS.TableElement.TAG);
        const closestApproval = EditorDOMUtils.closest(td, ELEMENTS.ApprovedElement.TAG);
        if (
          EditorDOMElements.isTableCellElement(td) &&
          EditorDOMElements.isTableElement(closestTable) &&
          !closestApproval
        ) {
          closestTable.handleCellMouseDown(event, td);
        }
      }
    }
  }

  private cut(event: ClipboardEvent) {
    event.preventDefault();
    event.stopPropagation();

    let commandFactory = this.context.commandFactory;
    if (!commandFactory) {
      return false;
    }

    let command = commandFactory.getCommand('CUT', event);
    if (command) {
      // command.exec();
      this.commandQueue.addCommand(command);
      return true;
    }
    return false;
  }

  private copy(event: ClipboardEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (this.context.navigationManager?.isMarkerRendered() && event.clipboardData) {
      this.context.VisualizerManager?.selection.stopSelectionTracker();

      try {
        this.context.clipboard?.removePasteOptions();

        this.context.clipboard?.handleCopy(event.clipboardData);
      } catch (error) {
        Logger.captureException(error);
      } finally {
        this.context.VisualizerManager?.selection.debounceStartSelectionTracker();
      }
    }
  }

  private isClipboardEvent(event: ClipboardEvent | DragEvent | null): event is ClipboardEvent {
    return event instanceof ClipboardEvent;
  }

  private isDragEvent(event: ClipboardEvent | DragEvent | null): event is DragEvent {
    return event instanceof DragEvent;
  }

  private paste(
    event: ClipboardEvent | DragEvent | null,
    pasteOptionsStyle?: Editor.Clipboard.PasteOptions,
    dataTransfer?: DataTransfer | null,
  ) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    let data: DataTransfer | null = null;
    if (this.isClipboardEvent(event)) {
      data = event?.clipboardData;
    } else if (this.isDragEvent(event)) {
      data = event?.dataTransfer;
    } else if (dataTransfer) {
      data = dataTransfer;
    }

    // build clipboard parser
    if (data) {
      this.context.clipboard?.handlePaste(data);
    }

    if (this.context.clipboard) {
      const pasteFunction = (parsedData: Editor.Clipboard.ParsedJsonData) => {
        if (!pasteOptionsStyle) {
          this.context.clipboard?.removePasteOptions();
          pasteOptionsStyle = ClipboardManager.ORIGINAL_STYLES;
        }

        let commandFactory = this.context.commandFactory;
        if (!commandFactory) {
          return false;
        }

        let command = commandFactory.getCommand('PASTE', parsedData, pasteOptionsStyle);
        if (command) {
          this.commandQueue.addCommand(command);
          // command.exec();
        }
        setTimeout(() => {
          ReduxInterface.stopEditorLoading();
        }, 0);
      };

      if (this.context.clipboard.hasParsersAvailable()) {
        const numbLines = this.context.clipboard.countAvailableContent();

        if (numbLines != null && numbLines < 50) {
          // get parsed data
          this.context.clipboard
            .getParsedJsonData(pasteOptionsStyle)
            .then((parsedData) => pasteFunction(parsedData))
            .catch((error) => {
              Logger.captureException(error);
              setTimeout(() => {
                ReduxInterface.stopEditorLoading();
              }, 0);
            });
        } else {
          ReduxInterface.startEditorLoading('LOADING_PASTED_CONTENT');
          setTimeout(() => {
            this.context.clipboard
              ?.getParsedJsonData(pasteOptionsStyle)
              .then((parsedData) => pasteFunction(parsedData))
              .catch((error) => {
                Logger.captureException(error);
                setTimeout(() => {
                  ReduxInterface.stopEditorLoading();
                }, 0);
              });
          }, 0);
        }
      }
    }
  }

  private drop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.paste(event, undefined);
  }

  private input(event: Event) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const editorRange = EditorSelectionUtils.getRange();
    if (editorRange) {
      const anchorNode = editorRange.startContainer;
      const anchorOffset = editorRange.startOffset;
      const level0Node = EditorDOMUtils.findFirstLevelChildNode(
        EditorDOMUtils.getContentContainer(anchorNode),
        anchorNode,
      );

      //* clean unwanted HTML
      let nodeToCheck: Node | null = anchorNode;
      while (nodeToCheck !== level0Node) {
        if (nodeToCheck) {
          if (
            nodeToCheck instanceof Element &&
            !DOMNormalizer.isAllowedUnder(nodeToCheck.tagName, nodeToCheck.parentElement?.nodeName)
          ) {
            while (nodeToCheck.firstChild) {
              nodeToCheck.parentElement?.insertBefore(nodeToCheck.firstChild, nodeToCheck);
            }
            EditorSelectionUtils.setCaret(anchorNode, 'INDEX', anchorOffset);
            this.context.VisualizerManager?.selection.triggerSelectionChanged();
            const parent: Node | null = nodeToCheck.parentNode;
            nodeToCheck.remove();
            nodeToCheck = parent;
          } else {
            nodeToCheck = nodeToCheck.parentNode;
          }
        }
      }
    }

    if (
      event instanceof InputEvent &&
      !event.isComposing &&
      event.inputType !== 'insertCompositionText'
    ) {
      // if (this.isInlineInsertionAllowed(level0Node, anchorNode, anchorOffset)) {
      this.compositionEnd(event);
      // } else if (event.data) {
      //   // delete data
      //   anchorNode.deleteData(anchorOffset - event.data.length, event.data.length);
      //   DOMNormalizer.normalizeChildren(anchorNode.parentNode);
      // }
    }
  }

  private compositionEnd(event: Event) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('COMPOSITION', event);
    if (command) {
      // command.exec();
      this.commandQueue.addCommand(command);
      return true;
    }
    return false;
  }

  // #################################################
  //         content manager event handlers
  // #################################################
  handleRemoveSelection(deleteOptions: Editor.Edition.DeleteOptions = {}) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('DELETE_CONTENT', deleteOptions);

    if (command) {
      command.exec();
      return true;
    }
    return false;
  }

  //@ts-expect-error evaluate if function is needed
  handleSaveChanges(actionContext) {
    // TODO: evaluate if this function is needed
    logger.warn('Not yet implemented!!');
  }

  // #################################################
  //              insert element handlers
  // #################################################
  insertPageBreak() {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const data = NodeDataBuilder.buildData({
      type: ELEMENTS.PageBreakElement.ELEMENT_TYPE,
    });

    if (data) {
      this.insertInlineElement(data, { forceBlockSplit: true, forceTextAsWrap: true });
    }
  }

  insertSectionBreak(type: Editor.Data.Sections.SectionType) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const data = NodeDataBuilder.buildData({
      type: ELEMENTS.SectionBreakElement.ELEMENT_TYPE,
      properties: {
        sect: type,
      },
    });

    if (data) {
      this.insertInlineElement(data, { forceBlockSplit: true, forceTextAsWrap: true });
    }
  }

  insertColumnBreak() {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const data = NodeDataBuilder.buildData({
      type: ELEMENTS.ColumnBreakElement.ELEMENT_TYPE,
    });

    if (data) {
      this.insertInlineElement(data, { forceBlockSplit: true, forceTextAsWrap: true });
    }
  }

  insertInlineElement(
    dataToInsert: Editor.Data.Node.Data | string,
    options?: Editor.Edition.InsertElementOptions,
  ) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let data: Editor.Data.Node.Data | undefined;
    if (typeof dataToInsert === 'string') {
      data = NodeDataBuilder.buildData({
        type: ELEMENTS.Text.ELEMENT_TYPE,
        content: dataToInsert,
      });
    } else {
      data = dataToInsert;
    }

    if (data) {
      let command = this.commandFactory.getCommand('INSERT_INLINE');
      if (command) {
        command.exec(data, options);
        return true;
      }
    }
    return false;
  }

  async insertTable(rows: number, columns: number) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('INSERT_TABLE', rows, columns);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  insertTableOfContents(options: TableOfContents.TOCSectionProperties) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const builder = new NodeDataBuilder(ELEMENTS.TableOfContentsElement.ELEMENT_TYPE)
      .addProperty('rl', options.representingLevels)
      .addProperty('spn', options.showPageNumber)
      .addProperty('anr', options.alignNumberRight)
      .addProperty('l', options.useLink)
      .addProperty('tabs', [{ t: 'r', l: options.leader, v: 0 }]);

    const data = builder.build();

    if (data) {
      this.insertBlockElement(data, { pathAfterInsert: 'START' });
    }
  }

  insertTableOfLabels(options: TableOfContents.TOLSectionProperties) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const builder = new NodeDataBuilder(ELEMENTS.TableOfLabelsElement.ELEMENT_TYPE)
      .addProperty('cpt', options.label)
      .addProperty('spn', options.showPageNumber)
      .addProperty('anr', options.alignNumberRight)
      .addProperty('l', options.useLink)
      .addProperty('tabs', [{ t: 'r', l: options.leader, v: 0 }]);

    const data = builder.build();

    if (data) {
      this.insertBlockElement(data, { pathAfterInsert: 'START' });
    }
  }

  insertListOfFigures() {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const data = NodeDataBuilder.buildData({
      type: ELEMENTS.ListOfFiguresElement.ELEMENT_TYPE,
    });

    if (data) {
      this.insertBlockElement(data, { pathAfterInsert: 'START' });
    }
  }

  insertListOfTables() {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const data = NodeDataBuilder.buildData({
      type: ELEMENTS.ListOfTablesElement.ELEMENT_TYPE,
    });

    if (data) {
      this.insertBlockElement(data, { pathAfterInsert: 'START' });
    }
  }

  insertKeywordsElement() {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const data = NodeDataBuilder.buildData({
      type: ELEMENTS.KeywordsElement.ELEMENT_TYPE,
    });

    if (data) {
      this.insertBlockElement(data, { pathAfterInsert: 'START' });
    }
  }

  insertAuthorsElement() {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const data = NodeDataBuilder.buildData({
      type: ELEMENTS.AuthorsElement.ELEMENT_TYPE,
    });

    if (data) {
      this.insertBlockElement(data, { pathAfterInsert: 'START' });
    }
  }

  insertReferenceSectionElement() {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    // build ref section
    const data = NodeDataBuilder.buildData({
      type: ELEMENTS.ReferencesSectionElement.ELEMENT_TYPE,
    });

    if (data) {
      this.insertBlockElement(data, { pathAfterInsert: 'START' });
    }
  }

  insertBlockElement(data: Editor.Data.Node.Data, options?: Editor.Edition.InsertBlockOptions) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('INSERT_BLOCK', options);
    if (command) {
      command.exec(data);
      return true;
    }
    return false;
  }

  // #################################################
  //              fields Handling
  //            captions / cross ref
  // #################################################

  insertCaptionElement(options: Editor.Edition.CaptionCommandOptions) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('INSERT_CAPTION', options);
    if (command) {
      command.exec();
      return true;
    }
    return false;
  }

  editCaptionElement(options: Editor.Edition.CaptionCommandOptions) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('EDIT_CAPTION', options);
    if (command) {
      command.exec();
      return true;
    }
    return false;
  }

  insertCrossReferenceElement(options: Editor.Data.CrossReferences.PresentationTextOptionsType) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('INSERT_CROSSREF', options);
    if (command) {
      command.exec();
      return true;
    }
    return false;
  }

  editCrossReferenceElement(options: Editor.Data.CrossReferences.PresentationTextOptionsType) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('EDIT_CROSSREF', options);
    if (command) {
      command.exec();
      return true;
    }
    return false;
  }

  updateCrossReferenceElement() {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('UPDATE_CROSSREF');
    if (command) {
      command.exec();
      return true;
    }
    return false;
  }

  // #################################################
  //              Tables Operations Handling
  // #################################################
  async handleDeleteCells(shiftCells: Editor.Edition.ShiftCellsType = 'SHIFT_LEFT') {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const args: Editor.Edition.TableOperationArgsType = {
      operation: 'DELETE_CELLS',
      shiftCells,
    };

    let command = this.commandFactory.getCommand('TABLE_OPERATION', args);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleDeleteRows() {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const args: Editor.Edition.TableOperationArgsType = {
      operation: 'DELETE_ROWS',
    };

    let command = this.commandFactory.getCommand('TABLE_OPERATION', args);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleDeleteRowAtIndex(index: number[]) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const args: Editor.Edition.TableOperationArgsType = {
      operation: 'DELETE_ROWS_INDEX',
      index,
    };

    let command = this.commandFactory.getCommand('TABLE_OPERATION', args);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleDeleteColumns() {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const args: Editor.Edition.TableOperationArgsType = {
      operation: 'DELETE_COLUMNS',
    };

    let command = this.commandFactory.getCommand('TABLE_OPERATION', args);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleDeleteColumnAtIndex(index: number[]) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const args: Editor.Edition.TableOperationArgsType = {
      operation: 'DELETE_COLUMNS_INDEX',
      index,
    };

    let command = this.commandFactory.getCommand('TABLE_OPERATION', args);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleDeleteTable() {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('DELETE_TABLE');

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleInsertRow(before: boolean = false) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const args: Editor.Edition.TableOperationArgsType = {
      operation: 'INSERT_ROW',
      before,
    };

    let command = this.commandFactory.getCommand('TABLE_OPERATION', args);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleInsertRowAtIndex(index: number[]) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const args: Editor.Edition.TableOperationArgsType = {
      operation: 'INSERT_ROW_INDEX',
      index,
    };

    let command = this.commandFactory.getCommand('TABLE_OPERATION', args);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleInsertColumn(before = false) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const args: Editor.Edition.TableOperationArgsType = {
      operation: 'INSERT_COLUMN',
      before,
    };

    let command = this.commandFactory.getCommand('TABLE_OPERATION', args);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleInsertColumnAtIndex(index: number[]) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const args: Editor.Edition.TableOperationArgsType = {
      operation: 'INSERT_COLUMN_INDEX',
      index,
    };

    let command = this.commandFactory.getCommand('TABLE_OPERATION', args);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleMergeCells() {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const args: Editor.Edition.TableOperationArgsType = {
      operation: 'MERGE_CELLS',
    };

    let command = this.commandFactory.getCommand('TABLE_OPERATION', args);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleSplitCells(
    splitColumns: number = 2,
    splitRows: number = 1,
    mergeCells: boolean = false,
  ) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    const args: Editor.Edition.TableOperationArgsType = {
      operation: 'SPLIT_CELLS',
      splitColumns,
      splitRows,
      mergeCells,
    };

    let command = this.commandFactory.getCommand('TABLE_OPERATION', args);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  /**
   *
   * @param {*} table
   * @param {*} columnWidths in points (pt)
   */
  async handleUpdateColumnWidths(
    table: Editor.Elements.TableElement,
    columnWidths: Editor.Edition.ColumnWidths,
  ) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('UPDATE_COLUMN_WIDTHS', table, columnWidths);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  /**
   *
   * @param table
   * @param rowHeights in points (pt)
   */
  async handleUpdateRowHeigths(table: Editor.Elements.TableElement, rowHeights: number[]) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('UPDATE_ROW_HEIGTHS', table, rowHeights);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleUpdateTableSize(
    table: Editor.Elements.TableElement,
    height: number,
    width: Editor.Data.Node.TableWidth,
  ) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('UPDATE_TABLE_SIZE', table, height, width);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleUpdateTableProperties(propertiesData: Editor.Styles.TablePropertiesData) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('UPDATE_TABLE_PROPERTIES', propertiesData);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleSortRows(sortType: Editor.Edition.SortType) {
    const args: Editor.Edition.TableOperationArgsType = {
      operation: 'SORT_ROWS',
      sortType,
    };

    let command = this.commandFactory.getCommand('TABLE_OPERATION', args);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleDistribute(distributeType: Editor.Edition.DistributeType) {
    const args: Editor.Edition.TableOperationArgsType = {
      operation: 'DISTRIBUTE',
      distributeType,
    };

    let command = this.commandFactory.getCommand('TABLE_OPERATION', args);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  // #################################################
  //              Images Operations Handlers
  // #################################################
  async handleInsertImage(image: File) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('INSERT_IMAGE', image);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleChangeImage(image: File) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('CHANGE_IMAGE', image);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleUpdateImageSize(
    image: Editor.Elements.FigureElement | Editor.Elements.ImageElement,
    height: number,
    width: number,
  ) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('UPDATE_IMAGE_SIZE', image.id, height, width);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleUpdateImageProperties(properties: Editor.Styles.ImageProperties) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('UPDATE_IMAGE_PROPERTIES', properties);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  // #################################################
  //                Citations handlers
  // #################################################
  async handleInsertCitation(citationId: string) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('INSERT_CITATION', citationId);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  // #################################################
  //                Notes handlers
  // #################################################
  async handleInsertNote(type: Notes.NoteType, text: string) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('INSERT_NOTE', type, text);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  // #################################################
  //              Hyperlink handlers
  // #################################################
  async handleInsertHyperlink(
    links: string[],
    url: string,
    showTextToDisplay: boolean = false,
    textToDisplay: string = '',
  ) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand(
      'INSERT_HYPERLINK',
      links,
      url,
      showTextToDisplay,
      textToDisplay,
    );

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleDeleteHyperlink() {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('DELETE_HYPERLINK');

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  // #################################################
  //              Comments handlers
  // #################################################
  async handleAddComment(comment: Descendant[]) {
    let command = this.commandFactory.getCommand('ADD_COMMENT', comment);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleAddTemporaryComment() {
    let command = this.commandFactory.getCommand('ADD_TEMPORARY_COMMENT');

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleRemoveTemporaryComment() {
    let command = this.commandFactory.getCommand('REMOVE_TEMPORARY_COMMENT');

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  // #################################################
  //              Equation handlers
  // #################################################
  async handleInsertEquation(value: any) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('INSERT_EQUATION', value);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleEditEquation() {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('EDIT_EQUATION');

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  async handleUpdateEquation(blockId: string, elementId: string, value: any) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand('UPDATE_EQUATION', blockId, elementId, value);

    if (command) {
      await command.exec();
      return true;
    }
    return false;
  }

  // #################################################
  //            copy/paste functions
  // #################################################
  removePasteOptions() {
    this.context.clipboard?.removePasteOptions();
  }

  updatePasteOptions() {
    this.context.clipboard?.updatePasteOptions();
  }

  async handlePasteOptions(pasteOptions: Editor.Clipboard.PasteOptions) {
    this.context.clipboard?.removePasteOptions();

    await HistoryManager.getInstance()
      .undo({
        showNotifications: false,
      })
      .then(() => {
        this.paste(null, pasteOptions);
      });
  }

  // #################################################
  //           block properties functions
  // #################################################

  async indentCurrentSelection(indentation: Editor.Elements.IndentationProperties) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand(
      'UPDATE_BLOCK_PROPERTIES',
      'INDENTATION_CURRENT_SELECTION',
      { indentation },
    );

    if (command) {
      await command.exec();
    }
  }

  async applyParagraphBackgroundColor(value: string | null) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand(
      'UPDATE_BLOCK_PROPERTIES',
      'APPLY_PARAGRAPH_BACKGROUND_COLOR',
      {
        paragraphBackgroundColor: value,
      },
    );

    if (command) {
      await command.exec();
    }
  }

  async setLinePaginationProperties(properties: Editor.Elements.PaginationProperties) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand(
      'UPDATE_BLOCK_PROPERTIES',
      'SET_LINE_PAGINATION_PROPERTIES',
      {
        pagination: properties,
      },
    );

    if (command) {
      await command.exec();
    }
  }

  async lineSpaceCurrentSelection(spacing: Editor.Elements.SpacingProperties) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand(
      'UPDATE_BLOCK_PROPERTIES',
      'LINE_SPACE_CURRENT_SELECTION',
      {
        lineSpacing: spacing,
      },
    );

    if (command) {
      await command.exec();
    }
  }

  async alignCurrentSelection(properties: Editor.Elements.TextAlignProperties) {
    if (this.context.editionMode === 'DISABLED') {
      return false;
    }

    let command = this.commandFactory.getCommand(
      'UPDATE_BLOCK_PROPERTIES',
      'ALIGN_CURRENT_SELECTION',
      {
        alignment: properties,
      },
    );

    if (command) {
      await command.exec();
    }
  }

  // #################################################
  //                  styles functions
  // #################################################
  toggleStyleValue<T extends Editor.Edition.Styles>(
    style: T,
    value: Exclude<Editor.Edition.StylesMap[T], undefined>,
  ) {
    return this.context.stylesManager?.toggleStyleValue(style, value);
  }

  removeStyleValue<T extends Editor.Edition.Styles>(
    style: T,
    value?: Exclude<Editor.Edition.StylesMap[T], undefined>,
  ) {
    return this.context.stylesManager?.removeStyleValue(style, value);
  }

  // #################################################
  //              other functions
  // #################################################
}
