export const cloneObject = <T extends object | undefined>(
  obj: T,
): T extends undefined ? undefined : T => {
  if (obj === undefined) {
    return undefined as T extends undefined ? undefined : T;
  }

  return JSON.parse(JSON.stringify(obj));
};

export const indexedModEffects = (effects?: Presentation.Data.Effect[]) =>
  effects?.reduce<Presentation.Data.IndexedEffect>((parsedEffects, effect) => {
    // @ts-expect-error TS has trouble assigning the effect into a specific sub-type
    parsedEffects[effect.type] = effect;

    return parsedEffects;
  }, {}) ?? {};
