import { CSSProperties, useMemo } from 'react';
import { useShapeData } from './ShapeData';
import { useSlideData } from '../SlideData';

const useTextBodyProperties = (text: Presentation.Data.TextBody) => {
  const { addUnsupportedElement } = useSlideData();
  const { shape } = useShapeData();

  return useMemo(() => {
    /*
     * Sources: 
        - ECMA part 1 p3123 - 20.4.2.22 bodyPr (Body Properties)
        - ECMA part 1 p3173 - 21.1.2.1.1 bodyPr (Body Properties)

     * Supported properties:
     *  - anchor
     *  - anchorCtr
     *  - bIns
     *  - lIns
     *  - rIns
     *  - rot
     *  - tIns
     *  - vert (with missing implemetations):
     *    - eaVert
     *    - mongolianVert
     *    - wordArtVert
     *    - wordArtVertRtl
     *  - wrap
     *  - noAutofit
     *  - normAutofit
      

     * TODO:PRESENTATION:TEXT Unsupported properties:
     *  - compatLnSpc
     *  - forceAA
     *  - fromWordArt
     *  - horzOverflow
     *  - numCol
     *  - rtlCol
     *  - spcCol
     *  - spcFirstLastPara
     *  - upright
     *  - vertOverflow
     *  - txAutoFit:
     *    - spAutoFit (it seems that the ppt already gives the dimensions of the shape scaled to fit, 
     *      but if it doesnt we need to do it ourselves)
     */
    const style: CSSProperties = {};

    if (text?.bodyPr) {
      //#region Alignment
      /*
       * If 'anchor' is omitted, a value of 't', or 'top' is implied
       */
      if (text.bodyPr.anchor) {
        switch (text.bodyPr.anchor) {
          case 't':
            style.justifyContent = 'flex-start';
            break;
          case 'b':
            style.justifyContent = 'flex-end';
            break;
          case 'ctr':
            style.justifyContent = 'center';
            break;
          case 'just':
            style.justifyContent = 'space-evenly';
            break;
          case 'dist':
            style.justifyContent = 'space-evenly';
            //TODO:PRESENTATION:UNSUPPORTED:TEXT
            addUnsupportedElement('Text - Alignment - Distributed');
            break;
        }
      } else {
        style.justifyContent = 'flex-start';
      }

      if (text.bodyPr.anchorCtr) {
        style.alignItems = 'center';
      }
      //#endregion

      //#region Spacing
      let paddingTop = text.bodyPr.tIns;
      let paddingRight = text.bodyPr.rIns;
      let paddingBottom = text.bodyPr.bIns;
      let paddingLeft = text.bodyPr.lIns;

      if (shape.type !== 'table' && !shape.id.includes('-chart')) {
        //If 'tIns' is omitted, a value of 45720 or 0.05 inches is implied
        paddingTop = paddingTop ?? 4.8;
        //If 'rIns' is omitted, a value of 91440 or 0.1 inches is implied
        paddingRight = paddingRight ?? 9.6;
        //If 'bIns' is omitted, a value of 45720 or 0.05 inches is implied
        paddingBottom = paddingBottom ?? 4.8;
        //If 'lIns' is omitted, a value of 91440 or 0.1 inches is implied
        paddingLeft = paddingLeft ?? 9.6;
      }
      //#endregion

      //#region Wrapping
      if (text.bodyPr?.txAutoFit?.normalAutoFit) {
        style.overflowWrap = 'break-word';
      }

      if (text.bodyPr.wrap === 'none') {
        style.overflowWrap = 'unset'; //Not sure if correct
      } else {
        //wrap 'square'
        style.overflowWrap = 'break-word';
      }
      //#endregion

      //#region Rotation
      const shapeRotation = shape.properties.xfrm?.rot ?? 0;
      let textRotation = 0;
      let unsupportedVert = '';

      if (text.bodyPr.vert != null) {
        const shapeWidth = shape.properties.xfrm?.ext?.cx ?? 0;
        const shapeHeight = shape.properties.xfrm?.ext?.cy ?? 0;

        switch (text.bodyPr.vert) {
          case 'horz': {
            textRotation = shapeRotation;
            break;
          }
          case 'vert': {
            if (shapeRotation) {
              textRotation = shapeRotation;
              style.writingMode = 'vertical-lr';
            } else {
              if (shapeHeight > shapeWidth) {
                textRotation = 0;
              } else {
                textRotation = 90;
              }
            }

            const delta = textRotation - shapeRotation;

            if (delta === 0 || delta === 360) {
              style.writingMode = 'vertical-lr';
            }

            break;
          }
          case 'vert270': {
            if (shapeRotation) {
              textRotation = 180 + shapeRotation;
            } else {
              if (shapeHeight > shapeWidth) {
                textRotation = 180;
              } else {
                textRotation = 270;
              }
            }

            const delta = textRotation - shapeRotation;

            if (delta === 180 || delta === -180) {
              style.writingMode = 'vertical-lr';
              const originalPaddingTop = paddingTop;
              const originalPaddingRight = paddingRight;

              paddingTop = paddingBottom;
              paddingBottom = originalPaddingTop;
              paddingRight = paddingLeft;
              paddingLeft = originalPaddingRight;
            }
            break;
          }
          case 'eaVert': {
            //TODO:PRESENTATION:TEXT Proccess this value
            break;
          }
          case 'mongolianVert': {
            //TODO:PRESENTATION:TEXT Proccess this value
            break;
          }
          case 'wordArtVert': {
            unsupportedVert = 'Stacked';
            //TODO:PRESENTATION:TEXT Proccess this value
            break;
          }
          case 'wordArtVertRtl': {
            unsupportedVert = 'Stacked Right-to-left';
            //TODO:PRESENTATION:TEXT Proccess this value
            break;
          }
        }

        if (unsupportedVert) {
          //TODO:PRESENTATION:UNSUPPORTED:TEXT
          addUnsupportedElement(`Text - Direction - ${unsupportedVert}`);
        }
      } else {
        textRotation = shapeRotation;
      }
      //#endregion

      //#region Effect - Transform (Unsupported)
      if (text.bodyPr.wrap === 'square' && text.bodyPr.prstTxWarp) {
        //TODO:PRESENTATION:UNSUPPORTED:TEXT:EFFECTS
        addUnsupportedElement(`Text Effect - Transform`);
      }
      //#endregion

      //#region Effect - Bevel (Unsupported)
      if (text.bodyPr.tx3d?.sp3d?.bevelT || text.bodyPr.tx3d?.sp3d?.bevelB) {
        //TODO:PRESENTATION:UNSUPPORTED:TEXT:EFFECTS
        addUnsupportedElement(`Text Effect - Bevel`);
      }
      //#endregion

      //#region Effect - Perspective (Unsupported)
      if (
        text.bodyPr.scene3d?.camera.prst &&
        text.bodyPr.scene3d.camera.prst !== 'orthographicFront'
      ) {
        //TODO:PRESENTATION:UNSUPPORTED:TEXT:EFFECTS
        addUnsupportedElement(`Text Effect - Perspective`);
      }
      //#endregion

      style.transform = `
        rotate(${textRotation}deg) 
        scale(${shape.properties.xfrm?.flipV ? -1 : 1}, ${shape.properties.xfrm?.flipH ? -1 : 1})
      `;
      style.paddingTop = paddingTop;
      style.paddingRight = paddingRight;
      style.paddingBottom = paddingBottom;
      style.paddingLeft = paddingLeft;
    }

    if (text.bodyPr?.numCol && text.bodyPr.numCol > 1) {
      //TODO:PRESENTATION:UNSUPPORTED:TEXT
      addUnsupportedElement(`Text - Columns`);
    }

    return style;
  }, [text]);
};

export default useTextBodyProperties;
