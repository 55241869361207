import { useEffect, useMemo, useRef } from 'react';
import { CellMeasurer, CellMeasurerCache, List, ListRowProps } from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';

import DynamicHeightCard from './DynamicHeightCard/DynamicHeightCard';
import VirtualizedListContextProvider from './VirtualizedListContext';
import type { CellMeasurerChildProps } from 'react-virtualized/dist/es/CellMeasurer';

export type VirtualizedListProps = {
  itemCount: number;
  selectedIndex?: number;
  itemMinHeight?: number;
  itemRenderer: (
    params: Pick<ListRowProps, 'index'> & Pick<CellMeasurerChildProps, 'measure'>,
  ) => JSX.Element;
};

const VirtualizedList = ({
  selectedIndex,
  itemCount,
  itemMinHeight,
  itemRenderer,
}: VirtualizedListProps) => {
  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        fixedWidth: true,
        minHeight: itemMinHeight,
      }),
    [itemMinHeight],
  );

  const listRef = useRef<List>(null);
  useEffect(() => {
    if ((selectedIndex ?? -1) >= 0) {
      // Double scrollToRow will make sure card will be displayed (Not 100% accurate if only 1 scrollTo)
      listRef.current?.scrollToRow(selectedIndex);
      //Allow list to rerender cards after initial scrollTo, second scrollTo will move to the correct card
      setTimeout(() => {
        listRef.current?.scrollToRow(selectedIndex);
      }, 0);
    }
  }, [selectedIndex]);

  const rowRenderer = ({ index, key, style, parent }: ListRowProps) => {
    return (
      <CellMeasurer cache={cache} columnIndex={0} key={key} rowIndex={index} parent={parent}>
        {({ measure, registerChild }) => (
          <DynamicHeightCard style={style} measure={measure} registerChild={registerChild}>
            {itemRenderer({ index, measure })}
          </DynamicHeightCard>
        )}
      </CellMeasurer>
    );
  };

  return (
    <AutoSizer>
      {({ height }) => (
        <VirtualizedListContextProvider>
          <List
            ref={listRef}
            deferredMeasurementCache={cache}
            rowHeight={cache.rowHeight}
            width={360}
            height={height}
            overscanRowCount={5}
            rowCount={itemCount}
            rowRenderer={rowRenderer}
            style={{ padding: '1rem 3rem 2rem 1rem' }} //Extra right padding to avoid card hiding behind scroll
          />
        </VirtualizedListContextProvider>
      )}
    </AutoSizer>
  );
};

export default VirtualizedList;
