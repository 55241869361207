import { FormattedMessage } from 'react-intl';
import { Tab } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';

import { setSidebarPanelTab } from 'Editor/redux/SidebarSlice';

import { PanelContent, PanelHeading } from '_common/suite/components';
import { InteractionController } from '_common/components';

import CommentTab from './CommentTab/CommentTab';
import TrackedActionTab from './TrackedActionTab/TrackedActionTab';

import styles from './ReviewTab.module.scss';

const ReviewTab = () => {
  const dispatch = useDispatch();

  const tab = useSelector((state) => state.editor.sidebar.tabs.review);

  const onCommentsClick = () => {
    dispatch(setSidebarPanelTab({ view: 'review', tab: 'comments' }));
  };
  const onSuggestionsClick = () => {
    dispatch(setSidebarPanelTab({ view: 'review', tab: 'changes' }));
  };

  return (
    <InteractionController environment="editor" style={{ flexDirection: 'column', height: '100%' }}>
      <PanelHeading>
        <FormattedMessage id="editor.menu.review.label" />
      </PanelHeading>
      <PanelContent padding="1.5rem 2rem" testId="review-panel">
        <div className={styles.tabs} data-testid="review-panel-tabs-container">
          <InteractionController
            environment="editor"
            rules={[
              {
                interaction: 'editor_tabmenu_comments',
                actions: ['editor_comments_openSidePanel'],
              },
            ]}
          >
            <span
              style={{ width: '100%', height: '100%', position: 'relative' }}
              id="sidebar-review-comments-tab"
            >
              <Tab
                fullWidth
                selected={tab === 'comments'}
                onClick={onCommentsClick}
                testId="comments-tab"
              >
                <FormattedMessage id="COMMENTS" />
              </Tab>
            </span>
          </InteractionController>
          <Tab
            fullWidth
            selected={tab === 'changes'}
            onClick={onSuggestionsClick}
            testId="track-changes-tab"
          >
            <FormattedMessage id="TRACK_CHANGES" />
          </Tab>
        </div>
        {tab === 'comments' ? <CommentTab /> : <TrackedActionTab />}
      </PanelContent>
    </InteractionController>
  );
};

export default ReviewTab;
