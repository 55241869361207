import { RealtimeOpsBuilder } from '_common/services/Realtime';
import { BaseOperation } from '../BaseOperation';

export class RemoveBlockOperation extends BaseOperation<Editor.Data.Structure.Model> {
  private elementId: string;
  private editionContext: Editor.Edition.Context;

  constructor(
    editionContext: Editor.Edition.Context,
    model: Editor.Data.Structure.Model,
    elementId: string,
  ) {
    super(model);

    this.elementId = elementId;
    this.editionContext = editionContext;

    this.build();
  }

  protected build(): Editor.Edition.IOperationBuilder {
    const structureData = this.model.selectedData();

    if (!structureData) {
      return this;
    }

    const structureNodes = structureData.childNodes;

    const elementIndex = structureNodes.indexOf(this.elementId);

    if (elementIndex != null && elementIndex >= 0) {
      // remove the node from the structure
      this.ops.push(RealtimeOpsBuilder.listDelete(this.elementId, ['childNodes', elementIndex]));
      // check if we have block properties, something might have gone wrong
      if (structureData.blkProps.hasOwnProperty(this.elementId)) {
        const props = structureData.blkProps[this.elementId];
        this.ops.push({
          od: props,
          p: ['blkProps', this.elementId],
        });
        // we might need to override the style list definition with an invalid inline
        // to remove a block from an outline. This check has to stay here
        if (props.lst && props.lst.lId && structureData.lists[props.lst.lId]) {
          const listPosition = structureData.lists[props.lst.lId].n.indexOf(this.elementId);
          if (listPosition >= 0) {
            if (structureData.lists[props.lst.lId].n.length === 1) {
              this.ops.push({
                p: ['lists', props.lst.lId],
                od: structureData.lists[props.lst.lId],
              });
            } else {
              this.ops.push({
                p: ['lists', props.lst.lId, 'n', listPosition],
                ld: this.elementId,
              });
            }
          }
        }
      }
    }

    this.getBlockAdjustedPosition(this.editionContext, this.elementId);

    return this;
  }

  getBlockAdjustedPosition(context: Editor.Edition.Context, nodeModelId: string): void {
    // get the previous nodeModel
    const previousModel = context.DataManager?.nodes.getPreviousModelById(nodeModelId);

    if (previousModel) {
      const selectedData = previousModel.selectedData();

      if (selectedData && selectedData.childNodes) {
        const index = selectedData.childNodes.length;

        this.posOpPosition = {
          b: previousModel.id,
          p: ['childNodes', index],
        };
      }
    } else {
      // get the next nodeModel
      const nextModel = context.DataManager?.nodes.getNextModelById(nodeModelId);

      if (nextModel) {
        this.posOpPosition = {
          b: nextModel.id,
          p: ['childNodes', 0],
        };
      }
    }
  }
}
