import { MouseEventHandler, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from '_common/hooks';
import { setSelectedCard, setSelectedShape } from 'Presentation/PresentationSlice';

import TextBody from './TextBody/TextBody';
import ShapeDataProvider from './ShapeData';
import ShapeBackground from './ShapeBackground';
import ShapeForeignObject from './ShapeForeignObject';
import { usePresentationManager } from 'Presentation/PresentationManager';
import { useSlideData } from '../SlideData';

type ShapeProps = {
  shape: Presentation.Data.Shape;
};

const CommonShape = ({ shape }: ShapeProps) => {
  const dispatch = useDispatch();
  const manager = usePresentationManager();
  const { isThumbnailSlide, addUnsupportedElement } = useSlideData();

  const selectedShape = useSelector((state) => state.presentation.general.selectedShape);
  const selectedCard = useSelector((state) => state.presentation.general.selectedCard);

  const handleSelectShape: MouseEventHandler<SVGGElement> = (e) => {
    //Only allow selection of slide shapes (skip thumbnail)
    if (isThumbnailSlide || shape.origin !== 'slide') {
      return;
    }

    e.stopPropagation();
    if (selectedShape !== shape.id) {
      manager.clearSelection();
      dispatch(setSelectedShape(shape.id));
    }

    if (selectedCard) {
      dispatch(setSelectedCard(null));
    }
  };

  useEffect(() => {
    if (shape.useBgFill) {
      addUnsupportedElement('Shape - Shape Background Fill');
    }
  }, [shape]);

  const hasText = useMemo(
    () =>
      (shape.text?.childNodes?.reduce((totalPChildren, p) => {
        if (p.childNodes?.length) {
          return totalPChildren + p.childNodes.length;
        }

        return totalPChildren;
      }, 0) ?? 0) > 0,
    [shape.text],
  );

  return (
    <ShapeDataProvider shape={shape}>
      <g
        id={isThumbnailSlide ? `thumbnail-${shape.id}` : `${shape.origin}-${shape.id}`}
        //Required for PresentationRange to get the original shape id
        data-id={shape.id}
        data-type="shape"
        onMouseDown={handleSelectShape}
      >
        <ShapeBackground />
        {shape.text && hasText && (
          <ShapeForeignObject>
            <TextBody text={shape.text} />
          </ShapeForeignObject>
        )}
      </g>
    </ShapeDataProvider>
  );
};

export default CommonShape;
