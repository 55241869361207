import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'dodoc-design-system';
import { useModalData } from 'App/ModalContext/ModalContext';
import { closeModal } from 'App/ModalContext/utils';

const GenericConfirmationModal = () => {
  const modalData = useModalData('ConfirmationModal');
  if (!modalData) {
    return null;
  }

  const {
    persistent,
    modalWidth,
    headerType,
    title,
    titleValues,
    message,
    messageValues,
    messageChildren,
    confirmButtonType,
    confirmButtonTextId,
    confirmButtonTextValues,
    cancelButtonTextId,
    cancelButtonTextValues,
    onConfirm,
  } = modalData;

  const close = () => {
    closeModal('ConfirmationModal');
  };

  const confirm = () => {
    onConfirm();
    close();
  };

  return (
    <Modal
      open
      onClose={persistent ? undefined : close}
      width={modalWidth}
      type={headerType}
      testId="confirmation-modal"
    >
      <Modal.Header onClose={persistent ? undefined : close}>
        <FormattedMessage id={title} values={titleValues} />
      </Modal.Header>
      <Modal.Body>
        {messageChildren || <FormattedMessage id={message} values={messageValues} />}
      </Modal.Body>
      <Modal.Footer>
        {!persistent && (
          <Button size="medium" onClick={close} testId="confirm-modal-close-button">
            <FormattedMessage id={cancelButtonTextId} values={cancelButtonTextValues} />
          </Button>
        )}
        <Button
          size="medium"
          variant={confirmButtonType}
          onClick={confirm}
          testId="confirm-modal-submit-button"
        >
          <FormattedMessage id={confirmButtonTextId} values={confirmButtonTextValues} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GenericConfirmationModal;
