import { ELEMENTS } from 'Editor/services/consts';
import { DOMProcess } from './DOM';
import {
  PageElement,
  SectionBreakElement,
  SectionElement,
  ApprovedViewElement,
  ParagraphElement,
  TableElement,
} from '../../Views';
import { JsonRange } from 'Editor/services/_Common/Selection';
import { BaseViewModel } from '../BaseViewModel';
import { HeaderViewModel } from '../HeaderViewModel';
import { FooterViewModel } from '../FooterViewModel';
import { BlockViewModel } from '..';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';

export class BreakToken {
  node: HTMLElement;
  offset: number;
  protected break?: any;
  constructor(node: HTMLElement, offset: number = 0) {
    this.node = node;
    this.offset = offset;
  }

  needsBreak() {
    if (this.isSectionBreak()) {
      return this.node.dataset.sect !== ELEMENTS.SectionBreakElement.TYPES.CONTINUOUS;
    }
    if (this.isPageBreak()) {
      return true;
    }
    return false;
  }

  isSectionBreak() {
    return this.node && this.node.nodeName === 'SECTION-BREAK-ELEMENT';
  }

  getNextSection(): string | undefined {
    if (this.isSectionBreak()) {
      return (this.node as SectionBreakElement).nextSection;
    }
    return undefined;
  }

  isPageBreak() {
    return this.node && this.node.nodeName === 'PAGE-BREAK-ELEMENT';
  }
}
export class LayoutResult {
  overflow?: HTMLElement[];
  error?: Error;
  breakToken?: BreakToken;
  constructor(overflow?: HTMLElement[], error?: Error, breakToken?: BreakToken) {
    this.overflow = overflow;
    this.error = error;
    this.breakToken = breakToken;
  }

  get success() {
    return !this.error;
  }

  get shouldBreak() {
    return !!this.overflow || this.breakToken?.needsBreak();
  }

  static success(breakToken: BreakToken, overflow?: HTMLElement[]) {
    return new LayoutResult(overflow, undefined, breakToken);
  }

  static error(error?: Error) {
    return new LayoutResult(undefined, error);
  }
}

function* walk(start: HTMLElement | undefined, limiter: HTMLElement) {
  let value: {
    node: HTMLElement | undefined;
    previous: HTMLElement | undefined;
  } = {
    node: start,
    previous: undefined,
  };

  while (value.node) {
    yield value;

    if (value.node.childNodes.length) {
      value.node = value.node.firstChild as HTMLElement;
    } else if (value.node.nextSibling) {
      if (limiter && value.node === limiter) {
        value.previous = value.node;
        value.node = undefined;
        break;
      }
      value.previous = value.node;
      value.node = value.node.nextSibling as HTMLElement;
    } else {
      while (value.node) {
        value.previous = value.node;
        value.node = value.node.parentNode as HTMLElement;
        if (limiter && value.node === limiter) {
          value.previous = value.node;
          value.node = undefined;
          break;
        }
        if (value.node && value.node.nextSibling) {
          value.previous = value.node;
          value.node = value.node.nextSibling as HTMLElement;
          break;
        }
      }
    }
  }
}

function* words(node: HTMLElement) {
  let currentText = node.nodeValue;
  let max = currentText?.length || 0;
  let currentOffset = 0;
  let currentLetter;

  let range;
  const significantWhitespaces = node.parentElement && node.parentElement.nodeName === 'PRE';

  while (currentOffset < max) {
    currentLetter = currentText?.[currentOffset];
    if (currentLetter && (/^[\S\u202F\u00A0]$/.test(currentLetter) || significantWhitespaces)) {
      if (!range) {
        range = document.createRange();
        range.setStart(node, currentOffset);
      }
    } else {
      if (range) {
        range.setEnd(node, currentOffset);
        yield range;
        range = undefined;
      }
    }

    currentOffset += 1;
  }

  if (range) {
    range.setEnd(node, currentOffset);
    yield range;
  }
}

function* letters(wordRange: Range) {
  let currentText = wordRange.startContainer as Text;
  let max = currentText.length;
  let currentOffset = wordRange.startOffset;
  // let currentLetter;

  let range;

  while (currentOffset < max) {
    // currentLetter = currentText[currentOffset];
    range = document.createRange();
    range.setStart(currentText, currentOffset);
    range.setEnd(currentText, currentOffset + 1);

    yield range;

    currentOffset += 1;
  }
}

export class PageViewModel extends BaseViewModel<PageElement> {
  typeName = 'PageViewModel';

  number: number = 0;
  breakToken?: BreakToken;
  header?: HeaderViewModel;
  footer?: FooterViewModel;
  constructor(Data: Editor.Data.API, Visualizer: Editor.Visualizer.State, sectionId?: string) {
    super(Data, Visualizer);
    this.view = Visualizer.viewFactory?.getPageView(sectionId);
    if (this.view) {
      (this.view as Editor.Visualizer.BaseView).vm = this;
      if (sectionId) {
        this.appendHeaderAndFooter(sectionId);
      }
    }
  }

  get isFirstPage() {
    return this.number === 1;
  }

  position(): 'LEFT' | 'RIGHT' {
    return this.number % 2 === 0 ? 'LEFT' : 'RIGHT';
  }

  side(): 'VERSO' | 'RECTO' {
    return this.number % 2 === 0 ? 'VERSO' : 'RECTO';
  }

  private extractContentFromTable(view: TableElement, range: Range) {
    if (!this.view) {
      return null;
    }

    const startContainer = range.startContainer;
    const startOffset = range.startOffset;
    let overflowRow: HTMLTableRowElement | null = null;

    if (startContainer instanceof HTMLTableSectionElement) {
      overflowRow = startContainer.rows[startOffset];
    } else if (startContainer instanceof HTMLTableRowElement) {
      overflowRow = startContainer;
    } else {
      const closest = EditorDOMUtils.closest(startContainer, ['TR']);
      if (closest instanceof HTMLTableRowElement) {
        overflowRow = closest;
      }
    }

    if (overflowRow) {
      const overflowIndex = overflowRow.sectionRowIndex;

      const tableClone = view.cloneNode(true) as TableElement;
      const tbodyClone = tableClone.tBodies[0];

      const cloneRows = Array.from(tbodyClone.rows);
      const overflowRowClone = cloneRows[overflowIndex];

      const cells = overflowRow.cells;
      const cloneCells = overflowRowClone.cells;

      let removeOverflowRow = true;

      let containerBounds = this.view.overflowContainer?.getBoundingClientRect();
      let containerHeight = containerBounds?.height || 0;
      let overflowRowRects = overflowRow.getClientRects();

      let remainingHeight: number = 0;

      // calculate remaining height for row
      if (containerBounds) {
        let rowTopWithinCointainer: number = containerBounds.bottom;
        for (let i = 0; i < overflowRowRects.length; i++) {
          let rect = overflowRowRects[i];
          if (rect.left < containerBounds.right && rect.right <= containerBounds.right + 10) {
            rowTopWithinCointainer = rect.top;
            break;
          }
        }

        remainingHeight = containerBounds.bottom - rowTopWithinCointainer;
      }

      let minRowHeight = 0;
      if (overflowRow.dataset.rh) {
        minRowHeight = EditorDOMUtils.convertUnitTo(overflowRow.dataset.rh, 'pt', 'px', 3);
      }

      // process overflow row
      if (isNaN(minRowHeight) || minRowHeight < remainingHeight) {
        let backupCloneRow = overflowRow.cloneNode(true);

        // find cells overflow and split content
        for (let c = 0; c < cells.length; c++) {
          // remove cloned cell children
          const cloneCell = cloneCells[c];
          while (cloneCell.firstChild) {
            cloneCell.removeChild(cloneCell.firstChild);
          }

          const child = cells[c].firstChild;
          if (child instanceof HTMLElement) {
            const overflow = this.findOverflow(child, cells[c]);

            if (overflow) {
              let contents = overflow.extractContents();

              while (contents.firstChild) {
                cloneCell.appendChild(contents.firstChild);
              }
            }
          }

          if (removeOverflowRow) {
            if (!EditorDOMUtils.isEmptyElement(cells[c])) {
              removeOverflowRow = false;
            }
          }
        }

        if (!removeOverflowRow) {
          overflowRowRects = overflowRow.getClientRects();
          if (overflowRowRects.length > 1) {
            // row still has overflow, move hole row to the next page
            //! WARN: probably some edges cases might fail

            const parent = overflowRowClone.parentNode;
            if (parent) {
              parent.replaceChild(backupCloneRow, overflowRowClone);
              removeOverflowRow = true;
            }
          } else if (overflowRowRects.length === 1) {
            let borderTop = EditorDOMUtils.convertUnitTo(
              overflowRow.style.borderTop,
              'px',
              'px',
              3,
            );
            let borderBottom = EditorDOMUtils.convertUnitTo(
              overflowRow.style.borderBottom,
              'px',
              'px',
              3,
            );

            if (isNaN(borderTop)) {
              borderTop = 0;
            }

            if (isNaN(borderBottom)) {
              borderBottom = 0;
            }

            const rowHeight = overflowRowRects[0].height + borderTop + borderBottom;

            if (rowHeight > remainingHeight) {
              logger.trace('bigger row height', rowHeight, remainingHeight, view);
              const parent = overflowRowClone.parentNode;
              if (parent) {
                parent.replaceChild(backupCloneRow, overflowRowClone);
                removeOverflowRow = true;
              }
            }
          }
        }
      } else if (containerHeight !== 0 && remainingHeight >= containerHeight / 2) {
        //! WARN: edge cases where defined row height is bigger than half the page
        removeOverflowRow = true;
      } else {
        removeOverflowRow = true;
      }

      // check merged cells
      // TODO: merged cells split points
      for (let c = 0; c < cells.length; c++) {
        const headId = cells[c].getAttribute('head-id');
        if (headId) {
          const headCell = view.querySelector(`[id="${headId}"]`);
          if (headCell instanceof HTMLTableCellElement) {
            if (headCell.cellIndex === c) {
              const rowSpan = headCell.rowSpan;
              const headRow = headCell.parentElement;
              if (headRow instanceof HTMLTableRowElement && rowSpan > 1) {
                const headRowIndex = headRow.sectionRowIndex;
                const overflowRowSpan = rowSpan - (overflowIndex - headRowIndex);

                if (overflowRowSpan > 0) {
                  const cloneHeadCell = headCell.cloneNode(true) as HTMLTableCellElement;
                  cloneHeadCell.rowSpan = overflowRowSpan;

                  // remove children
                  while (cloneHeadCell.firstChild) {
                    cloneHeadCell.removeChild(cloneHeadCell.firstChild);
                  }

                  // check overflow
                  const firstChild = headCell.firstChild;
                  if (firstChild instanceof HTMLElement) {
                    const overflow = this.findOverflow(firstChild, headCell);

                    if (overflow) {
                      let contents = overflow.extractContents();

                      while (contents.firstChild) {
                        cloneHeadCell.appendChild(contents.firstChild);
                      }
                    }
                  }

                  overflowRowClone.replaceChild(cloneHeadCell, cloneCells[c]);
                }
              }
            }
          }
        }
      }

      let remainingOriginalRows = removeOverflowRow ? overflowIndex : overflowIndex + 1;
      let pageFirstChild = this.view.sectionContainerAt(0)?.firstElementChild;

      // if table is not the first child in the page
      // has only one row in this page and it's size is smaller than 1/4 of the page
      // move the hole table to the next page
      if (remainingOriginalRows <= 1 && pageFirstChild !== view) {
        // remove original table
        view.remove();
      } else {
        // original table: remove rows after overflowIndex
        let rows = Array.from(view.tBodies[0].rows);
        for (let r = overflowIndex; r < rows.length; r++) {
          if (r === overflowIndex) {
            if (removeOverflowRow) {
              // remove overflow row
              rows[r].remove();
            }
          } else {
            // remove other rows
            rows[r].remove();
          }
        }

        // cloned table: remove rows before overflowIndex
        for (let r = 0; r < overflowIndex; r++) {
          if (cloneRows[r].dataset.hr !== 'true' && r < overflowIndex) {
            cloneRows[r].remove();
          }
        }

        // check if remaining rows are only header rows
        let onlyHeaderRows = true;
        rows = Array.from(view.tBodies[0].rows);
        for (let r = 0; r < rows.length; r++) {
          if (rows[r].dataset.hr !== 'true') {
            onlyHeaderRows = false;
            break;
          }
        }

        if (onlyHeaderRows) {
          view.remove();
        }
      }

      tableClone.preRender();

      // TODO:
      // split points per cell

      return tableClone;
    }

    return null;
  }

  extractContent(view?: HTMLElement, range?: Range, avoidPaginationProperties: boolean = false) {
    if (!range) {
      return [];
    }

    if (view && !avoidPaginationProperties) {
      //* WARN:
      //* keep lines together and keep with next should also work for paragraphs inside tables cells
      //* for now will not be implemented, split tables in page layout will be made per row and not per cell

      // check keep lines together
      if (view instanceof ParagraphElement) {
        const inlineKeepLines = view.getKeepLines();
        if (inlineKeepLines != null) {
          // WARN: false case should do nothing
          if (inlineKeepLines) {
            range.setStartBefore(view);
          }
        } else if (view.styleId) {
          const styleKeepLines = this.Data.styles.getStyleKeepLines(view.styleId);
          if (styleKeepLines) {
            range.setStartBefore(view);
          }
        }
      }

      // TODO: check keep with next inside tables
      // check for keep with next
      const previousSibling = view?.previousSibling;

      if (
        range?.startContainer.contains(view) &&
        range?.endContainer.contains(view) &&
        previousSibling instanceof ParagraphElement
      ) {
        const previousBreakElement = previousSibling?.querySelector(
          'page-break-element, section-break-element',
        );
        if (!previousBreakElement) {
          const inlineKeepWithNext = previousSibling.getKeepWithNext();
          if (inlineKeepWithNext != null) {
            // WARN: false case should do nothing
            if (inlineKeepWithNext) {
              range.setStartBefore(previousSibling);
            }
          } else if (previousSibling.styleId) {
            const styleKeepWithNext = this.Data.styles.getStyleKeepWithNext(
              previousSibling.styleId,
            );
            if (styleKeepWithNext) {
              range.setStartBefore(previousSibling);
            }
          }
        }
      }
    }

    // page-break, section-break validations
    if (
      EditorDOMElements.isPageBreakElement(range.startContainer) ||
      EditorDOMElements.isSectionBreakElement(range.startContainer)
    ) {
      if (view?.lastElementChild === range.startContainer) {
        return [];
      } else {
        range.setStartAfter(range.startContainer);
      }
    }

    let lastSection = this.view?.lastSectionContainer || null;
    let lastChild = lastSection?.lastChild as Editor.Visualizer.BaseView;

    let ancestorContainer: Element | null = lastSection;

    if (
      ancestorContainer &&
      lastChild instanceof ApprovedViewElement &&
      lastChild?.isContentWrapper
    ) {
      ancestorContainer = lastChild.contentContainer;
      lastChild = lastChild.selectableContent as Editor.Visualizer.BaseView;
    }

    let splitPoint: Editor.Selection.Position | null = null;

    if (lastChild?.contains(range.startContainer)) {
      splitPoint = JsonRange.getPositionFromNodeOffset(
        range.startContainer,
        range.startOffset,
        ancestorContainer,
      );
    }

    // extract content
    let extractedChildren: Editor.Visualizer.BaseView[] = [];

    if (view instanceof TableElement && view.contains(range.startContainer)) {
      // HANDLE TABLES
      const processedTable = this.extractContentFromTable(view, range);
      if (processedTable) {
        extractedChildren.push(processedTable);
      }
    } else {
      // HANDLE OTHER CONTENT
      let contents: DocumentFragment = range?.extractContents();
      extractedChildren = Array.from(contents?.childNodes || []) as Editor.Visualizer.BaseView[];
    }

    // handle split points
    let queue = [...extractedChildren];
    let processed: Editor.Visualizer.BaseView[] = [];
    let processing: Editor.Visualizer.BaseView;

    delete lastChild?.dataset.splitOriginal;
    while (queue.length) {
      processing = queue.shift() as Editor.Visualizer.BaseView;

      if (processing instanceof ApprovedViewElement && processing?.isContentWrapper) {
        const content = processing.selectableContent as HTMLElement;
        if (content) {
          queue.unshift(content);
        }
      } else if (processing?.nodeName === 'SECTION-ELEMENT') {
        let childnodes = (Array.from(processing.childNodes) || []) as Editor.Visualizer.BaseView[];
        queue.unshift(...childnodes);
      } else {
        if (lastChild?.id === processing.id && lastChild.dataset.splitFrom == null) {
          lastChild.dataset.splitOriginal = 'true';
          lastChild.dataset.splitIndex = '0';
          processing.dataset.splitFrom = lastChild.id;
          processing.dataset.splitIndex = `${+lastChild.dataset.splitIndex + 1}`;

          // TODO: table split points are not beeing correctly calculate for tables bigger than 3 pages

          const viewModel = lastChild.vm as BlockViewModel;
          processing.vm = viewModel;
          if (splitPoint?.p) {
            processing = viewModel.addSplitView(processing, splitPoint.p);
          }
        } else if (lastChild?.dataset.splitFrom === processing.id) {
          processing.dataset.splitFrom = lastChild?.dataset.splitFrom;
          if (lastChild.dataset.splitIndex)
            processing.dataset.splitIndex = `${+lastChild.dataset.splitIndex + 1}`;

          const viewModel = lastChild.vm as BlockViewModel;
          processing.vm = viewModel;
          if (splitPoint?.p) {
            processing = viewModel.addSplitView(processing, splitPoint.p);
          }
        } else {
          delete processing.dataset.splitFrom;
        }
        processed.push(processing);
      }
    }

    return processed;
  }

  private textBreak(node: HTMLElement, start: number, end: number) {
    let wordwalker = words(node);
    let left = 0;
    let right = 0;
    let word, next, done, pos;
    let offset;
    while (!done) {
      next = wordwalker.next();
      word = next.value;
      done = next.done;

      if (!word) {
        break;
      }

      pos = word.getBoundingClientRect();

      left = Math.floor(pos.left);
      right = Math.floor(pos.right);

      if (left >= end) {
        offset = word.startOffset;
        break;
      }

      if (right > end) {
        let letterwalker = letters(word);
        let letter, nextLetter, doneLetter;

        while (!doneLetter) {
          nextLetter = letterwalker.next();
          letter = nextLetter.value;
          doneLetter = nextLetter.done;

          if (!letter) {
            break;
          }

          pos = letter.getBoundingClientRect();
          left = Math.floor(pos.left);

          if (left >= end) {
            offset = letter.startOffset;
            done = true;

            break;
          }
        }
      }
    }

    return offset;
  }

  private async appendHeaderAndFooter(sectionId: string) {
    if (!this.view) {
      return;
    }
    this.header = this.Visualizer.viewModelFactory?.getPageHeader(sectionId);
    if (this.header) {
      this.header.parent = this;
      await this.header.bindView(this.view.headerContainer);
    }
    this.footer = this.Visualizer.viewModelFactory?.getPageFooter(sectionId);
    if (this.footer) {
      this.footer.parent = this;
      await this.footer.bindView(this.view.footerContainer);
    }
    const headerHeight = window.getComputedStyle(this.view.headerContainer as Element).height;
    const footerHeight = window.getComputedStyle(this.view.footerContainer as Element).height;
    let template = `[header] ${headerHeight} [page] calc(
      var(--page-pagebox-height) - ${headerHeight} - ${footerHeight}
    )
    [footer] ${footerHeight};`;
    //@ts-expect-error
    this.view.pagebox.style = `grid-template-rows: ${template}`;
  }

  hasBreakElement(
    view: Element | undefined | null = this.view?.lastSectionContainer?.lastElementChild,
  ) {
    if (view) {
      const breakElement = view.querySelector('page-break-element, section-break-element');
      if (breakElement) {
        return true;
      }
    }

    return false;
  }

  hasOverflow() {
    if (this.view && this.view.overflowContainer && this.view.contentContainer) {
      let overflowScrollWidth = this.view.overflowContainer?.scrollWidth || 0;
      let overflowClientWidth = this.view.overflowContainer?.clientWidth || 0;
      let overflowClientHeight = this.view.overflowContainer?.clientHeight || 0;
      let contentClientHeigth = this.view.contentContainer?.clientHeight || 0;

      return (
        (overflowClientWidth && overflowClientWidth * 2 < overflowScrollWidth) ||
        (contentClientHeigth && contentClientHeigth >= overflowClientHeight)
      );
    }

    return false;
  }

  hasUnderflow() {
    if (this.view && this.view.overflowContainer) {
      let overflowClientHeight = this.view.overflowContainer?.clientHeight || 0;
      let contentClientHeigth = this.view.contentContainer?.clientHeight || 0;

      if (contentClientHeigth < overflowClientHeight && !this.breakToken?.needsBreak()) {
        return true;
      }
    }
    return false;
  }

  findOverflow(startAt?: HTMLElement, limiterView?: HTMLElement) {
    if (!this.view || !this.view.contentContainer || !this.view.overflowContainer) {
      return;
    }

    if (!limiterView) {
      limiterView = this.view.contentContainer as HTMLElement;
    }

    let bounds = this.view.overflowContainer.getBoundingClientRect();
    let start = bounds.left;
    let end = bounds.right + 10; // 10 -> margin of error;
    let range;

    if (startAt instanceof TableElement) {
      const tbody = startAt.tBodies[0];
      const rows = tbody.rows;

      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];

        let rects = DOMProcess.getClientRects(row);
        if (rects) {
          for (let j = 0; j < rects.length; j++) {
            let left = Math.round(rects[j].left);

            if (!range && left >= end) {
              range = document.createRange();
              if (i === 0) {
                range.setStartBefore(startAt);
              } else {
                range.setStartBefore(row);
              }
              break;
            }
          }

          if (range) {
            break;
          }
        }
      }
    } else {
      let walkView = startAt || (limiterView.firstChild as HTMLElement);

      let walker = walk(walkView, limiterView);

      // Find Start
      let next, done, node: HTMLElement | undefined, offset, skip, prev, br;
      while (!done) {
        next = walker.next();
        done = next.done;
        node = next.value?.node;
        skip = false;
        prev = undefined;
        br = undefined;

        if (node) {
          let pos = DOMProcess.getBoundingClientRect(node);
          if (!pos) {
            break;
          }
          let left = pos.left;
          let right = Math.floor(pos.right);

          if (!range) {
            if (left >= end) {
              if (prev) {
                range = document.createRange();
                range.setStartBefore(prev);
                break;
              }

              if (!br && DOMProcess.isElement(node)) {
                range = document.createRange();
                range.setStartBefore(node);
                break;
              }

              if (DOMProcess.isText(node) && node.textContent?.trim().length) {
                range = document.createRange();
                range.setStartBefore(node);
                break;
              }
            }

            if (DOMProcess.isText(node) && node.textContent?.trim().length) {
              let rects = DOMProcess.getClientRects(node) as DOMRectList;
              let rect;
              left = 0;
              for (let i = 0; i !== rects.length; i++) {
                rect = rects[i];
                if (rect.width > 0 && (!left || rect.left > left)) {
                  left = rect.left;
                }
              }

              if (left > end) {
                range = document.createRange();
                offset = this.textBreak(node, start, end);

                if (offset === undefined) {
                  range = undefined;
                } else {
                  range.setStart(node, offset);
                }
                break;
              }
            }
          }

          // Skip children
          if (skip || right <= end) {
            let nextSibling = DOMProcess.nodeAfter(node, limiterView);
            if (nextSibling) {
              walker = walk(nextSibling, limiterView);
            }
          }
        }
      }

      if (!range) {
        const element = startAt || (limiterView.firstChild as HTMLElement);

        const breakElement = element.querySelector('page-break-element, section-break-element');
        if (breakElement) {
          range = document.createRange();
          range.setStart(breakElement, 0);
        }
      }
    }

    // Find End
    if (range && this.view?.lastSectionContainer?.lastChild) {
      if (limiterView.contains(this.view?.lastSectionContainer.lastChild)) {
        range.setEndAfter(this.view.lastSectionContainer.lastChild as Node);
      } else if (limiterView.lastChild) {
        range.setEndAfter(limiterView.lastChild);
      }
    }

    return range;
  }

  buidBreakToken(overflow?: Range): BreakToken | undefined {
    if (overflow) {
      return new BreakToken(overflow.startContainer as HTMLElement, overflow.startOffset);
    }

    const lastChild = this.view?.lastSectionContainer?.lastElementChild as HTMLElement;
    if (lastChild) {
      return new BreakToken(lastChild as HTMLElement, lastChild.childNodes.length);
    }

    return undefined;
  }

  setPageNumber(value: number) {
    this.number = value;
    this.view?.setAttribute('data-page-number', `${this.number}`);
  }

  getRootView(): PageElement | undefined {
    return this.view;
  }

  appendSectionView(sectionId?: string) {
    if (!sectionId || this.view?.sectionContainerById(sectionId)) {
      return;
    }
    const sectionElement = this.Visualizer.viewFactory?.getSectionView(sectionId);
    if (sectionElement) {
      this.view?.contentContainer?.appendChild(sectionElement);
    }
  }

  hasContent() {
    while (this.view?.lastSectionContainer) {
      if (this.view?.lastSectionContainer.isEmpty()) {
        this.view?.lastSectionContainer.remove();
      } else {
        return true;
      }
    }
    return false;
  }

  async layoutContents(
    view?: Editor.Visualizer.BaseView,
    sectionId?: string,
    avoidPaginationProperties?: boolean,
  ) {
    if (this.view && view) {
      // handle sections
      if (this.view?.lastSectionContainer && sectionId != null) {
        let sectionContainer = this.view?.lastSectionContainer as SectionElement;
        let currentSection = sectionContainer?.section;

        if (currentSection == null) {
          (this.view as PageElement).section = sectionId;
          sectionContainer.section = sectionId;
          await this.appendHeaderAndFooter(sectionId);
        } else if (sectionContainer.section !== sectionId) {
          this.appendSectionView(sectionId);
        }
      } else {
        this.appendSectionView(sectionId);
      }

      this.view?.lastSectionContainer?.appendChild(view);
      if (view.vm) {
        this.Visualizer.tabulator?.tabulate(view.vm as BlockViewModel);
      }
      if (view.nodeName === 'FIGURE-ELEMENT') {
        // @ts-expect-error
        await (view as FigureElement).awaitForImageLoad();
      }

      let overflowRange;
      let children;

      if (this.hasOverflow() || this.hasBreakElement(view)) {
        overflowRange = this.findOverflow(view);
        children = this.extractContent(view, overflowRange as Range, avoidPaginationProperties);
      }

      let breakToken = this.buidBreakToken(overflowRange);

      if (breakToken) {
        this.breakToken = breakToken;
        return LayoutResult.success(breakToken, children);
      }
    }
    return LayoutResult.error(new Error('No view available'));
  }

  hash() {
    return this.view?.innerHTML.hashCode();
  }

  dispose() {
    // this.removeAllChildren();
    while (this.hasContent()) {
      this.view?.lastSectionContainer?.remove();
    }
    this.header?.dispose();
    this.footer?.dispose();
    this.view?.remove();
  }
}
