import { DoDOCRange } from '_common/DoDOCSelection/DoDOCRange';

const OOXML_STRUCTURE_ELEMENTS: string[] = ['textbody', 'p', 'run'];

export class PresentationRange extends DoDOCRange {
  cloneRange(): PresentationRange {
    const newRange = new PresentationRange();
    newRange.setStart(this.startContainer, this.startOffset);
    newRange.setEnd(this.endContainer, this.endOffset);
    return newRange;
  }

  static fromNativeRange(nativeRange: Range) {
    let range = new PresentationRange();
    range.setStart(nativeRange.startContainer, nativeRange.startOffset);
    range.setEnd(nativeRange.endContainer, nativeRange.endOffset);
    return range;
  }

  static fromJsonLocation(location: Presentation.Common.TextMoniker) {
    let start = PresentationRange.parsePositionToNodeOffset(location.start);
    let end = PresentationRange.parsePositionToNodeOffset(location.end);

    if (start?.offset != null && end?.offset != null) {
      let range = new PresentationRange();
      range.setStart(start.node, start.offset);
      range.setEnd(end.node, end.offset);
      return range;
    }
  }

  getClientRects(): DOMRectList {
    return super.getClientRects();
  }

  getPresentationAnchor(): Presentation.Common.PresentationAnchor | null {
    const commonAncestorContainer =
      this.commonAncestorContainer instanceof Text
        ? this.commonAncestorContainer.parentElement
        : this.commonAncestorContainer;

    if (!(commonAncestorContainer instanceof Element)) {
      return null;
    }

    const slide = commonAncestorContainer.closest('[data-type="slide"]');
    if (!slide) {
      return null;
    }

    const shape = commonAncestorContainer.closest('[data-type="shape"]');
    const textbody = commonAncestorContainer.closest('[data-type="textbody"]');

    const slideMoniker: Presentation.Common.SlideMoniker = { id: slide.id };
    let shapeMoniker: Presentation.Common.ShapeMoniker | null = null;
    let textMoniker: Presentation.Common.TextMoniker | null = null;
    if (shape) {
      const shapeId = shape.getAttribute('data-id');
      if (!shapeId) {
        return null;
      }

      shapeMoniker = { id: shapeId };

      if (textbody) {
        textMoniker = {
          start: this.parseNodeOffsetToPosition(textbody, this.startContainer, this.startOffset),
          end: this.parseNodeOffsetToPosition(textbody, this.endContainer, this.endOffset),
        };
      }
    }

    //Only return text anchor if there is a non-collapsed selection
    if (shapeMoniker && textMoniker && !this.collapsed) {
      return [slideMoniker, shapeMoniker, textMoniker];
    }

    return null;
  }

  static parsePositionToNodeOffset(
    position: Presentation.Common.TextPosition,
    options: Presentation.Common.ParseLocationOptions = {},
  ) {
    let node: Node | null = document.getElementById(position.b);
    let offset: number | null = null;
    const path = position.p;

    if (node) {
      let lastKey: string | number | null = null;

      for (let i = 0; i < path.length; i++) {
        const key = path[i];

        if ((lastKey === 'childNodes' || lastKey === 'content') && !isNaN(+key)) {
          if (lastKey === 'childNodes' && node instanceof Element) {
            if (+key <= node.childNodes.length) {
              let childNodes: NodeListOf<ChildNode> = node.childNodes;

              offset = +key;

              // Adjust offset for frontend only elements
              for (let j = 0; j < childNodes.length; j++) {
                const node = childNodes[j];
                /**
                 * Type 1 is an Element Node for example (<p> or <div>)
                 * Source: https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType
                 */
                if (node.nodeType === 1) {
                  const element = node as HTMLElement;
                  //Check if element isn't based in a OOXML element (defined by data-type)
                  if (
                    !OOXML_STRUCTURE_ELEMENTS.includes(element.getAttribute('data-type') ?? '') &&
                    element.parentNode
                  ) {
                    const index = Array.from(element.parentNode.childNodes).indexOf(element);

                    if (offset < childNodes.length && index <= offset) {
                      offset += 1;
                    }
                  }
                }
              }

              if (
                childNodes[offset] != null &&
                ((options.selectionPath && i < path.length - 1) || !options.selectionPath) // for selection the path needs to be outside of the elements
              ) {
                node = childNodes[offset];
                offset = 0;
              }
            } else {
              offset = null;
              break;
            }
          } else if (lastKey === 'content' && node instanceof Text) {
            if (+key <= node.length) {
              offset = +key;
            } else {
              offset = null;
              break;
            }
          }
        }

        if (key === 'childNodes' || key === 'content') {
          lastKey = key;
        } else {
          lastKey = null;
        }
      }

      return { node, offset };
    }
  }

  //getPositionFromNodeOffset
  private parseNodeOffsetToPosition(
    closest: Element,
    node: Node,
    offset: number,
  ): Presentation.Selection.Position {
    const path: Presentation.Selection.Path = [];

    let initialOffset = offset;
    const childNodes = node.childNodes;
    // Adjust offset for frontend only elements
    for (let i = 0; i < childNodes.length; i++) {
      const node = childNodes[i];
      /**
       * Type 1 is an Element Node for example (<p> or <div>)
       * Source: https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType
       */
      if (node.nodeType === 1) {
        const element = node as HTMLElement;
        //Check if element isn't based in a OOXML element (defined by data-type)
        if (
          !OOXML_STRUCTURE_ELEMENTS.includes(element.getAttribute('data-type') ?? '') &&
          i <= offset &&
          initialOffset > 0
        ) {
          initialOffset -= 1;
        }
      }
    }

    path.push(initialOffset);

    let workNode: Node | null = node;
    while (workNode != null && workNode !== closest.parentNode) {
      if (workNode.nodeType === Node.TEXT_NODE) {
        path.unshift('content');
      }

      if (workNode.nodeType === Node.ELEMENT_NODE) {
        path.unshift('childNodes');
      }

      if (workNode.parentNode && workNode !== closest) {
        const parentChildNodes = workNode.parentNode.childNodes as NodeListOf<Node>;

        let index = Array.from(parentChildNodes).indexOf(workNode);

        // Adjust offset for frontend only elements
        for (let i = 0; i < parentChildNodes.length; i++) {
          const child = parentChildNodes[i];
          /**
           * Type 1 is an Element Node for example (<p> or <div>)
           * Source: https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType
           */
          if (child.nodeType === 1) {
            const element = child as HTMLElement;
            //Check if element isn't based in a OOXML element (defined by data-type)
            if (
              !OOXML_STRUCTURE_ELEMENTS.includes(element.getAttribute('data-type') ?? '') &&
              i <= index &&
              index > 0
            ) {
              index -= 1;
            }
          }
        }

        path.unshift(index);
      }

      workNode = workNode.parentNode;
    }

    return {
      b: closest.id,
      p: path,
    };
  }
}
