import { Command } from '../Command';
import { JsonRange } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { StylesManager } from '../../Managers';

export class UpdateBlockPropertiesCommand extends Command {
  properties: Editor.Edition.updateBlockPropertiesProperties;
  action: Editor.Edition.UpdateBlockPropertiesActions;

  constructor(
    context: Editor.Edition.Context,
    action: Editor.Edition.UpdateBlockPropertiesActions,
    properties: Editor.Edition.updateBlockPropertiesProperties,
  ) {
    super(context);
    this.properties = properties;
    this.action = action;
  }

  private identationCurrentSelection() {
    // TODO: warn user in suggestions

    if (!this.context.DataManager || !this.actionContext) {
      throw new Error('Invalid Context');
    }
    const blockElements = JsonRange.filterElementDataFromRange(
      this.context.DataManager,
      this.actionContext.range,
      NodeUtils.BLOCK_TYPES,
      { onlyContainerLevel: true, useSelectedCells: false },
    );

    let indent: Editor.Data.Node.Indentation = {};
    let specialIndent: Editor.Data.Node.SpecialIndentation = {};

    for (let b = 0; b < blockElements.length; b++) {
      const baseData = blockElements[b].baseData;
      const blockId = blockElements[b].baseData.id;

      if (
        blockId &&
        (this.context.DataManager.nodes.isNodeEditable(blockId) ||
          NodeUtils.isFigureData(baseData) ||
          NodeUtils.isImageData(baseData))
      ) {
        if (this.properties.indentation?.leftIndentation != null) {
          indent.l = this.properties.indentation.leftIndentation as number;

          if (indent.l > 0) {
            indent.l = Math.min(StylesManager.INDENTATION_LIMITS.LEFT.MANIPULATION_MAX, indent.l);
          } else {
            indent.l = Math.max(StylesManager.INDENTATION_LIMITS.LEFT.MANIPULATION_MIN, indent.l);
          }
        }

        if (this.properties.indentation?.rightIndentation != null) {
          indent.r = this.properties.indentation.rightIndentation as number;

          if (indent.r > 0) {
            indent.r = Math.min(StylesManager.INDENTATION_LIMITS.RIGHT.MANIPULATION_MAX, indent.r);
          } else {
            indent.r = Math.max(StylesManager.INDENTATION_LIMITS.RIGHT.MANIPULATION_MIN, indent.r);
          }
        }

        if (this.properties.indentation?.specialIndent != null) {
          specialIndent.t = this.properties.indentation.specialIndent;
        }

        if (this.properties.indentation?.specialIndentValue != null) {
          specialIndent.v = this.properties.indentation.specialIndentValue as number;

          if (specialIndent.v > 0) {
            specialIndent.v = Math.min(
              StylesManager.INDENTATION_LIMITS.SPECIAL_INDENT.MANIPULATION_MAX,
              specialIndent.v,
            );
          } else {
            specialIndent.v = Math.max(
              StylesManager.INDENTATION_LIMITS.SPECIAL_INDENT.MANIPULATION_MIN,
              specialIndent.v,
            );
          }
        }

        if (specialIndent.t === 'h' && specialIndent.v) {
          if (indent.l != null) {
            indent.l += specialIndent.v;
          } else {
            indent.l = specialIndent.v;
          }
        }

        const block = this.context.DataManager.nodes.getNodeModelById(blockId);
        if (block) {
          if (indent.l != null || indent.r != null) {
            block.set([...blockElements[b].childPath, block.KEYS.PROPERTIES, 'ind'], indent, {
              source: 'LOCAL_RENDER',
            });
          }

          if (specialIndent.t != null || specialIndent.v != null) {
            block.set(
              [...blockElements[b].childPath, block.KEYS.PROPERTIES, 'sp_ind'],
              specialIndent,
              {
                source: 'LOCAL_RENDER',
              },
            );
          }
        }
      }
    }
  }

  private setParagraphBackgroundColor(color: string | null | undefined) {
    // TODO: warn user in suggestions
    if (!this.context.stylesHandler?.askUserAboutThis?.()) {
      return;
    }

    if (!this.context.DataManager || !this.actionContext) {
      throw new Error('Invalid Context');
    }

    const blockElements = JsonRange.filterElementDataFromRange(
      this.context.DataManager,
      this.actionContext.range,
      NodeUtils.BLOCK_TYPES,
      { onlyContainerLevel: true, useSelectedCells: false },
    );

    for (let b = 0; b < blockElements.length; b++) {
      const blockId = blockElements[b].baseData.id;
      const childData = blockElements[b].childData;

      if (
        blockId &&
        this.context.DataManager.nodes.isNodeEditable(blockId) &&
        NodeUtils.isBlockTextData(childData)
      ) {
        let backgroundColor: string | boolean = false;
        if (color != null) {
          if (color === 'transparent') {
            backgroundColor = false;
          } else {
            const hex = EditorDOMUtils.rgbToHex(color);
            if (hex) {
              backgroundColor = hex.substring(1);
            }
          }
        }

        const block = this.context.DataManager.nodes.getNodeModelById(blockId);

        if (block) {
          block.set([...blockElements[b].childPath, block.KEYS.PROPERTIES, 'bg'], backgroundColor, {
            source: 'LOCAL_RENDER',
          });
        }
      }
    }
  }

  private setLinePaginationProperties(
    properties: Editor.Elements.PaginationProperties | undefined,
  ) {
    // TODO: warn user in suggestions
    if (!this.context.stylesHandler?.askUserAboutThis?.()) {
      return;
    }

    if (!this.context.DataManager || !this.actionContext) {
      throw new Error('Invalid Context');
    }

    const blockElements = JsonRange.filterElementDataFromRange(
      this.context.DataManager,
      this.actionContext.range,
      NodeUtils.BLOCK_TYPES,
      { onlyContainerLevel: true, useSelectedCells: false },
    );

    for (let b = 0; b < blockElements.length; b++) {
      const blockId = blockElements[b].baseData.id;
      const childData = blockElements[b].childData;

      if (
        blockId &&
        this.properties.pagination &&
        this.context.DataManager.nodes.isNodeEditable(blockId) &&
        NodeUtils.isBlockTextData(childData)
      ) {
        const propKeys = Object.keys(this.properties.pagination);
        for (let i = 0; i < propKeys.length; i++) {
          const prop = propKeys[i] as keyof Editor.Elements.PaginationProperties;
          const block = this.context.DataManager.nodes.getNodeModelById(blockId);

          if (block && properties) {
            block.set(
              [...blockElements[b].childPath, block.KEYS.PROPERTIES, prop],
              properties[prop],
              {
                source: 'LOCAL_RENDER',
              },
            );
          }
        }
      }
    }
  }

  private lineSpaceCurrentSelection(spacing: Editor.Elements.SpacingProperties | undefined) {
    // TODO: warn user in suggestions
    if (!this.context.stylesHandler?.askUserAboutThis?.()) {
      return;
    }

    if (!this.context.DataManager || !this.actionContext) {
      throw new Error('Invalid Context');
    }

    const blockElements = JsonRange.filterElementDataFromRange(
      this.context.DataManager,
      this.actionContext.range,
      NodeUtils.BLOCK_TYPES,
      { onlyContainerLevel: true, useSelectedCells: false },
    );

    for (let b = 0; b < blockElements.length; b++) {
      const blockId = blockElements[b].baseData.id;
      const childData = blockElements[b].childData;

      if (
        blockId &&
        spacing &&
        this.context.DataManager.nodes.isNodeEditable(blockId) &&
        NodeUtils.isBlockTextData(childData)
      ) {
        const propKeys = Object.keys(spacing);
        for (let i = 0; i < propKeys.length; i++) {
          const property = propKeys[i] as keyof Editor.Elements.SpacingProperties;
          let prop: keyof Editor.Data.Node.ParagraphProperties | undefined;

          switch (property) {
            case 'lineHeight':
              prop = 'lh';
              break;
            case 'spaceBefore':
              prop = 'sb';
              break;
            case 'spaceAfter':
              prop = 'sa';
              break;
            case 'asb':
              prop = 'asb';
              break;
            case 'asa':
              prop = 'asa';
              break;
            default:
              prop = undefined;
          }
          const block = this.context.DataManager.nodes.getNodeModelById(blockId);

          if (block && prop) {
            block.set(
              [...blockElements[b].childPath, block.KEYS.PROPERTIES, prop],
              spacing[property],
              {
                source: 'LOCAL_RENDER',
              },
            );
          }
        }
      }
    }
  }

  private alignCurrentSelection(params: Editor.Elements.TextAlignProperties | undefined) {
    // TODO: warn user in suggestions
    if (!this.context.stylesHandler?.askUserAboutThis?.()) {
      return;
    }

    if (!this.context.DataManager || !this.actionContext) {
      throw new Error('Invalid Context');
    }

    const blockElements = JsonRange.filterElementDataFromRange(
      this.context.DataManager,
      this.actionContext.range,
      NodeUtils.BLOCK_TYPES,
      { onlyContainerLevel: true, useSelectedCells: false },
    );

    for (let b = 0; b < blockElements.length; b++) {
      const blockId = blockElements[b].baseData.id;

      if (blockId && params?.style) {
        const block = this.context.DataManager.nodes.getNodeModelById(blockId);
        const style: Editor.Elements.TextAlignPropertiesType = params.style;
        let value: Editor.Data.Node.Alignment | undefined;
        //let path = [];

        switch (style) {
          case 'left':
            value = 'l';
            break;
          case 'center':
            value = 'c';
            break;
          case 'right':
            value = 'r';
            break;
          case 'justify':
            value = 'j';
            break;
          default:
            value = undefined;
        }

        if (block) {
          block.set([...blockElements[b].childPath, block.KEYS.PROPERTIES, 'a'], value, {
            source: 'LOCAL_RENDER',
          });
        }
      }
    }
  }

  async handleExec(): Promise<void> {
    if (!this.askUserAboutThis()) {
      return;
    }

    this.buildActionContext();

    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.context.contentManipulator ||
      !this.actionContext
    ) {
      throw new Error('Invalid Context');
    }

    switch (this.action) {
      case 'INDENTATION_CURRENT_SELECTION':
        this.identationCurrentSelection();
        break;
      case 'APPLY_PARAGRAPH_BACKGROUND_COLOR':
        this.setParagraphBackgroundColor(this.properties.paragraphBackgroundColor);
        break;
      case 'SET_LINE_PAGINATION_PROPERTIES':
        this.setLinePaginationProperties(this.properties.pagination);
        break;
      case 'LINE_SPACE_CURRENT_SELECTION':
        this.lineSpaceCurrentSelection(this.properties.lineSpacing);
        break;
      case 'ALIGN_CURRENT_SELECTION':
        this.alignCurrentSelection(this.properties.alignment);
        break;
    }

    this.applySelection();

    this.createPatch();
  }
}
