import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { EmptyState } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import { selectHasFilters } from '_common/components/Filters/FilterSlice';
import { useAuditLog } from '../AuditLogContext';

import UserCard from '_common/components/UserCard/UserCard';
import EmptyFilteredState from '_common/components/EmptyFilteredState/EmptyFilteredState';
import { Table as BaseTable } from '_common/components/Table2';
import Cell from '_common/components/Table2/Cells';
import AuditActionLabel from '_common/components/AuditLog/AuditCells/AuditActionLabel';
import AuditActionExtra from '_common/components/AuditLog/AuditCells/AuditActionExtra';

import styles from './Table.module.scss';

/** Source: https://dodoccorp.atlassian.net/wiki/spaces/RR/pages/609222952/Audit+Log?focusedCommentId=2337800220#Assumptions
 * // Comments
 | 'comment_created' // New
 | 'comment_resolved' // Status update (?)
 | 'comment_reply' // Replies
 | 'comment_deleted' // Deletions
   // Tasks
 | 'task_created' // New
 | 'task_status_changed' // Status update
 | 'task_replied' // Replies
 | 'task_deleted' // Deletions
   // Suggestions
 | 'suggestion_accepted' // Acceptance
 | 'suggestion_rejected' // Rejection
 | 'suggestion_replied' // Replies
   // Access and Permissions
   | 'permission_added' // Add permission | Add group permission
   | 'permission_removed' // Remove permission | Remove group permission
   | 'role_added' // Add role | Add group role
   | 'role_removed' // Remove role | Remove group role
   //Content
 | 'node_approved' // Approve content
 | 'node_reopened' // Reopen content
 | 'node_permission_added' // Add content permissions
 | 'node_permission_removed' // Remove content permissions
   // Track Changes
 | 'lock_unlock_suggestions' // Track changes Lock | Unlock
   // Versioning
 | 'saved_version' // Versioning Save a version
 | 'restored_version' // Versioning Restore a version
 | 'new_document_version' // Versioning Upload a new version
   // Explorer related
 | 'created' // Created element
 | 'downloaded' // Download file
 | 'edited' // Rename/Description/DueDate/Warning
 | 'status_changed' // Approve
 | 'moved' // Explorer related Move
 | 'copied' // Explorer related Copy
   // Tags
 | 'tag_added' // Tag Add
 | 'tag_removed' // Tag Remove
   // Wild
 | 'template_changed' // Apply styling template
 | 'exported' // Export document
 */

type TableProps = {
  noFlex?: boolean;
};

const IDENTITY = 'auditLog';

const Table = ({ noFlex }: TableProps) => {
  const intl = useIntl();

  const { data, isFetching, hasNextPage, originalArgs, fetchNextPage, params, setParams } =
    useAuditLog();

  const hasActiveFilters = useSelector((state) => selectHasFilters(state, state.filters.auditLog));

  const columns = useMemo(() => {
    return [
      {
        id: 'user',
        header: intl.formatMessage({ id: 'global.user' }),
        width: 224,
      },
      {
        id: 'name',
        header: intl.formatMessage({ id: 'editor.menu.view.action' }),
        width: 304,
      },
      {
        id: 'time',
        header: intl.formatMessage({ id: 'TIMESTAMP' }),
        orderable: true,
        width: 160,
      },
      {
        id: 'information',
        header: intl.formatMessage({ id: 'ADDITIONAL_INFORMATION' }),
        width: 240,
        flex: true,
      },
    ];
  }, []);

  const value = useMemo(() => {
    return data.map((auditAction) => {
      return {
        //@ts-expect-error API is sending "_id" but API type is "id"
        id: auditAction['_id'] ?? '',
        user: (
          //@ts-expect-error API is sending "_id" but API type is "id"
          <Cell testId={`${auditAction['_id']}-user-cell`}>
            {auditAction.user && (
              <UserCard userId={auditAction.user} titleStyle={{ fontWeight: 500 }} />
            )}
          </Cell>
        ),
        name: (
          //@ts-expect-error API is sending "_id" but API type is "id"
          <Cell ellipsis testId={`${auditAction['_id']}-action-cell`}>
            <AuditActionLabel auditAction={auditAction} />
          </Cell>
        ),
        time: (
          <Cell>
            <Cell.DateTime date={{ date: auditAction.time }} time={{ time: auditAction.time }} />
          </Cell>
        ),
        information: (
          //@ts-expect-error API is sending "_id" but API type is "id"
          <Cell ellipsis testId={`${auditAction['_id']}-extra-cell`}>
            <AuditActionExtra auditAction={auditAction} />
          </Cell>
        ),
      };
    });
  }, [data]);

  const renderEmptyView = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', flex: 1, marginTop: '10rem' }}>
        <EmptyState
          size="large"
          icon="EmptyDocument"
          title={intl.formatMessage({ id: 'AUDIT_LOG_IS_EMPTY' })}
          testId="empty-audit-log-list"
        >
          {intl.formatMessage({ id: 'THERE_ARE_NO_ACTIONS_LOGGED_YET_IN_THE_DOCUMENT' })}
        </EmptyState>
      </div>
    );
  };

  if (data.length === 0) {
    return hasActiveFilters ? (
      <EmptyFilteredState identity="auditLog" margin="10rem 0 0 0" />
    ) : (
      renderEmptyView()
    );
  }

  return (
    <div className={`${styles.root} ${noFlex ? styles.noFlex : null}`}>
      <BaseTable
        identity={IDENTITY}
        selectable={false}
        columns={columns}
        value={value}
        renderFooter={() => null}
        lazyLoading={{ fetchNextPage, hasNextPage, offset: originalArgs?.offset ?? 0 }}
        requestParams={{ params, setParams }}
        isLoading={isFetching}
        loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
        testId={'audit-log'}
      />
    </div>
  );
};

export default Table;
