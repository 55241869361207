import { FC, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTableData } from './TableData';
import Background from '../Background';
import TableCellBorder from './TableCellBorder';
import styles from './TableShape.module.scss';
import TextBody from '../TextBody/TextBody';
import useStyle from './useStyle';
import { useSelector } from '_common/hooks';

const NO_POSITION = { top: 0, left: 0 };

type TableCellProps = {
  tc: Presentation.Data.Table.Cell;
  tblStyle: Presentation.Data.Table.Style | null;
  rowIndex: number;
  colIndex: number;
};

const TableCell: FC<TableCellProps> = ({ tc, tblStyle, rowIndex, colIndex }) => {
  const ref = useRef<HTMLTableCellElement | null>(null);
  const { positions } = useTableData();
  const [bounds, setBounds] = useState<DOMRect | null>(null);
  const zoom = useSelector((state) => state.presentation.general.zoom);

  useLayoutEffect(() => {
    if (ref.current) {
      setBounds(ref.current.getBoundingClientRect());
    }
  }, [zoom]);

  const pos = positions[rowIndex][colIndex];
  const { style, getTextStyle } = useStyle(tblStyle?.[pos.placement], tc.tcPr);

  const tcTxStyle = useMemo(() => {
    const cellTxStyle = tblStyle?.[pos.placement]?.tcTxStyle;
    const tableTxStyle = tblStyle?.wholeTbl?.tcTxStyle;

    return cellTxStyle ?? tableTxStyle;
  }, [tblStyle, pos]);

  const textStyle = getTextStyle(tcTxStyle);
  const width = bounds?.width ?? 0;
  const height = bounds?.height ?? 0;

  const isInsideBorder = (
    borderPosition: keyof NonNullable<Presentation.Data.Table.Cell.TableCellStyle['tcBdr']>,
  ) => {
    switch (borderPosition) {
      case 't': {
        return rowIndex > 0;
      }
      case 'b': {
        return rowIndex < positions[rowIndex]?.length - 1;
      }
      case 'l': {
        return colIndex > 0;
      }
      case 'r': {
        return colIndex < positions[colIndex]?.length - 1;
      }
      default: {
        return false;
      }
    }
  };

  const getTableStyleLn = (
    borderPosition: keyof NonNullable<Presentation.Data.Table.Cell.TableCellStyle['tcBdr']>,
  ) => {
    let cellInsideBorder: Presentation.Data.Table.Cell.Border | undefined = undefined;
    let defaultInsideBorder: Presentation.Data.Table.Cell.Border | undefined = undefined;

    if (isInsideBorder(borderPosition)) {
      switch (borderPosition) {
        case 't':
        case 'b': {
          cellInsideBorder = tblStyle?.[pos.placement]?.tcStyle?.tcBdr?.insideH;
          defaultInsideBorder = tblStyle?.wholeTbl?.tcStyle?.tcBdr?.insideH;
          break;
        }
        case 'l':
        case 'r': {
          cellInsideBorder = tblStyle?.[pos.placement]?.tcStyle?.tcBdr?.insideV;
          defaultInsideBorder = tblStyle?.wholeTbl?.tcStyle?.tcBdr?.insideV;
          break;
        }
      }
    }

    const defaultBorder =
      defaultInsideBorder ?? tblStyle?.wholeTbl?.tcStyle?.tcBdr?.[borderPosition];
    const cellBorder =
      cellInsideBorder ?? tblStyle?.[pos.placement]?.tcStyle?.tcBdr?.[borderPosition];

    return (cellBorder?.ln ?? cellBorder?.lnRef) || (defaultBorder?.ln ?? defaultBorder?.lnRef);
  };

  const fill =
    tc.tcPr.fill ||
    (tblStyle?.[pos.placement]?.tcStyle?.themeableFill?.fill ??
      tblStyle?.[pos.placement]?.tcStyle?.themeableFill?.fillRef) ||
    (tblStyle?.wholeTbl?.tcStyle?.themeableFill?.fill ??
      tblStyle?.wholeTbl?.tcStyle?.themeableFill?.fillRef);

  const lnT = tc.tcPr.lnT || getTableStyleLn('t');
  const lnR = tc.tcPr.lnR || getTableStyleLn('r');
  const lnB = tc.tcPr.lnB || getTableStyleLn('b');
  const lnL = tc.tcPr.lnL || getTableStyleLn('l');
  const lnTlToBr = tc.tcPr.lnTlToBr || getTableStyleLn('tl2br');
  const lnBlToTr = tc.tcPr.lnBlToTr || getTableStyleLn('tr2bl');

  return (
    <td
      key={tc.id}
      style={style}
      className={styles.td}
      rowSpan={tc.rowSpan ?? 1}
      colSpan={tc.gridSpan ?? 1}
      ref={ref}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width={width / zoom ?? '100%'}
        height={height / zoom ?? '100%'}
      >
        <Background
          position={NO_POSITION}
          size={{ ...bounds, width: width / zoom, height: height / zoom } ?? pos}
          fill={fill}
          targetId={tc.id}
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width={width / zoom ?? '100%'}
        height={height / zoom ?? '100%'}
        /**
         * overflow: 'visible' is used to make sure that the borders are not clipped
         * because the borders space isn't being taking into consideration in the bounds of the <td>
         */
        style={{ overflow: 'visible' }}
      >
        {lnT && <TableCellBorder ln={lnT} placement="T" />}
        {lnR && <TableCellBorder ln={lnR} placement="R" />}
        {lnB && <TableCellBorder ln={lnB} placement="B" />}
        {lnL && <TableCellBorder ln={lnL} placement="L" />}
        {lnTlToBr && <TableCellBorder ln={lnTlToBr} placement="TlToBr" />}
        {lnBlToTr && <TableCellBorder ln={lnBlToTr} placement="BlToTr" />}
      </svg>
      {tc.text && (
        <foreignObject>
          <TextBody text={tc.text} textStyle={textStyle} />
        </foreignObject>
      )}
    </td>
  );
};

export default TableCell;
