import { SelectionFixer } from 'Editor/services/_Common/Selection';
import { Command } from '../Command';

export class CutCommand extends Command {
  event: ClipboardEvent;

  constructor(context: Editor.Edition.Context, event: ClipboardEvent) {
    super(context);

    this.event = event;
  }

  async handleExec() {
    this.buildActionContext();

    this.getSuggestionRefFromContent();

    // handle collapsed selection
    if (this.actionContext && !this.actionContext.range.collapsed) {
      this.context.clipboard?.handleCut(this.event);

      // normalize text selection
      SelectionFixer.normalizeTextSelection(
        this.actionContext.range,
        {
          suggestionMode: this.context.editionMode === 'SUGGESTIONS',
          forceTextAsWrap: true,
        },
        this.context.DataManager,
      );

      this.context.contentManipulator?.removeContent(this.actionContext);
    }

    this.handleSuggestionsUpdate();

    this.applySelection();

    this.createPatch();
  }
}
