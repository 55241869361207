import { CSSProperties, useMemo } from 'react';
import cx from 'classnames';
import styles from './Thumbnail.module.scss';
import { usePresentationData } from 'Presentation/PresentationData';
import Slide from 'Presentation/Slides/Slide/Slide';
import { THUMBNAIL_WIDTH } from 'Presentation/consts';
import { usePresentationManager } from 'Presentation/PresentationManager';
import { useSelector } from '_common/hooks';
import SlideData from 'Presentation/Slides/Slide/SlideData';
import { ThumbnailCards } from '_common/suite/components';
import { useNotesSlide, useSlideSync } from 'Presentation/SyncStore';
import { useActiveComments, useActiveTasks } from 'Presentation/hooks';
import { NOTE_SHAPE_WHITELIST } from 'Presentation/Slides/Note/NoteData';

type ThumbnailProps = {
  index: number;
  style: CSSProperties;
};

const Thumbnail = ({ index, style }: ThumbnailProps) => {
  const { structure, size } = usePresentationData();
  const manager = usePresentationManager();
  const currentPageNum = useSelector((state) => state.presentation.general.currentSlide);
  const slideId = structure.sldIdLst[index];
  const slide = useSlideSync(slideId);
  const notesSlide = useNotesSlide(slide?.noteSld);
  const activeSlideComments = useActiveComments(slideId);
  const activeSlideTasks = useActiveTasks(slideId);

  const pageNum = index + 1;

  /**
   * Remove the last shape from the notes slide, which is the notes placeholder
   * and filter out any shapes that don't have any text
   */
  const slideHasNotes = useMemo(() => {
    return !!notesSlide?.spTree.shapes.filter(
      (slideShape) =>
        slideShape.nvProperties?.ph?.type &&
        NOTE_SHAPE_WHITELIST.includes(slideShape.nvProperties?.ph?.type) &&
        slideShape.text?.childNodes?.[0].childNodes?.length,
    ).length;
  }, [notesSlide]);

  const zoom = (THUMBNAIL_WIDTH - 6 - 6) / size.width;
  const isCurrent = pageNum === currentPageNum;

  const goTo = () => {
    manager.navigation?.goTo(pageNum);
  };

  return (
    <div style={style} className={cx(styles.container, { [styles.current]: isCurrent })}>
      <div className={styles.background} onClick={goTo}>
        <div className={styles.thumbnail}>
          <div style={{ zoom }}>
            <SlideData slideId={slideId} isThumbnailSlide>
              <Slide />
            </SlideData>
          </div>
          <ThumbnailCards
            comments={activeSlideComments?.length}
            tasks={activeSlideTasks?.length}
            notes={slideHasNotes}
          />
        </div>
      </div>
      <div className={styles.number}>
        <div>{pageNum}</div>
      </div>
    </div>
  );
};

export default Thumbnail;
