import { useEffect, useMemo } from 'react';

import { useSlideData } from '../SlideData';
import { useShapeData } from './ShapeData';
import useGeometry from './useGeometry';
import useOutline from './useOutline';

import { modifyColor } from './Preset/utils';
import Pattern from '../Background/Pattern';
import Image from './Image';
import GradientDefinition from '../GradientDefinition';
import { useSelector } from '_common/hooks';
import { cloneObject } from 'Presentation/utils';
import { useGroupData } from './Group/GroupData';

type BackgroundProps = {
  fill?: Presentation.Data.Common.FillType;
  geometry?: Presentation.Shape.Geometry;
  xfrm?: Presentation.Shape.Xfrm;
  outline?: Presentation.Data.Common.Outline;
  position: Presentation.Data.Common.Position;
  size: Presentation.Data.Common.Size;
  /**
   * Target ID is used to differentiate between the background of multiple elements of the same shape
   * Example:
   * Table shape has multiple cells, each cell share the same shape id but has an unique id
   * that has to be used to differentiate between the background of each cell
   */
  targetId?: string;
};

/**
 *
 * TODO:PRESENTATION
 * use this Background component to render the background
 * src/Presentation/Slides/Slide/Background/Background.tsx
 */

const Background = ({
  fill,
  geometry,
  xfrm,
  outline,
  position,
  size,
  targetId,
}: BackgroundProps) => {
  const { color, addUnsupportedElement, slideId, theme } = useSlideData();
  const { shape } = useShapeData();
  const parentGroup = useGroupData();

  const { paths, inverseTransform } = useGeometry(geometry, size, position, shape.properties);
  const { parseOutline } = useOutline();
  const outlineProps = parseOutline(outline);

  const zoom = useSelector((state) => state.presentation.general.zoom);

  const shapeType = useMemo(() => {
    switch (shape.type) {
      case 'chart':
      case 'chartex':
        return 'Chart';
      case 'picture':
        return 'Picture';
      case 'table':
        return 'Table';
      default:
        return 'Shape';
    }
  }, [shape]);

  const suffixId = targetId ? `-${targetId}` : '';
  const bgId = `${slideId}-${shape.origin}-${shape.id}${suffixId}-bg`;
  const outlineId = `${slideId}-${shape.origin}-${shape.id}${suffixId}-outline`;
  const ellipseId = `${slideId}-${shape.origin}-${shape.id}${suffixId}-ellipse`;
  const ellipsePathId = `${slideId}-${shape.origin}-${shape.id}${suffixId}-ellipsePath`;

  //Process Shape properties to detect unsupported properties
  useEffect(() => {
    //TODO:PRESENTATION:UNSUPPORTED:SHAPE:EFFECTS
    shape.properties.effects?.forEach((effect) => {
      addUnsupportedElement(`${shapeType} Effect - ${effect.type}`);
    });

    if (shape.properties.sp3d?.bevelT || shape.properties.sp3d?.bevelB) {
      //TODO:PRESENTATION:UNSUPPORTED:SHAPE:EFFECTS
      addUnsupportedElement(`${shapeType} Effect - Bevel`);
    }

    if (shape.properties.sp3d?.prstMaterial) {
      //TODO:PRESENTATION:UNSUPPORTED:SHAPE:EFFECTS
      addUnsupportedElement(`${shapeType} Effect - Material`);
    }

    if (
      shape.properties.scene3d?.camera.prst &&
      shape.properties.scene3d.camera.prst !== 'orthographicFront'
    ) {
      //TODO:PRESENTATION:UNSUPPORTED:SHAPE:EFFECTS
      addUnsupportedElement(`${shapeType} Effect - Perspective`);
    }

    //Process Picture specific properties to detect unsupported properties
    if (shape.type === 'picture') {
      const recolorEffects: Presentation.Data.Effect['type'][] = [
        'grayscale',
        'duotone',
        'lum',
        'biLevel',
      ];

      //TODO:PRESENTATION:UNSUPPORTED:PICTURE:RECOLOR
      if (shape.fill.effects?.some((effect) => recolorEffects.includes(effect.type))) {
        addUnsupportedElement(`Picture Effect - Recolor`);
      }

      //TODO:PRESENTATION:UNSUPPORTED:PICTURE:CROPTOSHAPE
      if (
        shape.properties.geom?.type === 'custom' ||
        (shape.properties.geom?.type === 'prst' && shape.properties.geom.prst !== 'rect')
      ) {
        addUnsupportedElement(`Picture Effect - Crop to Shape`);
      }
    }
  }, [shape]);

  const handleStyleReferenceColor = ({
    referencedColor,
    styledColor,
  }: {
    referencedColor: Presentation.Data.Common.Color;
    styledColor: Presentation.Data.Common.Color;
  }) => {
    if (referencedColor.reference === 'phClr') {
      return {
        ...styledColor,
        mods: styledColor.mods?.length ? styledColor.mods : referencedColor.mods,
      };
    }

    return referencedColor;
  };

  /**
   *
   * @param fill Fill to be rendered
   * @param id Id of the fill definition
   * @param refFill Reference fill with the reference of the fill that will be rendered
   * @returns JSX.Element | null
   */
  const renderFillDef = (
    fill: Presentation.Data.Common.FillType | undefined,
    id: string,
    refFill?: Presentation.Data.StyleRef | Presentation.Data.Common.ReferenceFill,
  ): JSX.Element | null => {
    const clonedFill = cloneObject(fill);

    if (clonedFill) {
      switch (clonedFill.type) {
        case 'solid': {
          if (refFill && 'color' in refFill) {
            if ('color' in clonedFill) {
              clonedFill.color = handleStyleReferenceColor({
                referencedColor: clonedFill.color,
                styledColor: refFill.color,
              });
            }
          }
          return (
            <pattern id={id} width="1" height="1" patternUnits="userSpaceOnUse">
              <rect width="1" height="1" fill={color(clonedFill.color)} />
            </pattern>
          );
        }
        case 'gradient': {
          if (refFill && 'color' in refFill) {
            const stops = clonedFill.stops.map((stop) => {
              stop.color = handleStyleReferenceColor({
                referencedColor: stop.color,
                styledColor: refFill.color,
              });
              return stop;
            });
            clonedFill.stops = stops;
          }
          return <GradientDefinition id={id} gradientFill={clonedFill} forShape />;
        }
        case 'pattern': {
          if (refFill && 'color' in refFill) {
            clonedFill.background = handleStyleReferenceColor({
              referencedColor: clonedFill.background,
              styledColor: refFill.color,
            });

            clonedFill.foreground = handleStyleReferenceColor({
              referencedColor: clonedFill.foreground,
              styledColor: refFill.color,
            });
          }
          return (
            <Pattern
              id={id}
              pattern={clonedFill.preset}
              backgroundColor={color(clonedFill.background)}
              foregroundColor={color(clonedFill.foreground)}
              scaleTo={inverseTransform}
            />
          );
        }
        case 'picture': {
          if (clonedFill.tile) {
            //TODO:PRESENTATION:UNSUPPORTED:BACKGROUND:FILL
            addUnsupportedElement(`${shapeType} - Fill - Texture`);
          } else {
            //TODO:PRESENTATION:UNSUPPORTED:BACKGROUND:PICTURE
            addUnsupportedElement(`${shapeType} - Fill - Picture`);
          }
          return null;
        }
        case 'reference': {
          const reference = clonedFill.reference;
          if (reference in theme.formatScheme) {
            const referencedFill =
              theme.formatScheme[reference as keyof typeof theme.formatScheme][clonedFill.index];

            return renderFillDef(referencedFill, id, clonedFill);
          }
          return null;
        }
        case 'group':
          return renderFillDef(parentGroup?.properties.fill, id);
      }
    }
    return null;
  };

  return (
    <>
      <g id={`${shape.id}-background`} transform={`translate(${position.left}, ${position.top})`}>
        <defs>
          {renderFillDef(fill, bgId)}
          {renderFillDef(outline?.fill, outlineId)}
        </defs>
        <g
          //This is responsible for the rotation and flip of shapes
          style={{
            transformOrigin: 'center center',
            transform: `
              rotate(${xfrm?.rot ?? 0}deg) 
              scale(${xfrm?.flipH ? -1 : 1}, ${xfrm?.flipV ? -1 : 1})
            `,
            transformBox: 'fill-box',
          }}
        >
          {paths
            //Sort so stroke paths are on top of fill paths
            .sort((a) => (a.stroke === 'false' ? -1 : 1))
            .map(({ fillModifier, custom, fillIdSuffix, ...shapeProps }) => {
              const { stroke, ...strokeProps } = outlineProps;

              const renderStroke = shapeProps.stroke !== 'false';
              const renderFill = shapeProps.fill !== 'none';
              const modifiedFill =
                fill?.type === 'solid' ? modifyColor(color(fill.color), fillModifier) : undefined;

              switch (custom?.type) {
                case 'line':
                  return (
                    <line
                      key={`${shapeProps.d}-${shapeProps.fill ? 'fill' : 'nofill'}-${
                        renderStroke ? 'stroke' : 'nostroke'
                      }`}
                      x1={custom.x1}
                      y1={custom.y1}
                      /**
                       * By default, gradientUnits is objectBoundingBox
                       * This should not be used when the geometry of the applicable element has no width or no height,
                       * such as the case of a horizontal or vertical line
                       * Add low offset to avoid perfect horizontal/vertical line
                       * Source: https://stackoverflow.com/a/73043947
                       */
                      x2={custom.x2 + 0.001}
                      y2={custom.y2 + 0.001}
                      {...shapeProps}
                      {...strokeProps}
                      stroke={renderStroke ? `url(#${outlineId})` : 'false'}
                    />
                  );
                default:
                  /**
                   * Shapes can send a fillIdSuffix to differentiate between special fills (e.g. scaled fill for cloud)
                   * This is only applied to pattern fills
                   */
                  const fillId =
                    fillIdSuffix && fill?.type === 'pattern' ? `${bgId}-${fillIdSuffix}` : bgId;

                  //Non scaling stroke will avoid scale with zoom and it should, apply the scale to stroke width instead
                  const strokeWidth =
                    shapeProps.vectorEffect === 'non-scaling-stroke' && strokeProps.strokeWidth
                      ? +strokeProps.strokeWidth * zoom
                      : strokeProps.strokeWidth;

                  return (
                    <path
                      key={`${shapeProps.d}-${shapeProps.fill ? 'fill' : 'nofill'}-${
                        renderStroke ? 'stroke' : 'nostroke'
                      }`}
                      {...shapeProps}
                      {...strokeProps}
                      strokeWidth={strokeWidth}
                      stroke={renderStroke ? `url(#${outlineId})` : 'false'}
                      fill={renderFill ? modifiedFill || `url(#${fillId})` : 'none'}
                      fillRule="evenodd"
                    />
                  );
              }
            })}
        </g>
      </g>
      {shape.type === 'picture' && (
        <g id={`${shape.id}-picture`} transform={`translate(${position.left}, ${position.top})`}>
          <g
            //This is responsible for the rotation and flip of images
            style={{
              transformOrigin: 'center center',
              transform: `
              rotate(${xfrm?.rot ?? 0}deg) 
              scale(${xfrm?.flipH ? -1 : 1}, ${xfrm?.flipV ? -1 : 1})
            `,
              transformBox: 'fill-box',
            }}
          >
            {paths.map(({ fillModifier, custom, ...shapeProps }) => {
              return (
                <>
                  {!shape.fill.effects && (
                    <path
                      key={shapeProps.d}
                      x={position.left}
                      y={position.top}
                      {...shapeProps}
                      {...outline}
                      fill="transparent"
                      stroke="none"
                      id={ellipsePathId}
                    />
                  )}
                  <clipPath id={ellipseId}>
                    <use xlinkHref={`#${ellipsePathId}`} />
                  </clipPath>
                  {shape.fill.type === 'picture' && (
                    <Image
                      fill={shape.fill}
                      clip={
                        shape.nvProperties?.userDrawn ||
                        !shape.nvProperties?.ph?.type ||
                        shape.nvProperties?.ph?.sz === 'quarter'
                          ? 'none'
                          : `url(#${ellipseId})`
                      }
                    />
                  )}
                </>
              );
            })}
          </g>
        </g>
      )}
    </>
  );
};

export default Background;
