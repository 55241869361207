import { Dispatch, FC, SetStateAction, memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Accordion,
  Dropdown,
  EmptyState,
  SectionHeader,
  Table,
  Toggle,
  Tooltip,
} from 'dodoc-design-system';
import type { ColumnProps } from 'dodoc-design-system/build/types/Components/Table/Table';
import cn from 'classnames';

import { openModal } from 'App/ModalContext/utils';
import { useOrderMetadataMutation } from 'App/redux/MetadataApi';
import in_ from '_common/utils/in';

import Cell from '_common/components/Table2/Cells';
import NotApplicable from './NotApplicable';
import { DATA_TYPE_TRANSLATIONS } from './const';

import styles from './Metadata.module.scss';

type ManualMetadataProps = {
  metadata: ApiSchemas['MetadataObjectSchema'][];
  selected: number;
  select: Dispatch<SetStateAction<number>>;
  deleteMetadata: (id: string, name: string) => void;
};

const ManualMetadata: FC<ManualMetadataProps> = ({
  metadata,
  selected,
  select,
  deleteMetadata,
}) => {
  const intl = useIntl();
  const selectedMetadata = metadata[selected];

  const [orderMetadata] = useOrderMetadataMutation();

  const columns = useMemo<ColumnProps[]>(() => {
    return [
      {
        id: 'name',
        width: 30 * 8,
        minWidth: 30 * 8,
        header: intl.formatMessage({ id: 'global.name' }),
        flex: true,
      },
      {
        id: 'dataType',
        width: 21 * 8,
        header: intl.formatMessage({ id: 'DATA_TYPE' }),
      },
      {
        id: 'value',
        width: 29 * 8,
        header: intl.formatMessage({ id: 'VALUE' }),
      },
      {
        id: 'characterLimit',
        width: 17 * 8,
        header: intl.formatMessage({ id: 'CHARACTER_LIMIT' }),
      },
      {
        id: 'required',
        width: 10 * 8,
        header: intl.formatMessage({ id: 'REQUIRED' }),
      },
      {
        id: 'move',
        width: 9 * 8,
      },
    ];
  }, []);

  const value = useMemo(() => {
    return metadata.map((m, index) => ({
      id: m.id,
      name: (
        <Cell testId={`${m.id}-name-column`} ellipsis>
          {m.name}
        </Cell>
      ),
      dataType: (
        <Cell testId={`${m.id}-data-type-column`} ellipsis>
          {in_(m.data_type, DATA_TYPE_TRANSLATIONS)
            ? DATA_TYPE_TRANSLATIONS[m.data_type]
            : m.data_type}
        </Cell>
      ),
      value: (
        <Cell testId={`${m.id}-value-column`} ellipsis>
          {(m.data_type === 'select' || m.data_type === 'multi-select') && m.options ? (
            m.options.map((option) => option.fields.join('-')).join(', ')
          ) : (
            <NotApplicable place="table" />
          )}
        </Cell>
      ),
      characterLimit: (
        <Cell testId={`${m.id}-character-limit-column`}>
          {m.data_type === 'string' ? m.char_limit : <NotApplicable place="table" />}
        </Cell>
      ),
      required: (
        <Cell testId={`${m.id}-required-column`}>
          <div className={styles.capitalize}>{m.required.toString()}</div>
        </Cell>
      ),
      move: (
        <Cell testId={`${m.id}-move-column`}>
          <div style={{ display: 'flex' }}>
            <Tooltip
              testId={`${m.id}-move-up-tooltip`}
              content={<FormattedMessage id="MOVE_METADATA_UP" />}
              disabled={index === 0}
            >
              <Toggle
                testId={`${m.id}-move-up-toggle`}
                variant="link"
                size="small"
                icon="ArrowUp"
                disabled={index === 0}
                onClick={(e) => handleMoveUp(e, m.id)}
              />
            </Tooltip>
            <Tooltip
              testId={`${m.id}-move-down-tooltip`}
              content={<FormattedMessage id="MOVE_METADATA_DOWN" />}
              disabled={index === metadata.length - 1}
            >
              <Toggle
                testId={`${m.id}-move-down-toggle`}
                variant="link"
                size="small"
                icon="ArrowDown"
                disabled={index === metadata.length - 1}
                margin="0 0 0 1rem"
                onClick={(e) => handleMoveDown(e, m.id)}
              />
            </Tooltip>
          </div>
        </Cell>
      ),
      suffix: (
        <Cell testId={`${m.id}-options-column`}>
          <Cell.Options
            items={
              <>
                <Dropdown.Item
                  size="large"
                  onClick={(e) => {
                    e.stopPropagation();
                    edit(m);
                  }}
                  testId="edit-metadata-dropdown-item"
                >
                  <FormattedMessage id="EDIT_METADATA" />
                </Dropdown.Item>
                {(m.data_type === 'select' || m.data_type === 'multi-select') && (
                  <Dropdown.Item
                    size="large"
                    onClick={(e) => {
                      e.stopPropagation();
                      edit(m, true);
                    }}
                    testId="edit-select-options-dropdown-item"
                  >
                    <FormattedMessage id="EDIT_SELECT_OPTIONS" />
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  size="large"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteMetadata(m.id, m.name);
                  }}
                  testId="delete-metadata-dropdown-item"
                >
                  <FormattedMessage id="DELETE_METADATA" />
                </Dropdown.Item>
              </>
            }
            placement="bottom-start"
            testId={m.id}
            stopEventPropagation
          />
        </Cell>
      ),
    }));
  }, [metadata]);

  const handleMoveUp = (e: React.MouseEvent<HTMLSpanElement>, metadataId: string) => {
    e.stopPropagation();
    orderMetadata({ object_id: metadataId, direction: 'up' });
  };
  const handleMoveDown = (e: React.MouseEvent<HTMLSpanElement>, metadataId: string) => {
    e.stopPropagation();
    orderMetadata({ object_id: metadataId, direction: 'down' });
  };

  const edit = (metadata: ApiSchemas['MetadataObjectSchema'], editOptions?: boolean) => {
    if (!metadata.automatic) {
      openModal({
        modal: 'ManualMetadataModal',
        data: { metadata, editOptions },
      });
    }
  };

  return (
    <div className={styles.section}>
      {metadata.length === 0 ? (
        <EmptyState
          size="medium"
          testId="manual-metadata-empty-state"
          title={intl.formatMessage({ id: 'NO_METADATA_IN_TENANT_TITLE' })}
        >
          <FormattedMessage id="NO_METADATA_IN_TENANT_BODY" />
        </EmptyState>
      ) : (
        <>
          <div className={styles.table}>
            <Table
              testId="manual-metadata-table"
              columns={columns}
              value={value}
              onRowClick={({ index }) => {
                select(index);
              }}
              flex
              // @ts-expect-error only prop id is needed
              selection={[{ id: selectedMetadata?.id }]}
            />
          </div>
          {selectedMetadata && (
            <div className={styles.details}>
              <SectionHeader
                margin="0 0 1rem 0"
                suffixButtonProps={{
                  testId: 'manual-details-close-button',
                  icon: 'CloseGrey',
                  size: 'medium',
                  variant: 'link',
                  onClick: () => {
                    select(-1);
                  },
                }}
              >
                {selectedMetadata.name}
              </SectionHeader>
              <div className={styles.content}>
                <div className={styles.field} data-testid="manual-name-field">
                  <FormattedMessage id="global.name" />
                  <div className={styles.value}>{selectedMetadata.name}</div>
                </div>
                <div className={styles.field} data-testid="manual-data-type-field">
                  <FormattedMessage id="DATA_TYPE" />
                  <div className={styles.value}>
                    {in_(selectedMetadata.data_type, DATA_TYPE_TRANSLATIONS)
                      ? DATA_TYPE_TRANSLATIONS[selectedMetadata.data_type]
                      : selectedMetadata.data_type}
                  </div>
                </div>
                <div className={styles.field} data-testid="manual-value-field">
                  <FormattedMessage id="VALUE" />
                  <div className={styles.value}>
                    {selectedMetadata.data_type === 'select' ||
                    selectedMetadata.data_type === 'multi-select' ? (
                      <div className={styles.values}>
                        {selectedMetadata.options.map((value, optionIndex) => (
                          <Accordion
                            key={value.fields.join('-')}
                            size="medium"
                            testId={`selected-option-${optionIndex}-accordion`}
                            title={value.fields.join('-')}
                            collapsable={value.extra.length > 0}
                            contentMargin="1rem 0 0 2rem"
                          >
                            {value.extra.map((extra, extraIndex) => {
                              return (
                                <Accordion
                                  contentMargin="1rem 0 0 3rem "
                                  key={extra.label + '-' + extra.value}
                                  title={intl.formatMessage(
                                    {
                                      id: 'ADDITIONAL_INFORMATION_#',
                                    },
                                    { number: extraIndex + 1 },
                                  )}
                                  size="medium"
                                  testId={`selected-option-${optionIndex}-extra-${extraIndex}-accordion`}
                                >
                                  <div className={styles.label}>
                                    <FormattedMessage id="global.name" />
                                  </div>
                                  <div className={styles.value}>{extra.label}</div>
                                  <div className={styles.label}>
                                    <FormattedMessage id="VALUE" />
                                  </div>
                                  <div className={styles.value}>{extra.value}</div>
                                </Accordion>
                              );
                            })}
                          </Accordion>
                        ))}
                      </div>
                    ) : (
                      <NotApplicable place="details" />
                    )}
                  </div>
                </div>
                <div className={styles.field} data-testid="manual-char-limit-field">
                  <FormattedMessage id="CHARACTER_LIMIT" />
                  <div className={styles.value}>
                    {selectedMetadata.data_type === 'string' ? (
                      selectedMetadata.char_limit
                    ) : (
                      <NotApplicable place="details" />
                    )}
                  </div>
                </div>
                <div className={styles.field} data-testid="manual-required-field">
                  <FormattedMessage id="REQUIRED" />
                  <div className={cn(styles.value, styles.capitalize)}>
                    {selectedMetadata.required.toString()}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default memo(ManualMetadata);
