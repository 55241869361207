import { createContext, memo, ReactNode, useContext } from 'react';

type GroupContextValue = Presentation.Data.Shape;

const GroupContext = createContext<GroupContextValue | undefined>(undefined);

type GroupDataProps = {
  children: ReactNode;
  shape: Presentation.Data.GroupShape;
};

const GroupDataProvider = ({ children, shape }: GroupDataProps) => {
  return <GroupContext.Provider value={shape}>{children}</GroupContext.Provider>;
};

export const useGroupData = () => {
  return useContext(GroupContext);
};

export default memo(GroupDataProvider);
