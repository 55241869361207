import { OUTLINE_CAP, OUTLINE_DASHARRAY } from 'Presentation/consts';
import { useSlideData } from '../SlideData';
import { useShapeData } from './ShapeData';
import { useMemo } from 'react';
import { cloneObject } from 'Presentation/utils';

const dashArray = ({
  dash,
  width,
}: {
  dash: Presentation.Data.Common.Outline['dash'];
  width: number | undefined;
}) => {
  const dashArray = OUTLINE_DASHARRAY[dash ?? 'solid'];
  const strokeWidth = width ?? 1; //px

  if (!Array.isArray(dashArray)) {
    return dashArray;
  }

  return dashArray.map((value) => value * strokeWidth).toString();
};

const useOutline = () => {
  const { color, addUnsupportedElement, theme } = useSlideData();
  const { shape } = useShapeData();

  const shapeType = useMemo(() => {
    switch (shape.type) {
      case 'chart':
      case 'chartex':
        return 'Chart';
      case 'picture':
        return 'Picture';
      case 'table':
        return 'Table';
      default:
        return 'Shape';
    }
  }, [shape]);

  const handleStyleReferenceColor = ({
    referencedColor,
    styledColor,
  }: {
    referencedColor: Presentation.Data.Common.Color;
    styledColor: Presentation.Data.Common.Color;
  }) => {
    if (referencedColor.reference === 'phClr') {
      return {
        ...styledColor,
        mods: styledColor.mods?.length ? styledColor.mods : referencedColor.mods,
      };
    }

    return referencedColor;
  };

  const parseOutline = (
    ln: Presentation.Data.Common.Outline | Presentation.Data.LineStyleRef | undefined,
    refLn?: Presentation.Data.StyleRef | Presentation.Data.Common.Outline,
  ): {
    strokeWidth?: number;
    stroke?: string;
    strokeDasharray?: string;
    strokeLinejoin?: NonNullable<Presentation.Data.Common.Outline['join']>['type'];
    strokeLinecap?: (typeof OUTLINE_CAP)[keyof typeof OUTLINE_CAP];
    strokeMiterlimit?: number;
  } => {
    if (ln) {
      const clonedLn = cloneObject(ln);

      if ('type' in clonedLn) {
        switch (clonedLn.type) {
          case 'reference': {
            const reference = clonedLn.reference;
            if (reference in theme.formatScheme) {
              const referencedLn =
                theme.formatScheme[reference as keyof typeof theme.formatScheme][clonedLn.index];

              return parseOutline(referencedLn, clonedLn);
            }
          }
        }
      } else {
        if (refLn && 'color' in refLn) {
          if (clonedLn.fill && 'color' in clonedLn.fill) {
            clonedLn.fill.color = handleStyleReferenceColor({
              referencedColor: clonedLn.fill.color,
              styledColor: refLn.color,
            });
          }
        }

        const strokeWidth = clonedLn.w ?? 1;
        const c =
          clonedLn?.fill?.type === 'solid' ? clonedLn.fill.color : clonedLn.fill?.stops?.[0]?.color;
        const strokeDasharray = dashArray({ dash: clonedLn.dash, width: clonedLn.w });
        const strokeLinejoin = clonedLn.join?.type;
        const strokeLinecap =
          /**
           * This seems to be a limitation between browser and powerpoint
           * In browser, the "butt" (flat) stroke-linecap attribute is not supported with "dot" dasharray
           * because of the value 0 in the dasharray and in powerpoint this is not the case.
           * So when the dash is dot, we fallback the "butt" to "square" in order to render something
           */
          clonedLn.dash === 'dot' && clonedLn.cap === 'flat'
            ? 'square'
            : clonedLn.cap
            ? OUTLINE_CAP[clonedLn.cap]
            : undefined; // for some reason they use flat for the 'butt' stroke-linecap attribute
        const strokeMiterlimit = clonedLn.join?.type === 'miter' ? clonedLn.join.lim : undefined;

        let unsupportedCmpd = '';

        switch (clonedLn.cmpd) {
          case 'dbl':
            unsupportedCmpd = 'Double';
            break;
          case 'thickThin':
            unsupportedCmpd = 'Thick thin';
            break;
          case 'thinThick':
            unsupportedCmpd = 'Thin thick';
            break;
          case 'tri':
            unsupportedCmpd = 'Triple';
            break;
        }

        if (unsupportedCmpd) {
          //TODO:PRESENTATION:UNSUPPORTED:OUTLINE:COMPOUND
          addUnsupportedElement(`${shapeType} - Line Compound type - ${unsupportedCmpd}`);
        }

        //Warning won't appear if property is undefined or 'none'
        if (clonedLn.headEnd && clonedLn.headEnd.type !== 'none') {
          //TODO:PRESENTATION:UNSUPPORTED:OUTLINE:ARROW (rework to use markers)
          addUnsupportedElement(`${shapeType} - Line - Begin arrow`);
        }

        //Warning won't appear if property is undefined or 'none'
        if (clonedLn.tailEnd && clonedLn.tailEnd.type !== 'none') {
          //TODO:PRESENTATION:UNSUPPORTED:OUTLINE:ARROW (rework to use markers)
          addUnsupportedElement(`${shapeType} - Line - End arrow`);
        }

        if (c) {
          return {
            strokeWidth,
            stroke: color(c),
            strokeDasharray,
            strokeLinejoin,
            strokeLinecap,
            strokeMiterlimit,
          }; // missing other stroke attributes
        }
      }
    }

    return {};
  };

  return { parseOutline };
};

export default useOutline;
