import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button, InputField, Input } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import styles from './SplitCellsModal.module.scss';
import { closeModal } from '_common/modals/ModalsSlice';
import EditorManager from 'Editor/services/EditorManager';
import { Logger } from '_common/services';
import { notify } from '_common/components/ToastSystem';

const MODAL = 'SplitCellsModal';

const SplitCellsModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const selectedTable: any = useSelector((state) => state.editor.status.selection.TABLE);
  const currentCell = selectedTable?.selectedCells?.[0] || {};

  const [numberOfColumns, setNumberOfColumns] = useState('2');
  const [numberOfRows, setNumberOfRows] = useState('1');
  const [columnFeedback, setColumnFeedback] = useState<string>();
  const [rowFeedback, setRowFeedback] = useState<string>();

  useEffect(() => {
    setColumnFeedback(
      +numberOfColumns < 0
        ? intl.formatMessage({ id: 'NUMBER_OF_COLUMNS_CANNOT_BE_NEGATIVE' })
        : undefined,
    );
  }, [numberOfColumns]);

  useEffect(() => {
    setRowFeedback(
      +numberOfRows < 0
        ? intl.formatMessage({ id: 'NUMBER_OF_ROWS_CANNOT_BE_NEGATIVE' })
        : undefined,
    );
  }, [numberOfRows]);

  useEffect(() => {
    if (currentCell.isMergedCell) {
      setNumberOfColumns(currentCell.colSpan);
      setNumberOfRows(currentCell.rowSpan);
    } else {
      setNumberOfColumns('2');
      setNumberOfRows('1');
    }
  }, [currentCell.isMergedCell]);

  const close = () => {
    dispatch(closeModal('SplitCellsModal'));
    setNumberOfColumns('2');
    setNumberOfRows('1');
  };

  const handleSplitCells = () => {
    try {
      EditorManager.getInstance().splitCells(+numberOfColumns, +numberOfRows);
      notify({
        type: 'success',
        title: 'SPLIT_CELLS_SUCCESS',
        message: 'SPLIT_CELLS_SUCCESS_MESSAGE',
      });
    } catch (error) {
      Logger.captureException(error);
    } finally {
      close();
    }
  };

  const handleColumnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNumberOfColumns(e.target.value);
  };

  const handleRowChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNumberOfRows(
      currentCell.isMergedCell && e.target.value > currentCell.rowSpan
        ? currentCell.rowSpan
        : e.target.value,
    );
  };

  return (
    <Modal open={!!isOpen} width="70rem" onClose={close} testId="split-cells">
      <Modal.Header onClose={close}>
        <FormattedMessage id="editor.menu.table.splitCells" />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <InputField
            label={intl.formatMessage({ id: 'NUMBER_OF_COLUMNS' })}
            size="large"
            margin="0 2rem 0 0"
            testId="number-of-columns-field"
            feedback={columnFeedback}
          >
            <Input
              type="number"
              value={numberOfColumns}
              onChange={handleColumnChange}
              placeholder=""
              testId="number-of-columns"
              error={!!columnFeedback}
            ></Input>
          </InputField>
          <InputField
            label={intl.formatMessage({ id: 'NUMBER_OF_ROWS' })}
            size="large"
            testId="number-of-rows-field"
            feedback={rowFeedback}
          >
            <Input
              type="number"
              value={numberOfRows}
              onChange={handleRowChange}
              placeholder=""
              testId="number-of-rows"
              error={!!rowFeedback}
            ></Input>
          </InputField>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="split-cells-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleSplitCells}
          disabled={!numberOfColumns || !numberOfRows || !!columnFeedback || !!rowFeedback}
          testId="split-cells-submit-button"
        >
          <FormattedMessage id="editor.menu.table.splitCells" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SplitCellsModal;
