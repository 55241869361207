import { FormattedMessage, useIntl } from 'react-intl';
import { Tag } from 'dodoc-design-system';

import { FormattedDate, UsernameLabel } from '_common/components';
import { useSuiteObject } from '_common/suite';

import PanelHeading from '../PanelHeading/PanelHeading';
import Row from './Row/Row';
import styles from './Details.module.scss';

const DOCUMENT_TYPES = {
  document: 'object.type.doDOCDoc',
  dopdf: 'PDF_DOCUMENT',
  presentation: '',
};

const Details = () => {
  const intl = useIntl();
  const object = useSuiteObject();

  if (!object) {
    return null;
  }

  return (
    <>
      <PanelHeading>
        <FormattedMessage id="storage.browserSidebar.details" />
      </PanelHeading>
      <div className={styles.content}>
        <div className={styles.list} data-testid="details-list">
          <Row title={intl.formatMessage({ id: 'settings.general.title' })} testId="title">
            {object.name}
          </Row>
          <Row title={intl.formatMessage({ id: 'CREATION_DATE' })} testId="creation-date">
            <FormattedDate date={object.time.creation} />
          </Row>
          <Row title={intl.formatMessage({ id: 'DOCUMENT_TYPE' })} testId="type">
            {DOCUMENT_TYPES[object.type] ? (
              <FormattedMessage id={DOCUMENT_TYPES[object.type]} />
            ) : (
              'doPPT'
            )}
          </Row>
          <Row title={intl.formatMessage({ id: 'global.owner' })} testId="owner">
            <UsernameLabel userId={object.owner} />
          </Row>
          <Row
            title={intl.formatMessage({ id: 'global.description' })}
            empty={intl.formatMessage({ id: 'NO_DESCRIPTION' })}
            testId="description"
          >
            {object.description}
          </Row>

          <Row
            title={intl.formatMessage({ id: 'settings.general.tags' })}
            empty={intl.formatMessage({ id: 'NO_TAGS_ASSOCIATED_WITH_THIS_DOCUMENT' })}
            testId="tags"
          >
            {object.tags?.length > 0 ? (
              <div className={styles.tags}>
                {object.tags.map((tag) => {
                  return (
                    <Tag
                      key={tag}
                      variant="neutral"
                      disabled
                      onClick={() => {}}
                      value={tag}
                      testId={`tags-${tag}`}
                    >
                      {tag}
                    </Tag>
                  );
                })}
              </div>
            ) : null}
          </Row>
          <Row
            title={intl.formatMessage({ id: 'settings.general.dueDate' })}
            empty={intl.formatMessage({ id: 'NO_DUE_DATE_DEFINED_FOR_THIS_DOCUMENT' })}
            testId="due-date"
          >
            {object.events.due ? <FormattedDate date={object.events.due} /> : null}
          </Row>
          {object.events.due && (
            <Row
              title={intl.formatMessage({ id: 'storage.browserSidebar.dueDate.warningDate' })}
              testId="reminders"
            >
              {object.events.warnings?.length > 0 ? (
                <FormattedMessage id="global.on" />
              ) : (
                <FormattedMessage id="global.off" />
              )}
            </Row>
          )}
        </div>
      </div>
    </>
  );
};

export default Details;
