import cx from 'classnames';
import { Icon, usePopper } from 'dodoc-design-system';

import styles from './CardToggle.module.scss';
import { ReactNode, forwardRef, useMemo, Ref, MouseEvent as ReactMouseEvent } from 'react';
import { useSelector } from '_common/hooks';
import { PopperProps } from 'dodoc-design-system/build/types/Components/Popper/Popper';

export type ToggleProps = {
  children: ReactNode;
  type: 'task' | 'comment';
  isOpen: boolean;
  canMove?: boolean;
  placement?: PopperProps['placement'];
  onClick?: () => void;
  onMoveDown?: (e: ReactMouseEvent<HTMLDivElement>) => void;
};

const Toggle = forwardRef(
  (
    { type, isOpen, canMove, placement, children, onClick, onMoveDown }: ToggleProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const { referenceProps, popperProps } = usePopper({
      placement,
      distance: 12,
      closeOnReferenceHidden: true,
    });

    const rightPanel = useSelector((state) => state.presentation.general.rightPanel);

    const showCard = useMemo(() => {
      return rightPanel == null;
    }, [rightPanel]);

    const icon = useMemo(() => {
      switch (type) {
        case 'comment': {
          if (isOpen) {
            return 'PDFCommentBlue';
          }
          return 'PDFCommentGrey';
        }
        case 'task': {
          if (isOpen) {
            return 'TaskBlue';
          }
          return 'TaskGrey';
        }
      }
    }, [type, isOpen]);

    return (
      <div ref={ref}>
        <div
          className={cx(styles.toggle, {
            [styles.selected]: isOpen,
            [styles.canMove]: canMove,
          })}
          ref={referenceProps.ref}
        >
          <div
            className={styles.icon}
            onClick={(e) => {
              e.stopPropagation();
              onClick?.();
            }}
            onMouseDown={(e) => {
              if (canMove) {
                onMoveDown?.(e);
              }
            }}
          >
            <Icon size={24} icon={icon} />
          </div>
        </div>
        {isOpen && showCard && (
          <div
            className={`${styles.card}`}
            ref={popperProps.ref}
            style={popperProps.style}
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        )}
      </div>
    );
  },
);

export default Toggle;
