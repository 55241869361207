import {
  Button,
  Dropdown,
  InputField,
  SectionHeader,
  Toggle,
  Tooltip,
  usePopper,
} from 'dodoc-design-system';
import styles from './ManualMetadataModal.module.scss';
import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import DebouncedInput from '_common/components/DebouncedInput/DebouncedInput';

type SelectOptionFormProps = {
  option: { fields: string[]; extra: { label: string; value: string }[] };
  optionIndex: number;
  canDeleteOption: boolean;
  removeOption: (optionIndex: number) => void;
  changeField: (optionIndex: number, fieldIndex: number, newValue: string) => void;
  removeField: (optionIndex: number, fieldIndex: number) => void;
  addField: (optionIndex: number) => void;
  addAdditionalInformation: (optionIndex: number) => void;
  removeAdditionalInformation: (optionIndex: number, extraIndex: number) => void;
  editAdditionalInformation: (
    optionIndex: number,
    extraIndex: number,
    extraField: 'label' | 'value',
    extraValue: string,
  ) => void;
};

const SelectOptionForm: FC<SelectOptionFormProps> = ({
  option,
  optionIndex,
  canDeleteOption,
  removeOption,
  changeField,
  removeField,
  addField,
  addAdditionalInformation,
  removeAdditionalInformation,
  editAdditionalInformation,
}) => {
  const intl = useIntl();
  const { popperProps, referenceProps } = usePopper({
    placement: 'bottom-end',
    strategy: 'fixed',
    closeOnReferenceHidden: true,
  });
  const [showExtra, setShowExtra] = useState(false);
  const [deleting, setDeleting] = useState(false);
  return (
    <div className={styles.container}>
      <div className={styles.option}>
        <div className={cn(styles.fields, { [styles.deleting]: deleting })}>
          {deleting ? (
            <>
              <div className={styles.deletingMessage}>
                <FormattedMessage id="DELETING_METADATA_SELECT_OPTION" />
              </div>
              <Button
                size="medium"
                margin="0 0 0 auto"
                onClick={() => setDeleting(false)}
                testId={`option-${optionIndex}-cancel-delete-option-button`}
              >
                <FormattedMessage id="global.cancel" />
              </Button>
              <Button
                size="medium"
                margin="0 1rem 0 0.5rem"
                variant="danger"
                onClick={() => {
                  removeOption(optionIndex);
                  setDeleting(false);
                }}
                testId={`option-${optionIndex}-confirm-delete-option-button`}
              >
                <FormattedMessage id="global.delete" />
              </Button>
            </>
          ) : (
            option.fields.map((field, index) => {
              return (
                <div key={index} className={styles.field}>
                  <DebouncedInput
                    placeholder={intl.formatMessage({ id: 'INSERT_VALUE' })}
                    value={field}
                    suffix={option.fields.length !== 1 ? 'Delete' : undefined}
                    onSuffixClick={() => removeField(optionIndex, index)}
                    width="100%"
                    onChange={(e) => changeField(optionIndex, index, e.target.value)}
                    testId={`option-${optionIndex}-field-${index}`}
                  />
                </div>
              );
            })
          )}
          <div className={styles.toggle}>
            <Tooltip
              testId={`option-${optionIndex}-extra-toggle-tooltip`}
              content={intl.formatMessage({
                id: 'OPTION_DOES_NOT_HAVE_ANY_ADDITIONAL_INFORMATION',
              })}
              disabled={option.extra.length > 0 || deleting}
            >
              <Toggle
                variant="ghost"
                size="large"
                icon={showExtra ? 'AngleUpGrey' : 'AngleDownGrey'}
                disabled={option.extra.length === 0 || deleting}
                onClick={() => setShowExtra(!showExtra)}
                testId={`option-${optionIndex}-extra-toggle`}
              />
            </Tooltip>
          </div>
        </div>
        <Toggle
          size="large"
          icon="MoreOptions"
          variant="link"
          testId={`option-${optionIndex}-more-options-toggle`}
          disabled={deleting}
          {...referenceProps}
        />
        <Dropdown
          {...popperProps}
          width="31rem"
          testId={`option-${optionIndex}-more-options-dropdown`}
        >
          <Tooltip
            testId={`option-${optionIndex}-add-field-dropdown-item-tooltip`}
            placement="left"
            content={<FormattedMessage id="METADATA_MAXIMUM_NUMBER_OF_FIELDS" />}
            disabled={option.fields.length < 3}
          >
            <Dropdown.Item
              size="large"
              onClick={() => addField(optionIndex)}
              disabled={option.fields.length >= 3}
              testId={`option-${optionIndex}-add-new-field-dropdown-item`}
            >
              <FormattedMessage id="ADD_NEW_FIELD" />
            </Dropdown.Item>
          </Tooltip>
          <Dropdown.Item
            size="large"
            onClick={() => {
              addAdditionalInformation(optionIndex);
              setShowExtra(true);
            }}
            testId={`option-${optionIndex}-add-extra-dropdown-item`}
          >
            <FormattedMessage id="ADD_ADDITIONAL_INFORMATION" />
          </Dropdown.Item>
          <Tooltip
            disabled={canDeleteOption}
            content={<FormattedMessage id="METADATA_TYPE_REQUIRES_OPTION" />}
            testId={`option-${optionIndex}-delete-option-dropdown-item-tooltip`}
            placement="left"
          >
            <Dropdown.Item
              size="large"
              onClick={() => {
                setDeleting(true);
                setShowExtra(false);
              }}
              testId={`option-${optionIndex}-delete-option-dropdown-item`}
              disabled={!canDeleteOption}
            >
              <FormattedMessage id="DELETE_OPTION" />
            </Dropdown.Item>
          </Tooltip>
        </Dropdown>
      </div>
      {showExtra && (
        <div className={styles.extras}>
          {option.extra.map(({ label, value }, index) => {
            return (
              <div key={index} className={styles.extra}>
                <SectionHeader
                  margin="0 0 2rem 0"
                  suffixButtonProps={{
                    size: 'small',
                    children: <FormattedMessage id="global.remove" />,
                    onClick: () => removeAdditionalInformation(optionIndex, index),
                    testId: `option-${optionIndex}-extra-${index}-remove-button`,
                  }}
                >
                  <FormattedMessage id="ADDITIONAL_INFORMATION_#" values={{ number: index + 1 }} />
                </SectionHeader>
                <div className={styles.information}>
                  <InputField
                    label={intl.formatMessage({ id: 'global.name' })}
                    width="100%"
                    size="large"
                    testId={`option-${optionIndex}-extra-${index}-name-input-field`}
                  >
                    <DebouncedInput
                      size="large"
                      placeholder={intl.formatMessage({ id: 'INSERT_ADDITIONAL_INFORMATION_NAME' })}
                      value={label}
                      onChange={(e) =>
                        editAdditionalInformation(optionIndex, index, 'label', e.target.value)
                      }
                      testId={`option-${optionIndex}-extra-${index}-name`}
                    />
                  </InputField>
                  <InputField
                    label={intl.formatMessage({ id: 'VALUE' })}
                    width="100%"
                    size="large"
                    testId={`option-${optionIndex}-extra-${index}-value-input-field`}
                  >
                    <DebouncedInput
                      size="large"
                      placeholder={intl.formatMessage({
                        id: 'INSERT_ADDITIONAL_INFORMATION_VALUE',
                      })}
                      value={value}
                      onChange={(e) =>
                        editAdditionalInformation(optionIndex, index, 'value', e.target.value)
                      }
                      testId={`option-${optionIndex}-extra-${index}-value`}
                    />
                  </InputField>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SelectOptionForm;
