import { FormattedMessage } from 'react-intl';
import { Status, UsernameLabel } from '_common/components';

import styles from './MetadataOption.module.scss';

type MetadataOptionProps = {
  metadata: ApiSchemas['MetadataObjectSchema'] & { value?: string | string[] | string[][] };
};

const MetadataOption = ({ metadata }: MetadataOptionProps) => {
  const handleValue = () => {
    if (metadata.value) {
      if (metadata.data_type === 'user' && !Array.isArray(metadata.value)) {
        return <UsernameLabel userId={metadata.value} />;
      }
      if (
        metadata.automatic &&
        metadata.trigger === 'obj_status' &&
        !Array.isArray(metadata.value)
      ) {
        return <Status testId={metadata.name + '-status'} id={metadata.value} />;
      }
      if (Array.isArray(metadata.value) && metadata.value.length > 1) {
        return metadata.value.join(', ');
      }
      return metadata.value;
    }
    return <FormattedMessage id="NOT_SET" />;
  };

  const handleTitle = () => {
    return Array.isArray(metadata.value) ? metadata.value.join(', ') : metadata.value;
  };

  return (
    <div className={styles.item}>
      <div className={styles.itemLabel}>{metadata.name}</div>
      <div className={styles.itemValue} title={handleTitle()}>
        {handleValue()}
      </div>
    </div>
  );
};

export default MetadataOption;
