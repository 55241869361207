import { PresentationRange } from 'Presentation/services/SelectionManager/PresentationRange';
import { DOMUtils } from '_common/utils';

export type ObjectHighlightRect<T> = {
  object: T;
  rects: Rect[];
};

/**
 * Will calculate the rects to highlight zones of the slide based on provided anchor
 * @param params.anchor - The anchor which will provide the range for the highlight rectangles
 * @param params.zoom - The zoom to scale the position and size values accordingly
 * @param params.negativeOffset - The negative offset position, if applicable
 * @returns The highlight rectangles
 */
export const getHighlightRects = ({
  anchor,
  negativeOffset,
}: {
  anchor: Presentation.Common.PresentationAnchor;
  negativeOffset?: Position;
}): Rect[] => {
  const location = anchor[2];
  //If anchor doesn't have location: rects are unobtainable, return empty object
  if (!location) {
    return [];
  }

  const range = PresentationRange.fromJsonLocation(location);
  //If can't obtain range from location: rects are unobtainable, return empty object
  if (!range) {
    return [];
  }

  const rects = DOMUtils.filterAndAdjustDOMRectList(range.getClientRects());
  return rects.map((rect) => {
    return {
      ...rect,
      top: rect.top - (negativeOffset?.top ?? 0),
      width: rect.width,
      height: rect.height,
      left: rect.left - (negativeOffset?.left ?? 0),
    };
  });
};
