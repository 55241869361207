import { Button, Checkbox, Input, InputField, Modal, Select } from 'dodoc-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { closeModal } from 'App/ModalContext/utils';
import { useState } from 'react';
import { AUTOMATION_TRIGGER_TRANSLATIONS } from 'Settings/pages/TenantSettingsPage/Metadata/const';
import { useCreateMetadataMutation, useEditMetadataMutation } from 'App/redux/MetadataApi';
import { notify } from '_common/components/ToastSystem';
import useMetadataNameValidation from '_common/hooks/useMetadataNameValidation';
import { useModalData } from 'App/ModalContext/ModalContext';

const TRANSLATIONS = {
  header: {
    create: 'CREATE_AUTOMATIC_METADATA',
    edit: 'EDIT_AUTOMATIC_METADATA',
  },
  submit: {
    create: 'global.create',
    edit: 'global.save',
  },
};

const options = (
  Object.keys(AUTOMATION_TRIGGER_TRANSLATIONS) as Array<
    keyof typeof AUTOMATION_TRIGGER_TRANSLATIONS
  >
).map((key) => ({
  value: key,
  label: AUTOMATION_TRIGGER_TRANSLATIONS[key],
}));

const AutomaticMetadataModal = () => {
  const intl = useIntl();
  const data = useModalData('AutomaticMetadataModal');
  const [name, setName] = useState(data?.metadata?.name ?? '');
  const validation = useMetadataNameValidation(name);
  const [trigger, setTrigger] = useState<(typeof options)[number] | null>(
    // @ts-expect-error Metadata specs issue - trigger not a proprerty
    options.find(({ value }) => value === data?.metadata?.trigger) ?? null,
  );
  const [required, setRequired] = useState(data?.metadata?.required ?? true);
  const [createMutation, { isLoading }] = useCreateMetadataMutation();
  const [editMetadata] = useEditMetadataMutation();

  const close = () => {
    closeModal('AutomaticMetadataModal');
  };

  const mode: 'create' | 'edit' = data?.metadata ? 'edit' : 'create';

  const submit = async () => {
    if (!trigger) {
      return;
    }

    if (mode === 'create') {
      // @ts-expect-error Metadata specs issue - create type requesting id and time, which shouldn't
      await createMutation({ name, trigger: trigger.value, required, automatic: true });
      notify({
        type: 'success',
        title: 'AUTOMATIC_METADATA_CREATED',
        message: 'METADATA_CREATED_BODY',
        messageValues: { name },
      });
    } else {
      if (!data) {
        return;
      }

      //Name changed
      if (name !== data.metadata.name) {
        await editMetadata({
          id: data.metadata.id,
          path: 'name',
          value: name,
        });
      }
      //Required changed
      if (required !== data.metadata.required) {
        await editMetadata({
          id: data.metadata.id,
          path: 'required',
          value: `${required}`,
        });
      }
      notify({
        type: 'success',
        title: 'AUTOMATIC_METADATA_EDITED',
        message: 'SOMETHING_WAS_SUCCESSFULLY_EDITED',
        messageValues: { label: name },
      });
    }
    close();
  };

  return (
    <Modal open width="75rem" onClose={close} testId="automatic-metadata">
      <Modal.Header>
        <FormattedMessage id={TRANSLATIONS.header[mode]} />
      </Modal.Header>
      <Modal.Body>
        <InputField
          size="large"
          testId="metadata-name-input-field"
          label={intl.formatMessage({ id: 'METADATA_NAME' })}
          feedback={
            validation && (mode === 'create' || name !== data?.metadata?.name)
              ? intl.formatMessage({ id: validation.id }, validation.values)
              : undefined
          }
        >
          <Input
            size="large"
            testId="metadata-name"
            placeholder={intl.formatMessage({ id: 'INSERT_METADATA_UNIQUE_NAME' })}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            error={!!(validation && (mode === 'create' || name !== data?.metadata?.name))}
          />
        </InputField>
        <InputField
          size="large"
          testId="metadata-automation-trigger-input-field"
          label={intl.formatMessage({ id: 'AUTOMATION_TRIGGER' })}
          feedback={intl.formatMessage({ id: 'FIELD_CANNOT_BE_CHANGED_AFTER_CREATED' })}
          disabled={mode === 'edit'}
        >
          <Select
            testId="metadata-automation-trigger-select"
            width="100%"
            menuPosition="fixed"
            options={options}
            value={trigger}
            onChange={setTrigger}
            placeholder={intl.formatMessage({ id: 'CHOOSE_AN_AUTOMATION_TRIGGER' })}
            disabled={mode === 'edit'}
          />
        </InputField>
        <Checkbox
          checked={required ? 'checked' : 'unchecked'}
          testId="metadata-required-checkbox"
          margin="1rem 0 0 0"
          onChange={() => setRequired(!required)}
          size="small"
        >
          <FormattedMessage id="METADATA_REQUIRED" />
        </Checkbox>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" testId="metadata-cancel-button" onClick={close}>
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          testId="metadata-create-button"
          variant="primary"
          disabled={
            name === '' ||
            !trigger ||
            (!!validation && (mode === 'create' || name !== data?.metadata?.name))
          }
          loading={isLoading}
          onClick={submit}
        >
          <FormattedMessage id={TRANSLATIONS.submit[mode]} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AutomaticMetadataModal;
