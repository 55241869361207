import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { BaseManipulator } from '../Common/Base';
import { InsertElementOperation, RemoveContentOperation } from '../../Operations';
import { ELEMENTS } from 'Editor/services/consts';
import { InsertBlockOperation } from '../../Operations/StructureOperations';

export class SplitManipulator extends BaseManipulator implements Editor.Edition.ISplitManipulator {
  splitBlockContent(
    ctx: Editor.Edition.ActionContext,
    pathToSplit: Editor.Selection.Path,
    insertOptions: Editor.Edition.SplitBlockOptions = {},
  ): Editor.Selection.Path | undefined {
    if (!this.editionContext.DataManager) {
      return;
    }

    const structureModel = this.editionContext.DataManager?.structure.structureModel;

    // IMPORTANT: avoid outdated data
    const baseModel = this.editionContext.DataManager.nodes.getNodeModelById(ctx.range.start.b);
    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData || !baseData.id || !structureModel) {
      return;
    }

    let resultPath: Editor.Selection.Path | undefined = ctx.range.start.p;

    let refId: string = ctx.suggestionRef;

    const closestBlock = NodeUtils.closestOfTypeByPath(baseData, ctx.range.start.p, [
      ...NodeUtils.BLOCK_TYPES,
    ]);

    if (!closestBlock || !NodeUtils.isBlockSplitableTypeData(closestBlock.data)) {
      return resultPath;
    }

    const result = NodeUtils.getParentChildInfoByPath(baseData, closestBlock.path);

    let blockData: Editor.Data.Node.Data = closestBlock.data;
    let blockDataPath: Editor.Selection.Path = closestBlock.path;

    let startSplitPath: Editor.Selection.Path = pathToSplit.slice(
      blockDataPath.length,
      pathToSplit.length,
    );
    let endSplitPath: Editor.Selection.Path = ['childNodes', blockData.childNodes?.length || 0];

    let previousData: Editor.Data.Node.Data | null | undefined;
    let nextData: Editor.Data.Node.Data | null | undefined;

    // check for multiblock containers
    if (closestBlock.path.length === 0) {
      const previousBlock = this.editionContext.DataManager.nodes.getPreviousModelById(baseData.id);
      previousData = previousBlock?.selectedData();
      const nextBlock = this.editionContext.DataManager.nodes.getNextModelById(baseData.id);
      nextData = nextBlock?.selectedData();
    } else if (NodeUtils.isMultiBlockContainerData(result?.parentData)) {
      let blockIndex = Number(blockDataPath[blockDataPath.length - 1]);
      if (!isNaN(blockIndex)) {
        previousData = result?.parentData.childNodes?.[blockIndex - 1];
        nextData = result?.parentData.childNodes?.[blockIndex + 1];
      }
    }

    let clonedChildNodes: Editor.Data.Node.Data[] = [];

    // check if path is at start of original data
    if (
      NodeUtils.isPathAtContentStart(blockData, startSplitPath) &&
      blockData.tasks?.[0] &&
      previousData?.tasks?.[0] !== blockData.tasks?.[0]
    ) {
      // check task prop and remove it
      baseModel.delete([...blockDataPath, 'tasks'], { source: 'LOCAL_RENDER' });
    }

    // if (NodeUtils.isPathAtContentEnd(blockData, startSplitPath)) {
    //   // check if has any styles and add them to the cloned nodes
    //   let formatProperties: Editor.Data.Node.FormatProperties = {};

    //   let workingPath = [...startSplitPath];
    //   let closestFormat = NodeUtils.closestOfTypeByPath(blockData, workingPath, 'format');

    //   while (closestFormat) {
    //     // keeps always first properties found
    //     formatProperties = {
    //       ...closestFormat.data.properties,
    //       ...formatProperties,
    //     };

    //     workingPath = workingPath.slice(0, closestFormat.path.length - 2);
    //     if (workingPath.length > 0) {
    //       closestFormat = NodeUtils.closestOfTypeByPath(blockData, workingPath, 'format');
    //     } else {
    //       closestFormat = null;
    //     }
    //   }

    //   if (Object.keys(formatProperties).length) {
    //     let dataBuilder = new NodeDataBuilder('format');
    //     dataBuilder.addProperties(formatProperties);
    //     let data = dataBuilder.build();
    //     if (data) {
    //       clonedChildNodes.push(data);
    //     }
    //   }
    // } else {
    clonedChildNodes = NodeUtils.cloneData(blockData, startSplitPath, endSplitPath, ['format']);

    if (clonedChildNodes.length) {
      const removeOp = new RemoveContentOperation(
        baseModel,
        [...blockDataPath, ...startSplitPath],
        [...blockDataPath, ...endSplitPath],
      ).apply();
      resultPath = removeOp.getAdjustedPath();

      if (!resultPath) {
        resultPath = pathToSplit;
      }

      // update range position
      if (resultPath && blockData.id) {
        ctx.range.updateRangePositions({
          b: baseModel.id,
          p: resultPath,
        });
      }
    }
    // }

    // validate if element is empty and there is a node after
    if (insertOptions.checkEmpty && clonedChildNodes.length === 0 && nextData != null) {
      return resultPath;
    }

    // clean cloned block data
    const splitBlockData: Editor.Data.Node.Data = JSON.parse(JSON.stringify(blockData));
    delete splitBlockData._id;
    delete splitBlockData.id;
    delete splitBlockData.approvedBy;
    delete splitBlockData.lock;
    delete splitBlockData.childNodes;
    delete splitBlockData.refs;
    delete splitBlockData.tasks;

    let isNonRepeatableStyle: boolean = false;

    // check if split nodes are empty
    if (clonedChildNodes.length === 0 || NodeUtils.isLastChildElementData(clonedChildNodes[0])) {
      const nonRepeatableStyles: readonly string[] =
        ELEMENTS.ParagraphElement.NON_REPEATABLE_STYLES;
      if (
        NodeUtils.isParagraphData(splitBlockData) &&
        nonRepeatableStyles.includes(splitBlockData.properties.s)
      ) {
        splitBlockData.properties.s = ELEMENTS.ParagraphElement.ELEMENT_TYPE;
        isNonRepeatableStyle = true;
      }

      // check task prop and remove it
      if (splitBlockData?.tasks?.[0] && splitBlockData?.tasks?.[0] !== nextData?.tasks?.[0]) {
        delete splitBlockData.tasks;
      }
    }

    // TODO remove empty childNodes?

    const dataBuilder = new NodeDataBuilder(splitBlockData.type);
    const dataToInsert = dataBuilder
      .setData(splitBlockData)
      .setChildNodes(clonedChildNodes)
      .build();

    if (dataToInsert && dataToInsert.id) {
      if (blockDataPath.length !== 0 && NodeUtils.isMultiBlockContainerData(result?.parentData)) {
        let pathToInsert = [...blockDataPath];
        let blockIndex = Number(pathToInsert[pathToInsert.length - 1]);
        if (isNaN(blockIndex)) {
          return;
        }

        pathToInsert[pathToInsert.length - 1] = blockIndex + 1;
        const op = new InsertElementOperation(baseModel, pathToInsert, dataToInsert);
        op.apply();

        resultPath = op.getAdjustedPath();
        if (insertOptions.setSelectionAfter && resultPath) {
          resultPath = [...pathToInsert, 'childNodes', 0];
          ctx.range.updateRangePositions({
            b: baseModel.id,
            p: resultPath,
          });
        }

        this.handleInsertSplitMarker(
          baseModel,
          [...blockDataPath, 'childNodes', blockData.childNodes?.length || 0],
          baseModel,
          [...pathToInsert, 'childNodes', 0],
          refId,
        );

        if (resultPath) {
          ctx.addSuggestionLocation(baseModel.id, resultPath);
        }
      } else {
        const op = new InsertBlockOperation(
          this.editionContext.DataManager,
          structureModel,
          dataToInsert,
          baseModel.id,
          'AFTER',
          { pathAfterInsert: 'START' },
        );
        op.apply();

        resultPath = op.getAdjustedPath();
        if (insertOptions.setSelectionAfter && resultPath) {
          ctx.range.updateRangePositions({
            b: dataToInsert.id,
            p: resultPath,
          });
        }

        const nextModel = this.editionContext.DataManager.nodes.getNodeModelById(dataToInsert.id);

        if (resultPath && nextModel) {
          this.handleInsertSplitMarker(
            baseModel,
            [...blockDataPath, 'childNodes', blockData.childNodes?.length || 0],
            nextModel,
            resultPath,
            refId,
          );

          if (resultPath) {
            ctx.addSuggestionLocation(nextModel.id, resultPath);
          }
        }
      }

      if (blockData.id) {
        // check lists
        const isList = this.editionContext.DataManager.numbering.isListElement(blockData.id);
        const isInOutlineList = this.editionContext.DataManager.numbering.isBlockInOutlineList(
          blockData.id,
        );

        if (isList && !isInOutlineList && !isNonRepeatableStyle) {
          const listId = this.editionContext.DataManager.numbering.getListIdFromBlock(blockData.id);
          const listLevel = this.editionContext.DataManager.numbering.getListLevelFromBlock(
            blockData.id,
          );

          if (listId && listLevel != null) {
            this.editionContext.DataManager.numbering.addBlocksToList(
              [dataToInsert.id],
              listId,
              listLevel,
              blockData.id,
            );
          }
        }
      }

      return resultPath;
    }

    return;
  }
}
