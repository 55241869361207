import { v4 as uuid } from 'uuid';
import { ELEMENTS } from 'Editor/services/consts';
import DOMNormalizer from 'Editor/services/DOMUtilities/DOMNormalizer/DOMNormalizer';
import { SelectionUtils } from '_common/DoDOCSelection';
import { EditorDOMElements, EditorDOMUtils } from '../../DOM';
import { EditorRange } from '../EditorRange';
import { JsonRange } from '../JsonRange';
import { SelectionFixer } from '../SelectionFixer';
import { Logger } from '_common/services';

export class EditorSelectionUtils extends SelectionUtils {
  static SELECTION_CHANGE_TYPE = {
    move: 'move',
    expand: 'expand',
  } as const;

  static SELECTION_DIRECTION = {
    backward: 'backward',
    forward: 'forward',
  } as const;

  static SELECTION_GRANULARITY = {
    character: 'character',
    word: 'word',
    line: 'line',
  } as const;

  static getJsonRange(): Editor.Selection.JsonRange | null {
    const range = EditorSelectionUtils.getRange();
    if (range) {
      return JsonRange.buildFromDOMRange(range);
    }
    return null;
  }

  static getRange(): Editor.Selection.EditorRange | undefined {
    const selection = SelectionUtils.getSelection();
    if (selection && selection.type !== 'None' && selection.rangeCount > 0) {
      return EditorRange.fromNativeRange(selection.getRangeAt(0));
    }
    return undefined;
  }

  static createNewRange(): Editor.Selection.EditorRange {
    return new EditorRange();
  }

  static isSelectionInPage() {
    const page = EditorDOMUtils.getContentContainer();
    if (!page) {
      return false;
    }

    try {
      if (page.contains(document.activeElement)) {
        const range = EditorSelectionUtils.getRange();

        if (page.contains(range?.commonAncestorContainer || null)) {
          return true;
        }
      }

      return false;
    } catch (error) {
      Logger.captureException(error);
      return false;
    }
  }

  static collides(nodeId: string) {
    const range = EditorSelectionUtils.getRange();
    if (!range) {
      return false;
    }
    const node = EditorDOMUtils.getNode(
      nodeId,
      EditorDOMUtils.getContentContainer(range.startContainer),
    );
    if (!node) {
      return false;
    }

    return (
      range.containsNode(node) ||
      range.instersectsNode(node) ||
      node.contains(range.commonAncestorContainer)
    );
  }

  static isCurrentSelectionEditable(range = EditorSelectionUtils.getRange()) {
    if (!range) {
      return false;
    }
    if (range.collapsed) {
      const pageNode = EditorDOMUtils.getContentContainer(range.startContainer);
      const parentLevel0 = EditorDOMUtils.findFirstLevelChildNode(pageNode, range.startContainer);

      //! Commented because it broke enters on references
      //! BUG-1357
      //
      // const closestNonEditableTextElement = DOMUtils.closest(
      //   node,
      //   DOMUtils.INLINE_NON_EDITABLE_ELEMENTS,
      // );
      if (
        //! BUG-1357
        /* !closestNonEditableTextElement && */
        parentLevel0 &&
        EditorDOMUtils.isClosestBlockNodeEditable(parentLevel0)
      ) {
        return true;
      }
    } else {
      const nodes = EditorSelectionUtils.getSelectedLevel0Nodes(range);

      for (let i = 0; i < nodes.length; i++) {
        if (!EditorDOMUtils.isClosestBlockNodeEditable(nodes[i])) {
          return false;
        }
      }

      return true;
    }

    return false;
  }

  static isCurrentSelectionDeletable(range = EditorSelectionUtils.getRange()) {
    if (!range) {
      return false;
    }

    if (range.collapsed) {
      const pageNode = EditorDOMUtils.getContentContainer(range.startContainer);
      const parentLevel0 = EditorDOMUtils.findFirstLevelChildNode(pageNode, range.startContainer);

      if (parentLevel0 && EditorDOMUtils.isBlockNodeDeletable(parentLevel0)) {
        return true;
      }
    } else {
      const nodes = EditorSelectionUtils.getSelectedLevel0Nodes(range);

      for (let i = 0; i < nodes.length; i++) {
        if (!EditorDOMUtils.isBlockNodeDeletable(nodes[i])) {
          return false;
        }
      }

      return true;
    }

    return false;
  }

  static isCurrentSelectionApproved(range = EditorSelectionUtils.getRange()) {
    if (!range) {
      return false;
    }

    if (range.collapsed) {
      const pageNode = EditorDOMUtils.getContentContainer(range.startContainer);
      const parentLevel0 = EditorDOMUtils.findFirstLevelChildNode(pageNode, range.startContainer);

      if (EditorDOMElements.isApprovedElement(parentLevel0)) {
        return true;
      }
    } else {
      const nodes = EditorSelectionUtils.getSelectedLevel0Nodes(range);

      for (let i = 0; i < nodes.length; i += 1) {
        if (EditorDOMElements.isApprovedElement(nodes[i])) {
          return true;
        }
      }
    }

    return false;
  }

  static setCaret(
    node: Node,
    position: DoDOCSelection.CaretPosition = 'START',
    offset: number = 0,
  ) {
    const range = EditorSelectionUtils.getRange();
    if (range) {
      range.setCaret(node, position, offset);
      EditorSelectionUtils.applyRangeToSelection(range);
    }
  }

  static setSelection(startNode: Node, startOffset: number, endNode: Node, endOffset: number) {
    const range = EditorSelectionUtils.createNewRange();

    range.setStart(startNode, startOffset);
    range.setEnd(endNode, endOffset);
    EditorSelectionUtils.applyRangeToSelection(range);
  }

  static isSelectionAtStart(
    node: Node,
    container: 'start' | 'end' = 'start',
    range = EditorSelectionUtils.getRange(),
  ) {
    if (!range) {
      return false;
    }

    return EditorRange.fromNativeRange(range).isAtNodeStart(node, container);
  }

  static isSelectionAtEnd(
    node: Node,
    container: 'start' | 'end' = 'end',
    range = EditorSelectionUtils.getRange(),
  ) {
    if (!range) {
      return false;
    }

    return EditorRange.fromNativeRange(range).isAtNodeEnd(node, container);
  }

  static fixSelection(range = EditorSelectionUtils.getRange(), forceApply = false) {
    if (!range) {
      return;
    }

    const updated = SelectionFixer.fixSelection(range, {});
    if (updated || forceApply) {
      EditorSelectionUtils.applyRangeToSelection(range);
    }

    return updated;
  }

  static fixNonCollapsedTextSelection(
    options: Partial<Editor.Selection.FixerOptions> = {},
    range?: Range,
    applyRange: boolean = true,
  ) {
    let thisRange = range ? EditorRange.fromNativeRange(range) : EditorSelectionUtils.getRange();
    if (!thisRange) {
      return;
    }

    SelectionFixer.normalizeTextSelection(thisRange, options);

    if (applyRange) {
      EditorSelectionUtils.applyRangeToSelection(thisRange);
    }

    return thisRange;
  }

  static fixCollapsedTextSelection(
    options: Partial<Editor.Selection.FixerOptions> = {},
    range?: Range,
  ) {
    let thisRange = range ? EditorRange.fromNativeRange(range) : EditorSelectionUtils.getRange();
    if (!thisRange) {
      return;
    }

    SelectionFixer.normalizeTextSelection(thisRange, options);

    EditorSelectionUtils.applyRangeToSelection(thisRange);
  }

  static selectNode(node: Node) {
    const range = EditorSelectionUtils.createNewRange();
    range.selectNode(node);
    EditorSelectionUtils.applyRangeToSelection(range);
  }

  static selectLevel0Node(node: Node | null) {
    if (!node) {
      return;
    }

    const pageNode = EditorDOMUtils.getContentContainer(node);
    if (node.parentNode !== pageNode) {
      const closestContainer = EditorDOMUtils.closestMultiBlockContainerElement(node);

      if (closestContainer) {
        node = EditorDOMUtils.findFirstLevelChildNode(closestContainer, node);
      } else {
        node = EditorDOMUtils.findFirstLevelChildNode(pageNode, node);
      }
    }

    if (node) {
      EditorSelectionUtils.selectNode(node);
    }
  }

  static isSelectionBackwards() {
    const selection = window.getSelection();
    //@ts-expect-error
    const position = selection.anchorNode.compareDocumentPosition(selection.focusNode);
    return (
      //@ts-expect-error
      (!position && selection.anchorOffset > selection.focusOffset) ||
      position === Node.DOCUMENT_POSITION_PRECEDING
    );
  }

  static getSelectedText() {
    return EditorSelectionUtils.getRange()?.toString();
  }

  private static getTextNodesIn(thisNode: Node) {
    const textNodes: Text[] = [];

    if (thisNode instanceof Text) {
      textNodes.push(thisNode);
    } else {
      // let thisDescendant: Node | undefined;

      const allDescendants = Array.from(thisNode.childNodes);
      while (allDescendants.length) {
        const thisDescendant = allDescendants.shift();
        if (thisDescendant instanceof Element) {
          thisDescendant.childNodes.forEach((node) => {
            allDescendants.push(node);
          });
        } else if (thisDescendant instanceof Text) {
          textNodes.push(thisDescendant);
        }
      }
    }
    return textNodes;
  }

  private static getLinkElements(textNodes: Text[]) {
    const links: Editor.Elements.HyperlinkElement[] = [];

    textNodes.forEach((node) => {
      const pageNode = EditorDOMUtils.getContentContainer(node);

      let anchor: Node | null = node;
      while (anchor && !EditorDOMElements.isNodeHyperlinkElement(anchor)) {
        if (anchor === pageNode) {
          anchor = null;
          break;
        }
        anchor = anchor?.parentNode || null;
      }

      if (anchor && links.indexOf(anchor) < 0) {
        links.push(anchor);
      }
    });

    return links;
  }

  private static getLinkElementsURLAndIds(HyperlinkElements: Editor.Elements.HyperlinkElement[]) {
    let url: string | undefined | null;
    const linksIds: string[] = [];

    if (HyperlinkElements.length) {
      for (let i = 0; i < HyperlinkElements.length; i++) {
        if (HyperlinkElements[i].id) {
          linksIds.push(HyperlinkElements[i].id);
        } else {
          const id = uuid();

          HyperlinkElements[i].id = id;
          linksIds.push(id);
        }

        if (url === undefined) {
          url = HyperlinkElements[i].href;
        } else if (url !== HyperlinkElements[i].href) {
          url = null;
        }
      }
    }

    return { url: url, linkElementsIds: linksIds };
  }

  private static getTheseBlockNodes(textNodes: Text[]) {
    const theseBlockNodes: Node[] = [];

    textNodes.forEach((textNode) => {
      let thisBlock = textNode.parentNode;

      while (EditorDOMElements.isNodeInlineElement(thisBlock)) {
        thisBlock = thisBlock?.parentNode || null;
      }

      if (thisBlock && theseBlockNodes.indexOf(thisBlock) < 0) {
        theseBlockNodes.push(thisBlock);
      }
    });

    return theseBlockNodes;
  }

  static getLinkData() {
    const selectedNodes = EditorSelectionUtils.getSelectedNodes();

    let allTextNodes: Text[] = [];
    selectedNodes.forEach((selectedNode) => {
      allTextNodes = allTextNodes.concat(EditorSelectionUtils.getTextNodesIn(selectedNode));
    });

    const links = EditorSelectionUtils.getLinkElements(allTextNodes);

    const urlAndIds = EditorSelectionUtils.getLinkElementsURLAndIds(links);

    const theseBlockNodes = EditorSelectionUtils.getTheseBlockNodes(allTextNodes);

    const textToDisplay = links.length > 0 && links[0].innerText ? links[0].innerText : '';

    return {
      url: urlAndIds.url,
      textToDisplay,
      showTextToDisplay: theseBlockNodes.length > 1 ? false : true,
      links: urlAndIds.linkElementsIds,
    };
  }

  static getSelectedNodes(range = EditorSelectionUtils.getRange()) {
    if (!range) {
      return [];
    }

    const pageNode = EditorDOMUtils.getContentContainer(range.startContainer);
    if (!(pageNode instanceof Element)) {
      return [];
    }

    const nodes = Array.from(
      pageNode?.querySelectorAll(`${ELEMENTS.TableCellElement.TAG}[data-selected="true"]`),
    );

    let selectedNodes: Node[] = [];
    if (nodes.length) {
      nodes.forEach((node) => {
        selectedNodes.push(node);
      });
    } else if (range.collapsed) {
      selectedNodes.push(range.startContainer);
    } else {
      const thisRange = range.cloneRange();

      const startTD = EditorDOMUtils.closest(
        thisRange.startContainer,
        ELEMENTS.TableCellElement.TAG,
      );
      const endTD = EditorDOMUtils.closest(thisRange.endContainer, ELEMENTS.TableCellElement.TAG);

      if (
        startTD &&
        endTD &&
        startTD !== endTD &&
        EditorSelectionUtils.isSelectionAtStart(endTD, 'end')
      ) {
        if (endTD.previousSibling) {
          // previous TD
          const targetTD = endTD.previousSibling;
          thisRange.setEnd(targetTD, targetTD.childNodes.length);
        } else if (endTD.parentNode?.previousSibling) {
          // previous TR
          const targetTD = endTD.parentNode.previousSibling;
          thisRange.setEnd(targetTD, targetTD.childNodes.length);
        }
      }

      selectedNodes = thisRange.getNodes();
    }

    return selectedNodes;
  }

  static getSelectedBlockNodes() {
    const nodes = EditorSelectionUtils.getSelectedNodes();
    const blockNodes: Node[] = [];

    nodes.forEach((node) => {
      const pageNode = EditorDOMUtils.getContentContainer(node);
      let block: Node | null = node;
      while (EditorDOMElements.isInlineNode(block) && block?.parentNode !== pageNode) {
        block = block?.parentNode || null;
      }
      if (block && blockNodes.indexOf(block) < 0) {
        blockNodes.push(block);
      }
    });

    return blockNodes;
  }

  static getSelectedLevel0NodesId(range = EditorSelectionUtils.getRange()) {
    const nodes = EditorSelectionUtils.getSelectedLevel0Nodes(range);

    const nodeIds: string[] = [];
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (node instanceof HTMLElement) {
        nodeIds.push(node.id);
      }
    }

    return nodeIds;
  }

  private static getSelectedLevel0Nodes(range = EditorSelectionUtils.getRange()) {
    if (!range) {
      return [];
    }

    let selectedNodes: Node[] = [];
    if (range.collapsed) {
      if (range.startContainer) {
        selectedNodes = EditorDOMUtils.findFirstLevelChildNodes(
          EditorDOMUtils.getContentContainer(range.startContainer),
          [range.startContainer],
        );
      }
    } else {
      const nodes = range.getNodes();
      selectedNodes = EditorDOMUtils.findFirstLevelChildNodes(
        EditorDOMUtils.getContentContainer(nodes[0]),
        nodes,
      );
    }

    return selectedNodes;
  }

  static collapseToStart() {
    const range = EditorSelectionUtils.getRange();
    if (!range) {
      return;
    }

    range.collapseToStart();
    EditorSelectionUtils.applyRangeToSelection(range);
  }

  static collapseToEnd() {
    const range = EditorSelectionUtils.getRange();
    if (!range) {
      return;
    }

    range.collapseToEnd();
    EditorSelectionUtils.applyRangeToSelection(range);
  }

  static selectAll() {
    const page = EditorDOMUtils.getContentContainer();
    if (!page || !page.firstChild || !page.lastChild) {
      return;
    }

    EditorSelectionUtils.setSelection(
      page.firstChild,
      0,
      page.lastChild,
      page.lastChild.childNodes.length,
    );
  }

  static selectNodeContents(node: Node) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection?.removeAllRanges();
    selection?.addRange(range);
  }

  static getRangeBasedOnSavedMarkers(
    savedRange: DoDOCSelection.DoDOCRange.BoundaryMarkers,
    setCaretOnText: boolean = false,
  ) {
    const range = EditorSelectionUtils.createNewRange();

    if (savedRange.collapsed && savedRange.markerId) {
      const marker = document.getElementById(savedRange.markerId);
      if (marker) {
        range.setStartAfter(marker);
        range.setEndAfter(marker);
      }
    } else if (savedRange.startMarkerId && savedRange.endMarkerId) {
      const startMarker = document.getElementById(savedRange.startMarkerId);
      const endMarker = document.getElementById(savedRange.endMarkerId);

      if (startMarker) {
        if (startMarker.nextSibling && setCaretOnText) {
          range.setRangeStart(startMarker.nextSibling, 'INSIDE_START');
        } else {
          range.setStartAfter(startMarker);
        }
      }

      if (endMarker) {
        if (endMarker.previousSibling && setCaretOnText) {
          range.setRangeEnd(endMarker.previousSibling, 'INSIDE_END');
        } else {
          range.setEndBefore(endMarker);
        }
      }
    }
    return range;
  }

  static splitInlineTextElements(
    baseNode: Node,
    container: Node,
    offset: number,
    range: Editor.Selection.EditorRange | undefined = EditorSelectionUtils.getRange(),
  ) {
    if (
      !range ||
      !EditorDOMUtils.isClosestTextElementEditable(baseNode) ||
      !baseNode.contains(container) ||
      EditorDOMUtils.closest(container, EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS) ||
      container === baseNode
    ) {
      return {};
    }

    let closestText: Node | null;
    let anchorNode = container;
    let anchorOffset = offset;

    while (
      (closestText = EditorDOMUtils.closest(anchorNode, EditorDOMElements.INLINE_TEXT_ELEMENTS)) &&
      EditorDOMUtils.parentContainsNode(baseNode, closestText)
    ) {
      if (range.isAtNodeStart(closestText, 'end')) {
        if (closestText.parentNode) {
          anchorNode = closestText.parentNode;
          anchorOffset = Array.from(closestText.parentNode.childNodes as NodeListOf<Node>).indexOf(
            closestText,
          );
        }
      } else if (range.isAtNodeEnd(closestText, 'start')) {
        if (closestText.parentNode) {
          anchorNode = closestText.parentNode;
          anchorOffset =
            Array.from(closestText.parentNode.childNodes as NodeListOf<Node>).indexOf(closestText) +
            1;
        }
      } else {
        const copyingRange = EditorSelectionUtils.createNewRange();
        copyingRange.setStart(anchorNode, anchorOffset);
        copyingRange.setEndAfter(closestText);

        const contents = copyingRange.extractContents();
        const nodes = contents.childNodes;

        if (closestText.parentNode instanceof Element) {
          let i;
          for (i = 0; i < nodes.length; i += 1) {
            const node = nodes[i];
            if (node instanceof Element) {
              node.removeAttribute('id');
              node.removeAttribute('parent_id');
              DOMNormalizer.normalizeTree(node, closestText.parentNode.id);
              EditorDOMUtils.insertNodeAfter(closestText.parentNode, node, closestText);
            }
          }

          anchorNode = closestText.parentNode;
          anchorOffset =
            Array.from(closestText.parentNode.childNodes as NodeListOf<Node>).indexOf(closestText) +
            1;
        }
      }
    }

    return {
      anchorNode,
      anchorOffset,
    };
  }

  static getElementsFromRange(
    range: Editor.Selection.EditorRange | undefined = EditorSelectionUtils.getRange(),
    elementTAGs = EditorDOMElements.BLOCK_TEXT_ELEMENTS,
    aditionalValidator?: (node: Node, isAncestor: boolean) => boolean,
  ) {
    let nodes: Node[] = [];
    if (range) {
      nodes = range.getNodes([Node.ELEMENT_NODE], (node) => {
        if (aditionalValidator) {
          return elementTAGs.includes(node.nodeName) && aditionalValidator(node, false);
        }
        return elementTAGs.includes(node.nodeName);
      });

      const closestElement = EditorDOMUtils.closest(range.commonAncestorContainer, elementTAGs);

      if (closestElement && !nodes.includes(closestElement)) {
        if (aditionalValidator) {
          if (aditionalValidator(closestElement, true)) {
            nodes.push(closestElement);
          }
        } else {
          nodes.push(closestElement);
        }
      }
    }
    return nodes;
  }

  static getSelectedTableElements(
    range: Editor.Selection.EditorRange | undefined = EditorSelectionUtils.getRange(),
  ) {
    if (!range) {
      return null;
    }

    // Get TD, TR and TABLE from the anchor node of the current selection
    const td = EditorDOMUtils.closest(range.startContainer, ELEMENTS.TableCellElement.TAG);
    if (!EditorDOMElements.isTableCellElement(td)) {
      return null;
    }

    const row = td.parentNode as HTMLTableRowElement;
    const table = EditorDOMUtils.closest(row, ELEMENTS.TableElement.TAG);
    if (!EditorDOMElements.isTableElement(table)) {
      return null;
    }

    const elements: {
      selectedTable: Editor.Elements.TableElement | null;
      selectedRows: HTMLTableRowElement[];
      selectedRowsIds: string[];
      rowsIndex: number[];
      columnsIndex: number[];
      selectedCells: Editor.Elements.TableCellElement[];
      selectedCellsIds: string[];
    } = {
      selectedTable: table,
      selectedRows: [],
      selectedRowsIds: [],
      rowsIndex: [],
      columnsIndex: [],
      selectedCells: [],
      selectedCellsIds: [],
    };

    // Query the table for the selected TDs
    const cells = table.getSelectedCells();
    // If none, it means only one cell is selected and use the original TD
    if (cells.length === 0) {
      elements.selectedRows = [row];
      elements.selectedRowsIds = [row.id];
      elements.rowsIndex = [row.sectionRowIndex];
      elements.columnsIndex = [td.cellIndex];
      elements.selectedCells = [td];
      elements.selectedCellsIds = [td.id];
      return elements;
    }

    // Use the TDs selected from the data-selected attribute
    cells.forEach((cell) => {
      elements.selectedCells.push(cell);
      elements.selectedCellsIds.push(cell.id);
      if (
        cell.parentNode instanceof HTMLTableRowElement &&
        !elements.selectedRows.includes(cell.parentNode)
      ) {
        elements.selectedRows.push(cell.parentNode);
        elements.selectedRowsIds.push(cell.parentNode.id);
        elements.rowsIndex.push(cell.parentNode.sectionRowIndex);
      }
      if (!elements.columnsIndex.includes(cell.cellIndex)) {
        elements.columnsIndex.push(cell.cellIndex);
      }
    });
    return elements;
  }

  static getSelectableElementFromNode(block: Node | null, selectedNode: Node | null): Node | null {
    if (!block || !selectedNode) {
      return null;
    }

    let selectableElement: Node | null = null;

    const closestBlock = EditorDOMUtils.closest(selectedNode, EditorDOMElements.BLOCK_ELEMENTS);

    if (EditorDOMElements.isSupportedBlockElement(closestBlock)) {
      selectableElement = closestBlock.selectableContent;
    } else if (EditorDOMElements.isTableElement(block)) {
      if (EditorDOMElements.isTableCellElement(selectedNode)) {
        selectableElement = selectedNode;
      } else {
        const closest = EditorDOMUtils.closest(selectedNode, [ELEMENTS.TableCellElement.TAG]);
        if (closest) {
          if (EditorDOMElements.isTableCellElement(closest)) {
            selectableElement = this.getSelectableElementFromNode(
              EditorDOMUtils.findFirstLevelChildNode(closest, selectedNode),
              selectedNode,
            );
          }
        } else {
          selectableElement = block;
        }
      }
    } else if (EditorDOMElements.isFigureElement(block)) {
      selectableElement = EditorDOMUtils.closest(selectedNode, ['IMAGE-ELEMENT', 'FIGCAPTION']);
    } else if (EditorDOMElements.isTrackedElement(block)) {
      selectableElement = this.getSelectableElementFromNode(block.firstChild, selectedNode);
    } else if (EditorDOMElements.isNodeContainerElement(block)) {
      selectableElement = this.getSelectableElementFromNode(
        EditorDOMUtils.findFirstLevelChildNode(block, selectedNode),
        selectedNode,
      );
    } else if (EditorDOMElements.isSupportedBlockElement(block)) {
      selectableElement = block.selectableContent;
    }

    return selectableElement;
  }

  static scrollIntoSelection(modifiersData: Editor.Data.Selection.Modifiers) {
    const editorRoot = document.getElementById('EditorRoot');

    if (!editorRoot) {
      return;
    }

    const editorRootBoundingRect = editorRoot.getBoundingClientRect();

    const range = EditorSelectionUtils.getRange();

    if (!range) {
      return;
    }

    let selectedNode;
    if (modifiersData.expandingDirection === 'backward') {
      // expanding direction is backwards
      selectedNode = range.startContainer;
    } else {
      // expanding direction is forward or selection is collapsed
      selectedNode = range.endContainer;
    }

    const level0 = EditorDOMUtils.findFirstLevelChildNode(
      EditorDOMUtils.getContentContainer(selectedNode),
      selectedNode,
    );
    // check for node text element
    const selectableElement = this.getSelectableElementFromNode(level0, selectedNode);

    if (selectableElement instanceof Element) {
      const textLineHeight = parseInt(getComputedStyle(selectableElement).lineHeight, 10);
      const textBoundingRect = selectableElement.getBoundingClientRect();

      const rangeBoundingRect = range.getBoundingClientRect();
      const rangeClientRects = range.getClientRects();

      // verify editor scroll
      let bottom;
      let top;
      let pX;
      if (modifiersData.direction === 'forward') {
        if (modifiersData.expandingDirection === 'backward') {
          top =
            selectableElement.textContent && rangeClientRects.length > 0
              ? rangeClientRects[0].top
              : textBoundingRect.top + parseInt(getComputedStyle(selectableElement).paddingTop, 10);
          bottom =
            selectableElement.textContent && rangeClientRects.length > 0
              ? rangeClientRects[0].bottom
              : textBoundingRect.bottom -
                parseInt(getComputedStyle(selectableElement).paddingBottom, 10);
          pX =
            selectableElement.textContent && rangeClientRects.length > 0
              ? rangeClientRects[0].left
              : textBoundingRect.left +
                parseInt(getComputedStyle(selectableElement).paddingLeft, 10);
        } else {
          bottom =
            selectableElement.textContent && rangeBoundingRect.bottom !== 0
              ? rangeBoundingRect.bottom
              : textBoundingRect.bottom -
                parseInt(getComputedStyle(selectableElement).paddingBottom, 10);
          pX =
            selectableElement.textContent && rangeClientRects.length > 0
              ? rangeClientRects[rangeClientRects.length - 1].right
              : textBoundingRect.right -
                parseInt(getComputedStyle(selectableElement).paddingRight, 10);
        }
      } else if (modifiersData.direction === 'backward') {
        if (modifiersData.expandingDirection === 'forward') {
          top =
            selectableElement.textContent && rangeClientRects.length > 0
              ? rangeClientRects[rangeClientRects.length - 1].top
              : textBoundingRect.top + parseInt(getComputedStyle(selectableElement).paddingTop, 10);
          bottom =
            selectableElement.textContent && rangeClientRects.length > 0
              ? rangeClientRects[rangeClientRects.length - 1].bottom
              : textBoundingRect.bottom -
                parseInt(getComputedStyle(selectableElement).paddingBottom, 10);
          pX =
            selectableElement.textContent && rangeClientRects.length > 0
              ? rangeClientRects[rangeClientRects.length - 1].right
              : textBoundingRect.right -
                parseInt(getComputedStyle(selectableElement).paddingRight, 10);
        } else {
          top =
            selectableElement.textContent && rangeBoundingRect.top !== 0
              ? rangeBoundingRect.top
              : textBoundingRect.top + parseInt(getComputedStyle(selectableElement).paddingTop, 10);
          pX =
            selectableElement.textContent && rangeClientRects.length > 0
              ? rangeClientRects[0].left
              : textBoundingRect.left +
                parseInt(getComputedStyle(selectableElement).paddingLeft, 10);
        }
      }

      const scrollDiff = 75;

      if (bottom && bottom > editorRootBoundingRect.bottom - textLineHeight - scrollDiff) {
        // scroll bottom
        editorRoot.scrollTop +=
          bottom - editorRootBoundingRect.bottom + textLineHeight + scrollDiff;
      }

      if (top && top < editorRootBoundingRect.top + textLineHeight + scrollDiff) {
        // scroll top
        editorRoot.scrollTop -= editorRootBoundingRect.top - top + textLineHeight + scrollDiff;
      }

      if (pX && pX < scrollDiff) {
        // scroll left
        window.scrollBy(pX - scrollDiff, 0);
      }

      if (pX && pX > window.innerWidth - scrollDiff) {
        // scroll right
        window.scrollBy(pX - window.innerWidth + scrollDiff, 0);
      }
    }
  }
}
