import { Logger } from '_common/services';
import { Command } from '../Command';
import { PathUtils, SelectionFixer } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager';
import { ReduxInterface } from 'Editor/services';
import { ELEMENTS } from 'Editor/services/consts';
import { ErrorElementNotEditable } from '../../Errors';

export class BackspaceCommand extends Command {
  private event: KeyboardEvent;

  constructor(context: Editor.Edition.Context, event: KeyboardEvent) {
    super(context);
    this.event = event;
  }

  protected handleBackspaceAtStart(
    ctx: Editor.Edition.ActionContext,
    elementData: Editor.Data.Node.Data,
    elementPath: Editor.Selection.Path,
  ): boolean {
    logger.info('handleBackspaceAtStart', ctx, elementData, elementPath);
    if (!this.context.DataManager) {
      return false;
    }

    let structureModel = this.context.DataManager.structure.structureModel;

    if (!structureModel) {
      return false;
    }

    let previousData: Editor.Data.Node.Data | null = null;
    let previousPath: Editor.Selection.Path = [];
    let baseId: string | null = null;

    if (ctx.baseData.id && elementData.id === ctx.baseData.id) {
      // get preivous base block element
      const previousId = this.context.DataManager.structure.getPreviousChildId(ctx.baseData.id);
      if (previousId) {
        const previousModel = this.context.DataManager.nodes.getNodeModelById(previousId);
        if (previousModel) {
          previousData = previousModel?.selectedData();
          baseId = previousData?.id || null;
        }
      }
    } else if (elementPath.length > 0) {
      //
      const result = NodeUtils.getPreviousAncestor(ctx.baseData, elementPath);
      if (result) {
        previousData = result.data;
        previousPath = result.path;

        baseId = ctx.baseData.id || null;
      }
    }

    if (baseId && previousData) {
      // TODO:

      if (!NodeUtils.isBlockDeletableData(previousData)) {
        // check non deletable
        // TODO check if current is empty (???)

        ctx.range.updateRangePositions(
          {
            b: baseId,
            p: [...previousPath, 'childNodes', 0],
          },
          {
            b: baseId,
            p: [...previousPath, 'childNodes', previousData.childNodes?.length || 1],
          },
        );
        ctx.avoidNextNonCollapsedAction = true;
        return true;
      }
      // if (!NodeUtils.isBlockEditableData(previousData)) {
      //   // check non editable
      //   if (this.context.contentManipulator) {
      //     return this.context.contentManipulator.removeBlock(ctx, baseId);
      //   }
      // } else
      else if (
        !NodeUtils.isBlockEditableData(previousData) ||
        NodeUtils.isMultiBlockContainerData(previousData) ||
        NodeUtils.isFigureData(previousData) ||
        NodeUtils.isTableData(previousData)
      ) {
        // check non editable, figures, tables and multiblock containers
        ctx.range.updateRangePositions(
          {
            b: baseId,
            p: [...previousPath, 'childNodes', 0],
          },
          {
            b: baseId,
            p: [...previousPath, 'childNodes', previousData.childNodes?.length || 1],
          },
        );

        ctx.avoidNextNonCollapsedAction = true;
        return true;
      } else if (NodeUtils.isBlockTextData(previousData)) {
        // check text data

        let path: Editor.Selection.Path = [];
        if (NodeUtils.isBlockTextData(elementData)) {
          // mergeables
          if (previousData.childNodes) {
            const length = previousData.childNodes.length;
            const lastChild = previousData.childNodes[length - 1];

            if (
              !NodeUtils.isParagraphMarker(lastChild) &&
              NodeUtils.isLastChildElementData(lastChild)
            ) {
              path = [...previousPath, 'childNodes', length - 1];
            } else {
              path = [...previousPath, 'childNodes', length];
            }
          } else {
            path = [...previousPath, 'childNodes', 0];
          }

          ctx.range.updateRangePositions(
            {
              b: baseId,
              p: path,
            },
            ctx.range.end,
          );
        } else {
          // move selection to previous element
          ctx.range.updateRangePositions({
            b: baseId,
            p: [...previousPath, 'childNodes', previousData.childNodes?.length || 0],
          });
          ctx.avoidNextNonCollapsedAction = true;
        }
        return true;
      }
    }

    return false;
  }

  protected handleNonTextElement(
    ctx: Editor.Edition.ActionContext,
    elementData: Editor.Data.Node.Data,
    elementPath: Editor.Selection.Path,
  ): boolean {
    let subPath = ctx.range.start.p.slice(elementPath.length);

    if (NodeUtils.isPathAtContentStart(elementData, subPath)) {
      return this.handleBackspaceAtStart(ctx, elementData, elementPath);
    } else {
      let startPath: Editor.Selection.Path = ['childNodes', 0];
      let endPath: Editor.Selection.Path = ['childNodes', elementData.childNodes?.length || 1];
      ctx.range.updateRangePositions(
        { b: ctx.range.start.b, p: startPath },
        { b: ctx.range.start.b, p: endPath },
      );
      ctx.avoidNextNonCollapsedAction = true;

      return true;
    }
  }

  protected handleTextElement(
    ctx: Editor.Edition.ActionContext,
    elementData: Editor.Data.Node.Data,
    elementPath: Editor.Selection.Path,
  ): boolean {
    if (
      !ctx.baseModel ||
      !ctx.baseData ||
      !this.context.selection?.modifiers ||
      !this.context.DataManager
    ) {
      return false;
    }

    let subPath = ctx.range.start.p.slice(elementPath.length);

    if (NodeUtils.isPathAtContentStart(elementData, subPath)) {
      // selection at start
      if (this.context.DataManager.numbering.isListElement(ctx.baseModel.id)) {
        // handle list
        this.context.DataManager.numbering.removeBlocksFromList([ctx.baseModel.id]);
        return true;
      } else {
        return this.handleBackspaceAtStart(ctx, elementData, elementPath);
      }
    } else {
      // selection at middle or end

      let closestTrackedDelete = NodeUtils.closestOfTypeByPath(ctx.baseData, ctx.range.start.p, [
        'tracked-delete',
      ]);

      let closestNonEditable = NodeUtils.closestPreviousAncestorOfType(
        ctx.baseData,
        ctx.range.start.p,
        [...NodeUtils.INLINE_NON_EDITABLE_TYPES, ELEMENTS.FieldElement.ELEMENT_TYPE],
      );

      // check inline elements
      if (this.context.editionMode === 'SUGGESTIONS' && closestTrackedDelete) {
        let subPath = ctx.range.start.p.slice(closestTrackedDelete.path.length);
        if (
          !PathUtils.isPathEqual(closestTrackedDelete.path, ctx.range.start.p) &&
          !NodeUtils.isPathAtContentStart(closestTrackedDelete.data, subPath)
        ) {
          ctx.range.updateRangePositions({
            b: ctx.baseModel.id,
            p: closestTrackedDelete.path,
          });
          return true;
        }
      }

      if (
        closestNonEditable &&
        (!NodeUtils.isFieldData(closestNonEditable.data) ||
          NodeUtils.isFieldCaptionData(closestNonEditable.data))
      ) {
        let isAtStart = false;
        let nonEditableSubPath = ctx.range.start.p.slice(closestNonEditable.path.length);

        if (
          PathUtils.isChildPath(closestNonEditable.path, ctx.range.start.p) &&
          NodeUtils.isPathAtContentStart(closestNonEditable.data, nonEditableSubPath)
        ) {
          isAtStart = true;
        }

        if (!isAtStart) {
          const offset = Number(closestNonEditable.path[closestNonEditable.path.length - 1]);

          if (isNaN(offset)) {
            return false;
          }

          let startPath = [...closestNonEditable.path];
          let endPath = [...closestNonEditable.path];
          endPath[endPath.length - 1] = offset + 1;

          ctx.range.updateRangePositions(
            {
              b: ctx.baseModel.id,
              p: startPath,
            },
            {
              b: ctx.baseModel.id,
              p: endPath,
            },
          );

          ctx.avoidNextNonCollapsedAction = true;

          // trigger confirmation modal
          if (NodeUtils.isFieldCaptionData(closestNonEditable.data)) {
            ReduxInterface.openDeleteCaptionConfirmationModal();
          }
          return true;
        }
      }

      // normalize text selection
      SelectionFixer.normalizeTextSelection(
        ctx.range,
        {
          suggestionMode: this.context.editionMode === 'SUGGESTIONS',
          forceWrapAsText: true,
          isBackspace: true,
        },
        this.context.DataManager,
      );

      this.context.selection.modifiers.modify(ctx.range, 'expand', 'character', 'backward');

      return true;
    }

    // return false;
  }

  protected handleCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    if (!this.context.DataManager) {
      return false;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(ctx.range.start.b);

    const baseData = baseModel?.selectedData();
    if (!baseModel || !baseData) {
      return false;
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
      throw new ErrorElementNotEditable();
    }

    ctx.setModelAndData(baseModel, baseData);

    const result = NodeUtils.closestOfTypeByPath(
      baseData,
      ctx.range.start.p,
      NodeUtils.BLOCK_EDITABLE_TYPES,
    );

    if (result) {
      if (NodeUtils.isBlockTextData(result.data)) {
        // text elements
        return this.handleTextElement(ctx, result.data, result.path);
      } else {
        // multi block container elements
        // legacy figure elements
        // table elements
        // non editable elements
        return this.handleNonTextElement(ctx, result.data, result.path);
      }
    }

    return false;
  }

  protected handleNonCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    if (!this.context.DataManager) {
      return false;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(ctx.range.start.b);
    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData) {
      return false;
    }

    // normalize text selection
    SelectionFixer.normalizeTextSelection(
      ctx.range,
      {
        suggestionMode: this.context.editionMode === 'SUGGESTIONS',
        // forceWrapAsText: true, // WARN: avoid this prop here
        isBackspace: true,
        forceTextAsWrap: true,
      },
      this.context.DataManager,
    );

    if (this.context.contentManipulator) {
      return this.context.contentManipulator.removeContent(ctx, { selectionDirection: 'backward' });
    }

    return false;
  }

  async handleExec(): Promise<void> {
    if (this.debug) {
      Logger.trace('BackspaceCommand exec', this);
    }

    this.buildActionContext();

    this.getSuggestionRefFromContent();

    if (!this.context.DataManager || !this.context.DataManager.selection || !this.actionContext) {
      throw new Error('Invalid context');
    }

    //TODO:
    // fix block selection?

    // handle collapsed selection
    if (this.actionContext.range.collapsed) {
      if (!this.handleCollapsedSelection(this.actionContext)) {
        return;
      }
    }

    // handle non collapsed selection
    if (!this.actionContext.range.collapsed && !this.actionContext.avoidNextNonCollapsedAction) {
      if (!this.handleNonCollapsedSelection(this.actionContext)) {
        return;
      }
    }

    this.handleSuggestionsUpdate();

    this.applySelection();

    this.createPatch();
  }
}
