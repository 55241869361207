import { useDispatch } from 'react-redux';
import { notify } from '_common/components/ToastSystem';
import { usePresentationManager } from 'Presentation/PresentationManager';
import { setRightPanel, setSelectedCard } from 'Presentation/PresentationSlice';
import useFilteredTasks from './useFilteredTasks';
import useFilteredComments from './useFilteredComments';
import { useSelector } from '_common/hooks';

const useFocusCard = () => {
  const dispatch = useDispatch();
  const filteredComments = useFilteredComments();
  const filteredTasks = useFilteredTasks();
  const manager = usePresentationManager();
  const rightPanel = useSelector((state) => state.presentation.general.rightPanel);

  const focusCard = ({
    objectId,
    objectType,
  }: {
    objectId: Presentation.Data.Comment['id'];
    objectType?: 'comment' | 'task';
  }) => {
    const comment = filteredComments.find((comment) => comment.id === objectId);
    const task = filteredTasks.find((task) => task.id === objectId);
    const object: Presentation.Data.Task | Presentation.Data.Comment | undefined = comment ?? task;

    if (!object) {
      if (objectType) {
        notify({
          type: 'error',
          title: 'CARD_TYPE_X_NOT_ACCESSIBLE',
          titleValues: { cardType: objectType.charAt(0).toUpperCase() + objectType.slice(1) },
          message: 'LINK_CANNOT_BE_OPENED_BECAUSE_CARD_TYPE_X_DOESNT_EXIST',
          messageValues: { cardType: objectType },
        });
      }
      return;
    }

    dispatch(setSelectedCard(object.id));

    if (comment) {
      if (rightPanel && rightPanel !== 'comments') {
        dispatch(setRightPanel('comments'));
      }
      return manager.focusComment(comment);
    } else if (task) {
      if (rightPanel && rightPanel !== 'tasks') {
        dispatch(setRightPanel('tasks'));
      }
      return manager.focusTask(task);
    }
  };

  return { focusCard };
};

export default useFocusCard;
