import { useMemo } from 'react';

import { useSelector } from '_common/hooks';
import { useSlideId } from 'Presentation/hooks';

import styles from './ShapeSelection.module.scss';
import interactionControllerStyles from '_common/components/OnboardingOverlay/InteractionController.module.scss';

const ShapeSelection = () => {
  const currentSlideId = useSlideId();
  const selectedShape = useSelector((state) => state.presentation.general.selectedShape);
  const creating = useSelector((state) => state.presentation.general.creating);

  const selectionRect = useMemo<Omit<Rect, 'right' | 'bottom'> | null>(() => {
    if (!selectedShape) {
      return null;
    }

    const shape = document.getElementById(`slide-${selectedShape}`);
    if (!shape) {
      return null;
    }

    const slideRect = document.getElementById(currentSlideId);
    const slideRectBounds = slideRect?.getBoundingClientRect();
    const slideRectOffset = slideRectBounds && {
      top: slideRectBounds.top,
      left: slideRectBounds.left,
    };
    if (!slideRectOffset) {
      return null;
    }

    const { x, y, width, height } = shape.getBoundingClientRect();
    return { left: x - slideRectOffset.left, top: y - slideRectOffset.top, width, height };
  }, [selectedShape, currentSlideId]);

  if (!selectedShape || !selectionRect || creating?.anchor?.length) {
    return null;
  }

  return (
    <div
      id={interactionControllerStyles.skipControl}
      style={selectionRect}
      className={styles.root}
    />
  );
};

export default ShapeSelection;
