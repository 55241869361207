import { useEffect, useMemo } from 'react';

import { useDispatch, useObject, useSelector } from '_common/hooks';
import { setObjectPreview } from './ObjectPreviewSlice';
import { navigateToFile } from 'router/history';
import { notify } from '_common/components/ToastSystem';

import ObjectPreviewProvider from './ObjectPreviewContext';
import Topbar from './Topbar/Topbar';
import Preview from './Preview/Preview';

import styles from './ObjectPreview.module.scss';

const ObjectPreview = () => {
  const dispatch = useDispatch();

  const objectId = useSelector((state) => state.objectPreview.id);

  const { data } = useObject(
    { object_id: objectId ?? '', object_type: 'file' },
    { skip: !objectId },
  );

  const type = useMemo(() => {
    let type: ObjectPreviewType = undefined;

    if (data?.type && data.mime?.type) {
      switch (data.mime.type) {
        case 'image/png':
        case 'image/jpeg':
        case 'image/jpg':
          // add remaining image mime types
          type = 'image';
          break;
        case 'application/pdf':
          type = 'pdf';
          break;
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          type = 'docx';
          break;
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          type = 'presentation';
          break;
        default:
          navigateToFile(data.id);
          notify({
            type: 'success',
            title: 'ELEMENT_TYPE_DOWNLOADED',
            titleValues: { elementType: data.type.charAt(0).toUpperCase() + data.type.slice(1) },
            message: 'ELEMENT_TYPE_SUCCESSFULLY_DOWNLOADED',
            messageValues: { elementTitle: data.name },
          });

          dispatch(setObjectPreview({ id: null }));
      }
    }

    return type;
  }, [data]);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      close();
    }
  };

  const close = () => {
    dispatch(setObjectPreview({ id: null }));
  };

  if (objectId != null && data?.type === 'file' && type) {
    return (
      <div className={styles.root} data-testid="object-preview-root">
        <ObjectPreviewProvider type={type} object={data}>
          <Topbar />
          <Preview />
        </ObjectPreviewProvider>
      </div>
    );
  }
};

export default ObjectPreview;
