import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { JsonRange, PathUtils } from 'Editor/services/_Common/Selection';
import { ELEMENTS } from 'Editor/services/consts';
import { Logger } from '_common/services';
import { RemoveBlockOperation } from '../../Operations/StructureOperations';
import {
  InsertElementOperation,
  InsertTextOperation,
  RemoveContentOperation,
} from '../../Operations';

export class BaseManipulator {
  protected editionContext: Editor.Edition.Context;
  protected manipulatorContext: Editor.Edition.ManipulatorsContext;

  constructor(ctx: Editor.Edition.Context, manipulatorContext: Editor.Edition.ManipulatorsContext) {
    this.editionContext = ctx;
    this.manipulatorContext = manipulatorContext;
  }

  protected buildNewTrackedInsert(
    dataToAdd: string | Editor.Data.Node.Data | Editor.Data.Node.Data[],
    refId?: string,
  ): Editor.Data.Node.Data | undefined {
    if (!this.editionContext.DataManager) {
      return undefined;
    }

    if (!refId) {
      refId = NodeUtils.generateUUID();
    }

    let childData: Editor.Data.Node.Data[] = [];
    if (Array.isArray(dataToAdd)) {
      childData = dataToAdd;
    } else if (typeof dataToAdd === 'string') {
      const data = NodeDataBuilder.buildData({
        type: 'text',
        content: dataToAdd,
      });
      if (data) {
        childData.push(data);
      }
    } else {
      childData.push(dataToAdd);
    }

    const loggedUserId = this.editionContext.DataManager.users.loggedUserId;
    if (childData && refId && loggedUserId) {
      const trackedBuilder = new NodeDataBuilder(ELEMENTS.TrackInsertElement.ELEMENT_TYPE)
        .addProperty('element_reference', refId)
        .addProperty('author', loggedUserId)
        .addChildData(...childData);

      return trackedBuilder.build();
    }

    return undefined;
  }

  protected buildNewTrackedDelete(
    dataToAdd: Editor.Data.Node.Data | Editor.Data.Node.Data[] | string,
    refId?: string,
  ): Editor.Data.Node.Data | undefined {
    if (!this.editionContext.DataManager) {
      return undefined;
    }

    if (!refId) {
      refId = NodeUtils.generateUUID();
    }

    let childData: Editor.Data.Node.Data[] = [];
    if (typeof dataToAdd === 'string') {
      const textData = NodeDataBuilder.buildData({
        type: 'text',
        content: dataToAdd,
      });

      if (textData) {
        childData.push(textData);
      }
    } else if (typeof dataToAdd === 'object' && !Array.isArray(dataToAdd)) {
      childData.push(dataToAdd);
    } else {
      childData.push(...dataToAdd);
    }

    const loggedUserId = this.editionContext.DataManager.users.loggedUserId;

    if (childData && refId && loggedUserId) {
      const trackedBuilder = new NodeDataBuilder(ELEMENTS.TrackDeleteElement.ELEMENT_TYPE)
        .addProperty('element_reference', refId)
        .addProperty('author', loggedUserId)
        .addChildData(...childData);

      return trackedBuilder.build();
    }

    return undefined;
  }

  protected getInsertOperation(
    baseModel: Editor.Data.Node.Model,
    path: Editor.Selection.Path,
    dataToAdd: Editor.Data.Node.Data | string,
    options?: Editor.Edition.InsertContentOptions,
  ) {
    if (typeof dataToAdd === 'string') {
      return new InsertTextOperation(baseModel, path, dataToAdd);
    } else {
      return new InsertElementOperation(baseModel, path, dataToAdd, options);
    }
  }

  protected isUserAuthor(data: Editor.Data.Node.TrackedData): boolean {
    try {
      const author = data.properties.author;

      if (author != null && author === this.editionContext.DataManager?.users.loggedUserId) {
        return true;
      }
    } catch (error) {
      Logger.captureException(error);
    }
    return false;
  }

  protected getRemoveBlockOperation(
    baseModel: Editor.Data.Node.Model,
    blockData: Editor.Data.Node.Data,
    blockPath: Editor.Selection.Path,
  ): Editor.Edition.IOperationBuilder | undefined {
    if (!this.editionContext.DataManager) {
      return undefined;
    }

    let structureModel = this.editionContext.DataManager.structure.structureModel;
    if (!structureModel) {
      return undefined;
    }

    if (baseModel.id === blockData.id) {
      return new RemoveBlockOperation(this.editionContext, structureModel, baseModel.id);
    } else {
      const startPath = [...blockPath];
      const endPath = [...blockPath];
      const offset = Number(endPath[endPath.length - 1]);
      if (!isNaN(offset)) {
        endPath[endPath.length - 1] = offset + 1;
      }

      return new RemoveContentOperation(baseModel, startPath, endPath);
    }
  }

  protected removeTrackedParagraphMarkers(
    ctx: Editor.Edition.ActionContext,
    checkAuthor: boolean = false,
    useSelectedCells: boolean = false,
  ) {
    if (!this.editionContext.DataManager) {
      return;
    }

    let rangesData = JsonRange.splitRangeByTypes(
      this.editionContext.DataManager,
      ctx.range,
      [...NodeUtils.BLOCK_TEXT_TYPES, ...NodeUtils.BLOCK_NON_TEXT_TYPES],
      {
        onlyContainerLevel: true,
        useSelectedCells: useSelectedCells,
      },
    );

    for (let i = rangesData.length - 1; i >= 0; i--) {
      const range = rangesData[i].range;

      const baseModel = this.editionContext.DataManager.nodes.getNodeModelById(range.start.b);

      const baseData = baseModel?.selectedData();
      if (!baseModel || !baseData) {
        continue;
      }

      const closestBlock = NodeUtils.closestOfTypeByPath(baseData, range.getCommonAncestorPath(), [
        ...NodeUtils.BLOCK_TEXT_TYPES,
        // ...NodeUtils.BLOCK_NON_TEXT_TYPES,
      ]);

      // TODO remove track insert elements

      if (
        closestBlock &&
        NodeUtils.isBlockTextData(closestBlock.data) &&
        closestBlock.data.childNodes
      ) {
        const subStartPath = range.start.p.slice(closestBlock.path.length);
        const subEndPath = range.end.p.slice(closestBlock.path.length);
        const lastChildIndex = closestBlock.data.childNodes.length - 1;
        const lastChild = closestBlock.data.childNodes[lastChildIndex];
        if (
          !NodeUtils.isPathAtContentStart(closestBlock.data, subStartPath) &&
          NodeUtils.isPathAtContentEnd(closestBlock.data, subEndPath) &&
          NodeUtils.isParagraphMarker(lastChild) &&
          NodeUtils.isTrackInsertData(lastChild) &&
          (!checkAuthor || this.isUserAuthor(lastChild))
        ) {
          const markerStartPath: Editor.Selection.Path = [
            ...closestBlock.path,
            'childNodes',
            lastChildIndex,
          ];
          const markerEndPath: Editor.Selection.Path = [
            ...closestBlock.path,
            'childNodes',
            lastChildIndex + 1,
          ];

          let nextModel: Editor.Data.Node.Model | undefined;
          let nextData: Editor.Data.Node.Data | undefined | null;
          let nextPath: Editor.Selection.Path | undefined;

          // TODO
          // improve this when the replacewith is not next element
          // Example:
          //      Paragraph(marker)
          //      Table
          //      Paragraph
          if (closestBlock.data.id === baseModel.id) {
            let replacewith = lastChild.properties.replacewith;
            // level 0
            nextModel = this.editionContext.DataManager.nodes.getNextModelById(baseModel.id);
            if (nextModel && nextModel.id === replacewith) {
              nextData = nextModel.selectedData();
              nextPath = [];
            }
          } else {
            let replacewithsibling = lastChild.properties.replacewithsibling;
            // inside container
            nextModel = baseModel;
            const next = NodeUtils.getNextSibling(baseData, closestBlock.path);
            if (next && next.data.id === replacewithsibling) {
              nextData = next.data;
              nextPath = next.path;
            }
          }

          if (nextModel && nextData && nextPath) {
            // join content
            let pathToMerge: Editor.Selection.Path | undefined = markerEndPath;

            let cloneStartPath: Editor.Selection.Path = ['childNodes', 0];
            let cloneEndPath: Editor.Selection.Path = [
              'childNodes',
              nextData.childNodes?.length || 0,
            ];

            const clonedNodes = NodeUtils.cloneData(nextData, cloneStartPath, cloneEndPath, [
              ...NodeUtils.INLINE_TYPES,
              'text',
            ]);

            for (let i = 0; i < clonedNodes.length; i++) {
              if (pathToMerge) {
                let insertOp: InsertElementOperation = new InsertElementOperation(
                  baseModel,
                  pathToMerge,
                  clonedNodes[i],
                  { mergeText: false, allowAll: true },
                );
                if (insertOp.hasOpsToApply()) {
                  insertOp.apply();
                  pathToMerge = insertOp.getAdjustedPath();
                }
              }
            }

            // remove block element
            let removeBlockOp = this.getRemoveBlockOperation(nextModel, nextData, nextPath);
            if (removeBlockOp) {
              removeBlockOp.apply();
            }

            // get path to update range
            let splitPointPath = markerStartPath;
            let previousSibling = NodeUtils.getPreviousSibling(baseData, markerStartPath);
            if (previousSibling) {
              if (NodeUtils.isTextData(previousSibling.data)) {
                splitPointPath = [
                  ...previousSibling.path,
                  'content',
                  previousSibling.data.content.length,
                ];
              } else {
                splitPointPath = [
                  ...previousSibling.path,
                  'childNodes',
                  previousSibling.data.childNodes?.length || 0,
                ];
              }
            }

            // remove tracked marker
            const removeMarkerOp = new RemoveContentOperation(
              baseModel,
              markerStartPath,
              markerEndPath,
              { mergeText: false },
            );
            removeMarkerOp.apply();

            // update range
            let nextRange = rangesData[i + 1]?.range;
            if (
              nextRange &&
              ctx.range.end.b === nextModel.id &&
              ctx.range.end.b === nextRange.end.b
            ) {
              if (PathUtils.isChildPath(nextPath, ctx.range.end.p)) {
                // WARN:
                // problem with merged text nodes after last remove
                // forcing text nodes to not be merged

                // merge only paths within blocks
                const subSPath = markerStartPath.slice(closestBlock.path.length);
                const subEPath = ctx.range.end.p.slice(nextPath.length);

                let newEndPath = PathUtils.transformPath(subEPath, subSPath, 'ADD');
                if (newEndPath) {
                  if (PathUtils.comparePath(ctx.range.start.p, splitPointPath) > 0) {
                    ctx.range.updateStartPosition({
                      b: baseModel.id,
                      p: [...splitPointPath],
                    });
                  }

                  ctx.range.updateEndPosition({
                    b: baseModel.id,
                    p: [...closestBlock.path, ...newEndPath],
                  });
                }
              } else {
                if (PathUtils.comparePath(ctx.range.start.p, splitPointPath) > 0) {
                  ctx.range.updateRangePositions({
                    b: baseModel.id,
                    p: [...splitPointPath],
                  });
                } else {
                  ctx.range.updateEndPosition({
                    b: baseModel.id,
                    p: [...splitPointPath],
                  });
                }
              }
            } else if (ctx.range.start.b === ctx.range.end.b) {
              if (PathUtils.comparePath(ctx.range.start.p, splitPointPath) > 0) {
                ctx.range.updateRangePositions({
                  b: baseModel.id,
                  p: [...splitPointPath],
                });
              } else {
                ctx.range.updateEndPosition({
                  b: baseModel.id,
                  p: [...splitPointPath],
                });
              }
            }
          }
        }
      }
    }
  }

  protected handleInsertSplitMarker(
    previousModel: Editor.Data.Node.Model,
    previousPath: Editor.Selection.Path,
    currentModel: Editor.Data.Node.Model,
    currentPath: Editor.Selection.Path,
    refId: string | undefined,
  ) {
    if (!this.editionContext.DataManager) {
      return false;
    }

    const previousData = previousModel.selectedData();
    if (!previousData) {
      return false;
    }

    const currentData = currentModel.selectedData();
    if (!currentData) {
      return false;
    }

    const previousBlock = NodeUtils.closestOfTypeByPath(previousData, previousPath, [
      ...NodeUtils.BLOCK_TEXT_TYPES,
    ]);

    const currentBlock = NodeUtils.closestOfTypeByPath(currentData, currentPath, [
      ...NodeUtils.BLOCK_TEXT_TYPES,
    ]);

    if (
      previousBlock &&
      currentBlock &&
      NodeUtils.isBlockTextData(previousBlock.data) &&
      NodeUtils.isBlockTextData(currentBlock.data)
    ) {
      let previousLastChild: Editor.Data.Node.Data | undefined;

      let length = previousBlock.data.childNodes?.length || 0;
      previousLastChild = previousBlock.data.childNodes?.[length - 1];

      if (!NodeUtils.isParagraphMarker(previousLastChild)) {
        const loggedUserId = this.editionContext.DataManager.users.loggedUserId;

        if (refId && loggedUserId) {
          const markerBuilder = new NodeDataBuilder(ELEMENTS.TrackInsertElement.ELEMENT_TYPE)
            .addProperty('element_reference', refId)
            .addProperty('author', loggedUserId);

          if (previousModel.id === currentModel.id && previousModel.id !== previousData.id) {
            // container element
            markerBuilder.addProperty('replacewithsibling', currentBlock.data.id);
          } else {
            markerBuilder.addProperty('replacewith', currentBlock.data.id);
          }

          const markerData = markerBuilder.build();

          if (markerData) {
            let pathToInsert: Editor.Selection.Path = [
              ...previousBlock.path,
              'childNodes',
              previousBlock.data.childNodes?.length || 0,
            ];
            let op = new InsertElementOperation(previousModel, pathToInsert, markerData);
            op.apply();
          }
        }
      }
    }
  }

  protected validateCaptionsOnRange(ctx: Editor.Edition.ActionContext) {
    if (!this.editionContext.DataManager) {
      return false;
    }

    let fieldsData = ctx.range.getNodes(this.editionContext.DataManager, ['f']);

    if (fieldsData) {
      for (let i = 0; i < fieldsData.length; i++) {
        if (NodeUtils.isFieldCaptionData(fieldsData[i].childData)) {
          return true;
        }
      }
    }
    return false;
  }
}
