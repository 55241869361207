import { useSelector } from '_common/hooks';
import PatternDefs from './PatternDefs';
import { useSlideData } from '../SlideData';

type PatternBackgroundProps = {
  background: Presentation.Data.Common.PatternFillType;
  size: Presentation.Data.Common.Size;
};

const PatternBackground = ({ background, size }: PatternBackgroundProps) => {
  const { slideId } = useSlideData();
  const zoom = useSelector((state) => state.presentation.general.zoom);

  const fillId = `${slideId}-${background.preset}`;

  return (
    <>
      <defs>
        <PatternDefs id={fillId} background={background} />
      </defs>
      <rect
        width={size.width * zoom}
        height={size.height * zoom}
        transform={`scale(${1 / zoom})`}
        fill={`url(#${fillId})`}
      />
    </>
  );
};

export default PatternBackground;
