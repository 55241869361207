import { Input, Toggle, Tooltip } from 'dodoc-design-system';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { notify } from '_common/components/ToastSystem';
import styles from './Toolbar.module.scss';

type PageNavigatorProps = {
  onPageUp: () => void;
  onPageDown: () => void;
  goToPage: (page: number) => void;
  totalPageNumber: number;
  currentPageNumber: number;
};

const PageNavigator = ({
  onPageUp,
  onPageDown,
  totalPageNumber,
  goToPage,
  currentPageNumber,
}: PageNavigatorProps) => {
  const intl = useIntl();
  const [localPageNumber, setLocalPageNumber] = useState('1');

  useEffect(() => {
    if (+localPageNumber !== currentPageNumber) {
      setLocalPageNumber(`${currentPageNumber}`);
    }
  }, [currentPageNumber]);

  const inputWidth = useMemo(() => {
    const totalPageNumberDigits = (Math.log(+totalPageNumber) * Math.LOG10E + 1) | 0;
    return totalPageNumberDigits < 2 ? `32px` : undefined;
  }, [totalPageNumber]);

  return (
    <>
      <Tooltip
        placement="bottom"
        content={intl.formatMessage({ id: 'PREVIOUS_PAGE' })}
        testId="menu-previous-page-tooltip"
      >
        <Toggle
          margin="0 0.75rem 0 0"
          variant="ghost"
          size="medium"
          onClick={onPageUp}
          icon="ArrowUpGrey"
          testId="menu-previous-page-toggle"
          disabled={currentPageNumber === 1}
        />
      </Tooltip>
      <Tooltip
        placement="bottom"
        content={intl.formatMessage({ id: 'NEXT_PAGE' })}
        testId="menu-next-page-toolip"
      >
        <Toggle
          margin="0 0.75rem 0 0"
          variant="ghost"
          size="medium"
          onClick={onPageDown}
          icon="ArrowDownGrey"
          testId="menu-next-page-toggle"
          disabled={currentPageNumber === totalPageNumber}
        />
      </Tooltip>
      <Input
        type="number"
        size="medium"
        placeholder="1"
        width={inputWidth}
        margin="0 0.5rem 0 0"
        max={`${totalPageNumber}`}
        min="1"
        value={`${localPageNumber}`}
        clearable={false}
        onChange={(e) => setLocalPageNumber(e.target.value)}
        onEnterKey={() => {
          if (!Number.isNaN(+localPageNumber) && +localPageNumber !== currentPageNumber) {
            if (+localPageNumber <= totalPageNumber && +localPageNumber >= 1) {
              goToPage(+localPageNumber);
            } else {
              notify({
                type: 'error',
                title: 'WRONG_PAGE_NUMBER',
                message: 'PAGE_NUMBER_ERROR',
                messageValues: { number: localPageNumber },
              });
              setLocalPageNumber(`${currentPageNumber}`);
            }
          } else {
            setLocalPageNumber(`${currentPageNumber}`);
          }
        }}
        onBlur={() => {
          if (+localPageNumber !== currentPageNumber) {
            setLocalPageNumber(`${currentPageNumber}`);
          }
        }}
        testId="page"
      />
      <span className={styles.totalPages}>/ {totalPageNumber}</span>
    </>
  );
};

export default PageNavigator;
