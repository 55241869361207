import { createContext, memo, ReactNode, useContext, useMemo } from 'react';

import { usePresentationData } from 'Presentation/PresentationData';
import { useSlideData } from '../SlideData';
import usePosition from './usePosition';
import useSize from './useSize';
import { cloneObject } from 'Presentation/utils';

type ShapeContextValue = {
  position: Presentation.Data.Common.Position;
  size: Presentation.Data.Common.Size;
  shape: Presentation.Data.Shape;
};

const ShapeContext = createContext<ShapeContextValue | undefined>(undefined);

type ShapeDataProps = {
  children: ReactNode;
  shape: Presentation.Data.Shape;
};
const ShapeDataProvider = ({ children, shape }: ShapeDataProps) => {
  const { theme } = usePresentationData();
  const { color } = useSlideData();

  const position = usePosition(shape);
  const size = useSize(shape);

  const ln = useMemo(() => {
    if (!shape.style?.lnRef) {
      return shape.properties.ln;
    }

    const themeLine = theme.formatScheme.lineStyleList[shape.style.lnRef.index - 1];
    if (!themeLine) {
      return shape.properties.ln;
    }

    if (shape.properties.ln?.fill) {
      return shape.properties.ln;
    }

    const lnColor = shape.style.lnRef.color;
    const lnBuilder =
      themeLine.fill?.type === 'solid' && lnColor
        ? cloneObject({ ...themeLine, fill: { ...themeLine.fill, color: lnColor } })
        : cloneObject(themeLine);
    if (lnBuilder) {
      const parsedColor = color(shape.style.lnRef.color).substring(1);

      switch (lnBuilder.fill?.type) {
        case 'solid':
          lnBuilder.fill.color = {
            ...lnBuilder.fill.color,
            base: parsedColor,
            reference: undefined,
          };
          break;
        case 'gradient':
          lnBuilder.fill.stops = lnBuilder.fill.stops.map((s) => ({
            ...s,
            color: { ...s.color, base: parsedColor, reference: undefined },
          }));
          break;
      }
    }

    return lnBuilder;
  }, [shape]);

  const fill = useMemo(() => {
    if (!shape.style?.fillRef) {
      return shape.properties.fill;
    }

    //TODO:PRESENTATION index - 1 giving incorrect values, check if correct in special cases
    const themeFill = theme.formatScheme.fillStyleList[shape.style.fillRef.index /* - 1 */];
    if (!themeFill) {
      return shape.properties.fill;
    }

    if (shape.properties.fill) {
      return shape.properties.fill;
    }

    const fillBuilder = cloneObject(themeFill);
    if (fillBuilder) {
      const parsedColor = color(shape.style.fillRef.color).substring(1);

      switch (fillBuilder.type) {
        case 'solid':
          fillBuilder.color = {
            ...fillBuilder.color,
            base: parsedColor,
            reference: undefined,
          };
          break;
        case 'gradient':
          fillBuilder.stops = fillBuilder.stops.map((s) => ({
            ...s,
            color: { ...s.color, base: parsedColor, reference: undefined },
          }));
          break;
      }
    }

    return fillBuilder;
  }, [shape]);

  const effects = useMemo(() => {
    if (!shape.properties.effects) {
      shape.properties.effects = [];
    }

    if (!shape.style?.effectRef) {
      return shape.properties.effects;
    }

    //TODO:PRESENTATION index - 1 giving incorrect values, check if correct in special cases

    const index = shape.style.effectRef.index > 0 ? shape.style.effectRef.index - 1 : 0; // avoid - 1 index
    const themeEffect = theme.formatScheme.effectsStyleList[index].effects;

    if (themeEffect) {
      return [...themeEffect, ...shape.properties.effects ] 
    }

    return shape.properties.effects;

  }, [shape]);


  return (
    <ShapeContext.Provider
      value={{ shape: { ...shape, properties: { ...shape.properties, ln, fill, effects } }, position, size }}
    >
      {children}
    </ShapeContext.Provider>
  );
};

export const useShapeData = () => {
  const context = useContext(ShapeContext);
  if (context === undefined) {
    throw new Error('useShapeData can only be used in a ShapeData');
  }
  return context;
};

export const useShapeProperties = () => {
  const { shape } = useShapeData();
  return shape.properties;
};

export default memo(ShapeDataProvider);
