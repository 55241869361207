import { FC, MouseEventHandler, useMemo } from 'react';
import Shape from '../Shape';
import GroupDataProvider, { useGroupData } from './GroupData';
import { useSlideData } from '../../SlideData';
import { useDispatch, useSelector } from '_common/hooks';
import { usePresentationManager } from 'Presentation/PresentationManager';
import { setSelectedCard, setSelectedShape } from 'Presentation/PresentationSlice';
import { cloneObject } from 'Presentation/utils';

type GroupShapeProps = {
  groupShape: Presentation.Data.GroupShape;
};

const GroupShape: FC<GroupShapeProps> = ({ groupShape }) => {
  const dispatch = useDispatch();
  const manager = usePresentationManager();
  const { isThumbnailSlide } = useSlideData();

  //Get parent group if applicable (will return undefined if this is top level group)
  const parentGroup = useGroupData();

  const selectedShape = useSelector((state) => state.presentation.general.selectedShape);
  const selectedCard = useSelector((state) => state.presentation.general.selectedCard);

  //Calculate group transform in order to position and scale properly
  const { translateX, translateY, scaleX, scaleY } = useMemo(() => {
    //Child offset provided by parent group, if top level group offset is 0
    const parentChildOffset = parentGroup?.properties?.xfrm?.chOff ?? { x: 0, y: 0 };

    const { off, ext, chOff, chExt } = groupShape.properties.xfrm ?? {};
    //If any of the transform properties are missing, return default values (no transform)
    if (!off || !ext || !chOff || !chExt) {
      return { translateX: 0, translateY: 0, scaleX: 1, scaleY: 1 };
    }

    //Subtract the child offset provided in the group transform
    const translateX = off.x - parentChildOffset.x;
    const translateY = off.y - parentChildOffset.y;

    //Calculate scale based on the group and child extents
    const scaleX = ext.cx / chExt.cx;
    const scaleY = ext.cy / chExt.cy;

    return { translateX, translateY, scaleX, scaleY };
  }, [groupShape, parentGroup]);

  const handleSelectShape: MouseEventHandler<SVGGElement> = (e) => {
    //Only allow selection of slide shapes (skip thumbnail and non top level group)
    if (isThumbnailSlide || groupShape.origin !== 'slide' || parentGroup) {
      return;
    }

    e.stopPropagation();
    if (selectedShape !== groupShape.id) {
      manager.clearSelection();
      dispatch(setSelectedShape(groupShape.id));
    }

    if (selectedCard) {
      dispatch(setSelectedCard(null));
    }
  };

  return (
    <g
      id={isThumbnailSlide ? `thumbnail-${groupShape.id}` : `${groupShape.origin}-${groupShape.id}`}
      //Required for PresentationRange to get the original shape id
      data-id={groupShape.id}
      data-type="shape"
      //Apply group transform
      transform={`translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
      onMouseDown={handleSelectShape}
    >
      <GroupDataProvider shape={groupShape}>
        {groupShape.shapes.map((childShape) => {
          const clonedChild = cloneObject(childShape);
          clonedChild.origin = `grouped-by-${groupShape.id}`;
          //If the child shape has a fill of type group, apply the group fill to the child
          if (clonedChild.properties.fill?.type === 'group') {
            clonedChild.properties.fill = groupShape.properties.fill;
          }

          return <Shape key={childShape.id} shape={clonedChild} />;
        })}
      </GroupDataProvider>
    </g>
  );
};

export default GroupShape;
