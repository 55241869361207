import { InsertBlockCommand, InsertInlineCommand } from './GenericCommands';
import {
  ArrowDownCommand,
  ArrowLeftCommand,
  ArrowRightCommand,
  ArrowUpCommand,
  BackspaceCommand,
  DefaultCommand,
  DeleteCommand,
  EnterCommand,
  ProcessCommand,
  TabCommand,
} from './KeyDownCommands';

import {
  InsertImageCommand,
  UpdateImageSizeCommand,
  ChangeImageCommand,
  UpdateImagePropertiesCommand,
} from './ImageCommands';

import {
  AddTemporaryCommentCommand,
  AddCommentCommand,
  RemoveTemporaryCommentCommand,
} from './CommentsCommands';

import {
  EditEquationCommand,
  InsertEquationCommand,
  UpdateEquationCommand,
} from './EquationsCommands';

import { InsertNoteCommand } from './NotesCommands';

import { InsertCitationCommand } from './CitationsCommands';

import { InsertHyperlinkCommand, DeleteHyperlinkCommand } from './HyperlinksCommands';

import {
  DeleteTableCommand,
  InsertTableCommand,
  TableOperationCommand,
  UpdateColumnWidthsCommand,
  UpdateRowHeigthsCommand,
  UpdateTablePropertiesCommand,
  UpdateTableSizeCommand,
} from './TableCommands';
import { CompositionCommand } from './CompositionCommands';
import {
  EditCaptionCommand,
  EditCrossReferenceCommand,
  InsertCaptionCommand,
  InsertCrossReferenceCommand,
} from './FieldsCommands';

import { PasteCommand, CutCommand } from './ClipboardCommands';
import { StylesCommand, UpdateBlockPropertiesCommand } from './StylesCommands';

type ShortcutKeys<T> = {
  ctrlKey: T;
  shiftKey: string[];
  altKey: string[];
};

const DEFAULT_ALLOWED_KEYS: string[] = [
  'CapsLock',
  'Fn',
  'FnLock',
  'NumLock',
  'End',
  'Home',
  'PageDown',
  'PageUp',
  'F1',
  'F2',
  'F3',
  'F4',
  'F5',
  'F6',
  'F7',
  'F8',
  'F9',
  'F10',
  'F11',
  'F12',
  'F13',
  'F14',
  'F15',
  'F16',
  'F17',
  'F18',
  'F19',
  'F20',
  'Control',
];

const ALLOWED_SHORTCUT_KEYS: ShortcutKeys<boolean | string[]> = {
  ctrlKey: true /* { c: 'c', x: 'x', v: 'v', r: 'r', shiftKey: { r: 'r' } } */,
  shiftKey: [],
  altKey: [],
};

const OVERRIDED_SHORTCUT_KEYS: ShortcutKeys<string[]> = {
  ctrlKey: [
    'ArrowLeft',
    'ArrowUp',
    'ArrowRight',
    'ArrowDown',
    'Backspace',
    'Delete',
    'Enter',
    'Tab',
    'V',
    'K',
    'k',
  ],
  shiftKey: [],
  altKey: [],
};

export class CommandFactory {
  private context: Editor.Edition.Context;

  constructor(context: Editor.Edition.Context) {
    this.context = context;
  }

  /**
   * validates if a keydown default action is allowed
   * @param {KeyboardEvent} event
   */
  isKeyDefaultAllowed(event: KeyboardEvent) {
    if (event.ctrlKey || event.metaKey) {
      if (typeof ALLOWED_SHORTCUT_KEYS.ctrlKey === 'boolean') {
        return (
          ALLOWED_SHORTCUT_KEYS.ctrlKey && !OVERRIDED_SHORTCUT_KEYS.ctrlKey.includes(event.key)
        );
      } else {
        return (
          ALLOWED_SHORTCUT_KEYS.ctrlKey.includes(event.key) &&
          !OVERRIDED_SHORTCUT_KEYS.ctrlKey.includes(event.key)
        );
      }
    }
    if (event.shiftKey) {
      return (
        ALLOWED_SHORTCUT_KEYS.shiftKey.includes(event.key) &&
        !OVERRIDED_SHORTCUT_KEYS.shiftKey.includes(event.key)
      );
    }
    if (event.altKey) {
      return (
        ALLOWED_SHORTCUT_KEYS.altKey.includes(event.key) &&
        !OVERRIDED_SHORTCUT_KEYS.altKey.includes(event.key)
      );
    }
    return DEFAULT_ALLOWED_KEYS.includes(event.key);
  }

  /**
   * validates if a keydown event is printable
   * @param {KeyboardEvent} event
   */
  isKeyPrintable(event: KeyboardEvent) {
    return event.key && event.key.length === 1;
  }

  getCommand(...[command, arg1, arg2, arg3, arg4]: Editor.Edition.GetCommandArgs) {
    switch (command) {
      // ----------------------------------------------------------------------------------------------
      //                             Key down commands
      // ----------------------------------------------------------------------------------------------
      case 'KEYDOWN':
        return this.getKeyDownCommand(arg1);
      // ----------------------------------------------------------------------------------------------
      //                             Comment commands
      // ----------------------------------------------------------------------------------------------
      case 'ADD_COMMENT':
        return this.getAddCommentCommand(arg1);
      case 'ADD_TEMPORARY_COMMENT':
        return this.getAddTemporaryComment();
      case 'REMOVE_TEMPORARY_COMMENT':
        return this.getRemoveTemporaryComment();
      // ----------------------------------------------------------------------------------------------
      //                             Compostion commands
      // ----------------------------------------------------------------------------------------------
      case 'COMPOSITION':
        return this.getCompositionCommand(arg1);
      // ----------------------------------------------------------------------------------------------
      //                             Insert commands
      // ----------------------------------------------------------------------------------------------
      case 'INSERT_INLINE':
        return this.getInsertInlineCommand();
      case 'INSERT_BLOCK':
        return this.getInsertBlockCommand();
      // ----------------------------------------------------------------------------------------------
      //                             Content commands
      // ----------------------------------------------------------------------------------------------
      case 'DELETE_CONTENT':
        return this.getDeleteContentCommand(arg1);
      // ----------------------------------------------------------------------------------------------
      //                             Fields commands
      // ----------------------------------------------------------------------------------------------
      case 'INSERT_CAPTION':
        return this.getInsertCaptionCommand(arg1);
      case 'EDIT_CAPTION':
        return this.getEditCaptionCommand(arg1);
      case 'INSERT_CROSSREF':
        return this.getInsertCrossReferenceCommand(arg1);
      case 'EDIT_CROSSREF':
        return this.getEditCrossReferenceCommand(arg1);
      case 'UPDATE_CROSSREF':
        return this.getUpdateCrossReferenceCommand();
      // ----------------------------------------------------------------------------------------------
      //                              Clipboard commands
      // ----------------------------------------------------------------------------------------------
      case 'PASTE':
        return this.getPasteCommand(arg1, arg2);
      case 'CUT':
        return this.getCutCommand(arg1);
      // ----------------------------------------------------------------------------------------------
      //                             Image commands
      // ----------------------------------------------------------------------------------------------
      case 'INSERT_IMAGE':
        return this.getInsertImageCommand(arg1);
      case 'UPDATE_IMAGE_SIZE':
        return this.getUpdateImageSizeCommand(arg1, arg2, arg3);
      case 'CHANGE_IMAGE':
        return this.getChangeImageCommand(arg1);
      case 'UPDATE_IMAGE_PROPERTIES':
        return this.getUpdateImagePropertiesCommand(arg1);
      // ----------------------------------------------------------------------------------------------
      //                             Equations commands
      // ----------------------------------------------------------------------------------------------
      case 'EDIT_EQUATION':
        return this.getEditEquationCommand();
      case 'INSERT_EQUATION':
        return this.getInsertEquationCommand(arg1);
      case 'UPDATE_EQUATION':
        return this.getUpdateEquationCommand(arg1, arg2, arg3);
      // ----------------------------------------------------------------------------------------------
      //                             Citations commands
      // ----------------------------------------------------------------------------------------------
      case 'INSERT_CITATION':
        return this.getInsertCitationCommand(arg1);
      // ----------------------------------------------------------------------------------------------
      //                             Hyperlinks commands
      // ----------------------------------------------------------------------------------------------
      case 'INSERT_HYPERLINK':
        return this.getInsertHyperlinkCommand(arg1, arg2, arg3, arg4);
      case 'DELETE_HYPERLINK':
        return this.getDeleteHyperlinkCommand();
      // ----------------------------------------------------------------------------------------------
      //                             Styles commands
      // ----------------------------------------------------------------------------------------------
      case 'UPDATE_BLOCK_PROPERTIES':
        return this.getUpdateBlockPropertiesCommand(arg1, arg2);
      case 'APPLY_STYLES_COMMAND':
        return this.getApplyStylesCommand(arg1, arg2, arg3);
      case 'REMOVE_STYLES_COMMAND':
        return this.getRemoveStylesCommand(arg1, arg2, arg3);
      // ----------------------------------------------------------------------------------------------
      //                             Note commands
      // ----------------------------------------------------------------------------------------------
      case 'INSERT_NOTE':
        return this.getInsertNoteCommand(arg1, arg2);
      // ----------------------------------------------------------------------------------------------
      //                             Table commands
      // ----------------------------------------------------------------------------------------------
      case 'DELETE_TABLE':
        return this.getDeleteTableCommand();
      case 'INSERT_TABLE':
        return this.getInsertTableCommand(arg1, arg2);
      case 'TABLE_OPERATION':
        return this.getTableOperationCommand(arg1);
      case 'UPDATE_COLUMN_WIDTHS':
        return this.getUpdateColumnWidthsCommand(arg1, arg2);
      case 'UPDATE_ROW_HEIGTHS':
        return this.getUpdateRowHeigthsCommand(arg1, arg2);
      case 'UPDATE_TABLE_PROPERTIES':
        return this.getUpdateTablePropertiesCommand(arg1);
      case 'UPDATE_TABLE_SIZE':
        return this.getUpdateTableSizeCommand(arg1, arg2, arg3);
      // ----------------------------------------------------------------------------------------------
      default:
        return null;
    }
  }

  // ----------------------------------------------------------------------------------------------
  //                              Key down commands
  // ----------------------------------------------------------------------------------------------

  private getKeyDownCommand(
    ...[event]: Editor.Edition.CommandArgsMapper['KEYDOWN']
  ): Editor.Edition.Command | null {
    let command = null;
    switch (event.key) {
      // Backspace command
      case 'Backspace': {
        if (this.context.editionMode !== 'DISABLED') {
          command = new BackspaceCommand(this.context, event);
        }
        break;
      }
      // Tab command
      case 'Tab': {
        if (this.context.editionMode !== 'DISABLED') {
          if (!event.ctrlKey && !event.metaKey) {
            command = new TabCommand(this.context, event);
          } else {
            // shortcus?
          }
        }
        break;
      }
      // Enter command
      case 'Enter':
        if (this.context.editionMode !== 'DISABLED') {
          if (!event.ctrlKey && !event.metaKey) {
            command = new EnterCommand(this.context, event);
          } else {
            // shortcus?
          }
        }
        break;
      // Delete command
      case 'Delete': {
        if (this.context.editionMode !== 'DISABLED') {
          command = new DeleteCommand(this.context, event);
        }
        break;
      }
      // ArrowLeft command
      case 'ArrowLeft': {
        command = new ArrowLeftCommand(this.context, event);
        break;
      }
      // ArrowUp command
      case 'ArrowUp': {
        command = new ArrowUpCommand(this.context, event);
        break;
      }
      // ArrowRight command
      case 'ArrowRight': {
        command = new ArrowRightCommand(this.context, event);
        break;
      }
      // ArrowDown command
      case 'ArrowDown': {
        command = new ArrowDownCommand(this.context, event);
        break;
      }
      // Process command
      case 'Process': {
        if (this.context.editionMode !== 'DISABLED') {
          command = new ProcessCommand(this.context, event);
        }
        break;
      }
      // Default command
      default: {
        if (this.context.editionMode !== 'DISABLED') {
          if (!event.ctrlKey && !event.metaKey) {
            if (this.isKeyPrintable(event) && !event.isComposing) {
              command = new DefaultCommand(this.context, event);
            }
          } else {
            // TODO: handle shortcuts?
            // ctrl + KeyV
            // ctrl + k
          }
        }
        break;
      }
    }
    return command;
  }

  // ----------------------------------------------------------------------------------------------
  //                                Fields commands
  // ----------------------------------------------------------------------------------------------

  protected getInsertCaptionCommand(
    ...[options]: Editor.Edition.CommandArgsMapper['INSERT_CAPTION']
  ) {
    return new InsertCaptionCommand(this.context, options);
  }

  protected getEditCaptionCommand(...[options]: Editor.Edition.CommandArgsMapper['EDIT_CAPTION']) {
    return new EditCaptionCommand(this.context, options);
  }

  protected getInsertCrossReferenceCommand(
    ...[options]: Editor.Edition.CommandArgsMapper['INSERT_CROSSREF']
  ) {
    return new InsertCrossReferenceCommand(this.context, options);
  }

  protected getEditCrossReferenceCommand(
    ...[options]: Editor.Edition.CommandArgsMapper['EDIT_CROSSREF']
  ) {
    return new EditCrossReferenceCommand(this.context, options);
  }

  protected getUpdateCrossReferenceCommand() {
    return new EditCrossReferenceCommand(this.context);
  }

  // ----------------------------------------------------------------------------------------------
  //                              Compostion commands
  // ----------------------------------------------------------------------------------------------

  protected getCompositionCommand(...[event]: Editor.Edition.CommandArgsMapper['COMPOSITION']) {
    return new CompositionCommand(this.context, event);
  }

  // ----------------------------------------------------------------------------------------------
  //                              Comment commands
  // ----------------------------------------------------------------------------------------------

  private getAddCommentCommand(...[comment]: Editor.Edition.CommandArgsMapper['ADD_COMMENT']) {
    return new AddCommentCommand(this.context, comment);
  }

  private getAddTemporaryComment() {
    return new AddTemporaryCommentCommand(this.context);
  }

  private getRemoveTemporaryComment() {
    return new RemoveTemporaryCommentCommand(this.context);
  }

  // ----------------------------------------------------------------------------------------------
  //                              Insert commands
  // ----------------------------------------------------------------------------------------------

  private getInsertInlineCommand(): Editor.Edition.Command {
    return new InsertInlineCommand(this.context);
  }

  private getInsertBlockCommand(
    ...[options]: Editor.Edition.CommandArgsMapper['INSERT_BLOCK']
  ): Editor.Edition.Command {
    return new InsertBlockCommand(this.context, options);
  }

  // ----------------------------------------------------------------------------------------------
  //                              Image commands
  // ----------------------------------------------------------------------------------------------

  private getInsertImageCommand(
    ...[image]: Editor.Edition.CommandArgsMapper['INSERT_IMAGE']
  ): Editor.Edition.Command {
    return new InsertImageCommand(this.context, image);
  }

  private getUpdateImageSizeCommand(
    ...[image, height, width]: Editor.Edition.CommandArgsMapper['UPDATE_IMAGE_SIZE']
  ): Editor.Edition.Command {
    return new UpdateImageSizeCommand(this.context, image, height, width);
  }

  private getChangeImageCommand(
    ...[image]: Editor.Edition.CommandArgsMapper['CHANGE_IMAGE']
  ): Editor.Edition.Command {
    return new ChangeImageCommand(this.context, image);
  }

  private getUpdateImagePropertiesCommand(
    ...[properties]: Editor.Edition.CommandArgsMapper['UPDATE_IMAGE_PROPERTIES']
  ): Editor.Edition.Command {
    return new UpdateImagePropertiesCommand(this.context, properties);
  }

  // ----------------------------------------------------------------------------------------------
  //                              Equations commands
  // ----------------------------------------------------------------------------------------------

  private getEditEquationCommand(): Editor.Edition.Command {
    return new EditEquationCommand(this.context);
  }

  private getInsertEquationCommand(
    ...[value]: Editor.Edition.CommandArgsMapper['INSERT_EQUATION']
  ): Editor.Edition.Command {
    return new InsertEquationCommand(this.context, value);
  }

  private getUpdateEquationCommand(
    ...[blockId, elementId, value]: Editor.Edition.CommandArgsMapper['UPDATE_EQUATION']
  ): Editor.Edition.Command {
    return new UpdateEquationCommand(this.context, blockId, elementId, value);
  }

  // ----------------------------------------------------------------------------------------------
  //                              Citations commands
  // ----------------------------------------------------------------------------------------------

  private getInsertCitationCommand(
    ...[citationId]: Editor.Edition.CommandArgsMapper['INSERT_CITATION']
  ): Editor.Edition.Command {
    return new InsertCitationCommand(this.context, citationId);
  }

  // ----------------------------------------------------------------------------------------------
  //                              Hyperlinks commands
  // ----------------------------------------------------------------------------------------------

  private getInsertHyperlinkCommand(
    ...[
      links,
      url,
      showTextToDisplay,
      textToDisplay,
    ]: Editor.Edition.CommandArgsMapper['INSERT_HYPERLINK']
  ): Editor.Edition.Command {
    return new InsertHyperlinkCommand(this.context, links, url, showTextToDisplay, textToDisplay);
  }

  private getDeleteHyperlinkCommand(): Editor.Edition.Command {
    return new DeleteHyperlinkCommand(this.context);
  }

  // ----------------------------------------------------------------------------------------------
  //                              Notes commands
  // ----------------------------------------------------------------------------------------------

  private getInsertNoteCommand(
    ...[type, text]: Editor.Edition.CommandArgsMapper['INSERT_NOTE']
  ): Editor.Edition.Command {
    return new InsertNoteCommand(this.context, type, text);
  }

  // ----------------------------------------------------------------------------------------------
  //                              Table commands
  // ----------------------------------------------------------------------------------------------

  private getDeleteTableCommand(): Editor.Edition.Command {
    return new DeleteTableCommand(this.context);
  }

  private getInsertTableCommand(
    ...[rows, columns]: Editor.Edition.CommandArgsMapper['INSERT_TABLE']
  ): Editor.Edition.Command {
    return new InsertTableCommand(this.context, rows, columns);
  }

  private getTableOperationCommand(
    ...[operationArgs]: Editor.Edition.CommandArgsMapper['TABLE_OPERATION']
  ): Editor.Edition.Command {
    return new TableOperationCommand(this.context, operationArgs);
  }

  private getUpdateColumnWidthsCommand(
    ...[table, columnWidths]: Editor.Edition.CommandArgsMapper['UPDATE_COLUMN_WIDTHS']
  ): Editor.Edition.Command {
    return new UpdateColumnWidthsCommand(this.context, table.id, columnWidths);
  }

  private getUpdateRowHeigthsCommand(
    ...[table, rowHeights]: Editor.Edition.CommandArgsMapper['UPDATE_ROW_HEIGTHS']
  ): Editor.Edition.Command {
    return new UpdateRowHeigthsCommand(this.context, table.id, rowHeights);
  }

  private getUpdateTablePropertiesCommand(
    ...[propertiesData]: Editor.Edition.CommandArgsMapper['UPDATE_TABLE_PROPERTIES']
  ): Editor.Edition.Command {
    return new UpdateTablePropertiesCommand(this.context, propertiesData);
  }

  private getUpdateTableSizeCommand(
    ...[table, height, width]: Editor.Edition.CommandArgsMapper['UPDATE_TABLE_SIZE']
  ): Editor.Edition.Command {
    return new UpdateTableSizeCommand(this.context, table.id, height, width);
  }

  // ----------------------------------------------------------------------------------------------
  //                              Clipboard commands
  // ----------------------------------------------------------------------------------------------

  protected getPasteCommand(
    ...[parsedData, pasteOption]: Editor.Edition.CommandArgsMapper['PASTE']
  ) {
    return new PasteCommand(this.context, parsedData, pasteOption);
  }

  protected getCutCommand(...[event]: Editor.Edition.CommandArgsMapper['CUT']) {
    return new CutCommand(this.context, event);
  }

  // ----------------------------------------------------------------------------------------------
  //                              Content commands
  // ----------------------------------------------------------------------------------------------

  private getDeleteContentCommand(
    ...[deleteOptions]: Editor.Edition.CommandArgsMapper['DELETE_CONTENT']
  ): Editor.Edition.Command | null {
    return new DeleteCommand(this.context, null, deleteOptions);
  }

  // ----------------------------------------------------------------------------------------------
  //                              Styles commands
  // ----------------------------------------------------------------------------------------------

  private getApplyStylesCommand(
    ...[stylesToApply, range, options]: Editor.Edition.CommandArgsMapper['APPLY_STYLES_COMMAND']
  ) {
    return new StylesCommand(this.context, stylesToApply, {}, range, options);
  }

  private getRemoveStylesCommand(
    ...[stylesToRemove, range, options]: Editor.Edition.CommandArgsMapper['REMOVE_STYLES_COMMAND']
  ) {
    return new StylesCommand(this.context, {}, stylesToRemove, range, options);
  }

  private getUpdateBlockPropertiesCommand(
    ...[action, properties]: Editor.Edition.CommandArgsMapper['UPDATE_BLOCK_PROPERTIES']
  ): Editor.Edition.Command | null {
    return new UpdateBlockPropertiesCommand(this.context, action, properties);
  }
}
