import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { ELEMENTS } from 'Editor/services/consts';
import { Logger } from '_common/services';
import { BaseManipulator } from '../Common/Base';
import { InsertElementOperation } from '../../Operations';
import { InsertBlockOperation } from '../../Operations/StructureOperations';

export class InsertManipulator
  extends BaseManipulator
  implements Editor.Edition.IInsertManipulator
{
  insertContent(
    ctx: Editor.Edition.ActionContext,
    path: Editor.Selection.Path,
    dataToInsert: Editor.Data.Node.Data | string,
    options: Editor.Edition.InsertContentOptions = {},
  ): boolean {
    if (this.editionContext.debug) {
      Logger.trace('SuggestionsManipulator insertContet', ctx, ctx.baseModel, dataToInsert);
    }

    // TODO
    // check if path is valid
    // is selection editable
    // is insertion allowed
    // check styles to apply

    if (!this.editionContext.DataManager) {
      return false;
    }

    const baseModel = this.editionContext.DataManager.nodes.getNodeModelById(ctx.range.start.b);
    const baseData = baseModel?.selectedData();

    if (!baseModel || !baseData) {
      return false;
    }

    const result = NodeUtils.getParentChildInfoByPath(baseData, path);

    if (!result?.parentData) {
      return false;
    }

    let type: Editor.Data.Node.DataTypes;
    if (typeof dataToInsert === 'string') {
      type = 'text';
    } else {
      type = dataToInsert.type;
    }

    // is insertion allowed
    if (
      !NodeUtils.isAllowedUnder(result.parentData.type, type) ||
      NodeUtils.isRestrictedUnder(baseData.type, type)
    ) {
      Logger.warn('Element insertion not allowed!!', baseData.type, result.parentData.type, type);
      return false;
    }

    const closestTracked = NodeUtils.closestOfTypeByPath(
      baseData,
      path,
      ELEMENTS.TrackInsertElement.ELEMENT_TYPE,
    );

    let operation;

    if (
      closestTracked &&
      NodeUtils.isTrackedData(closestTracked.data) &&
      this.isUserAuthor(closestTracked.data)
    ) {
      // closest element is a tracked action

      // user is the tracked action author
      if (NodeUtils.isParagraphMarker(closestTracked.data)) {
        // either way this condition shouldn't hapen

        ctx.suggestionRef = closestTracked.data.properties.element_reference;

        const newTrackedData = this.buildNewTrackedInsert(
          dataToInsert,
          closestTracked.data.properties.element_reference,
        );
        options.pathFix ??= 'TEXT_END';

        if (newTrackedData) {
          operation = this.getInsertOperation(
            baseModel,
            closestTracked.path,
            newTrackedData,
            options,
          );
          if (operation) {
            operation.apply();
            let resultPath = operation?.getAdjustedPath();
            if (resultPath) {
              ctx.addSuggestionLocation(baseModel.id, resultPath);
            }
          }
        }
      } else {
        // insert content
        operation = this.getInsertOperation(baseModel, path, dataToInsert, options);
        if (operation) {
          operation.apply();
          ctx.addSuggestionLocation(baseModel.id, path);
        }
      }
    } else {
      const selectionData = NodeUtils.getParentChildInfoByPath(baseData, path);

      // check previous sibling
      let checkPrevious;
      if (NodeUtils.isTextData(selectionData?.childData)) {
        if (selectionData?.contentIndex === 0) {
          checkPrevious = true;
        }
      } else {
        checkPrevious = true;
      }

      let previousAncestor;
      if (checkPrevious) {
        previousAncestor = NodeUtils.getPreviousAncestor(baseData, path);
      }

      // check next sibling
      let checkNext;
      if (NodeUtils.isTextData(selectionData?.childData)) {
        if (selectionData?.contentIndex === selectionData?.childData.content.length) {
          checkNext = true;
        }
      } else {
        checkNext = true;
      }

      let nextAncestor;
      if (checkNext) {
        nextAncestor = NodeUtils.getNextAncestor(baseData, path);
      }

      let pathToInsert: Editor.Selection.Path = path;
      let elementToInsert: Editor.Data.Node.Data | string | undefined;

      if (
        previousAncestor &&
        NodeUtils.isTrackedData(previousAncestor.data) &&
        this.isUserAuthor(previousAncestor.data)
      ) {
        if (
          NodeUtils.isTrackInsertData(previousAncestor.data) &&
          !NodeUtils.isParagraphMarker(previousAncestor.data)
        ) {
          pathToInsert = [
            ...previousAncestor.path,
            'childNodes',
            previousAncestor.data.childNodes?.length || 0,
          ];
          elementToInsert = dataToInsert;
        } else {
          ctx.suggestionRef = previousAncestor.data.properties.element_reference;

          const newTrackedData = this.buildNewTrackedInsert(dataToInsert, ctx.suggestionRef);
          if (newTrackedData) {
            elementToInsert = newTrackedData;
          }
        }
      } else if (
        nextAncestor &&
        NodeUtils.isTrackedData(nextAncestor.data) &&
        this.isUserAuthor(nextAncestor.data)
      ) {
        if (
          NodeUtils.isTrackInsertData(nextAncestor.data) &&
          !NodeUtils.isParagraphMarker(nextAncestor.data)
        ) {
          pathToInsert = [...nextAncestor.path, 'childNodes', 0];
          elementToInsert = dataToInsert;
        } else {
          ctx.suggestionRef = nextAncestor.data.properties.element_reference;
          const newTrackedData = this.buildNewTrackedInsert(dataToInsert, ctx.suggestionRef);
          if (newTrackedData) {
            elementToInsert = newTrackedData;
          }
        }
      } else {
        // create track insert with text and insert
        const newTrackedData = this.buildNewTrackedInsert(dataToInsert, ctx.suggestionRef);
        if (newTrackedData) {
          elementToInsert = newTrackedData;
        }
      }

      if (elementToInsert) {
        options.pathFix ??= 'TEXT_END';
        operation = this.getInsertOperation(baseModel, pathToInsert, elementToInsert, options);
        if (operation) {
          operation.apply();
          let resultPath = operation?.getAdjustedPath();
          if (resultPath) {
            ctx.addSuggestionLocation(baseModel.id, resultPath);
          }
        }
      }
    }

    // adjust selection
    if (operation) {
      const resultPath = operation.getAdjustedPath();
      if (ctx.range && resultPath) {
        ctx.range.updateRangePositions({
          b: ctx.range.start.b,
          p: resultPath,
        });
      }
    }

    return true;
  }

  insertBlock(
    ctx: Editor.Edition.ActionContext,
    newBlockData: Editor.Data.Node.Data,
    position: 'BEFORE' | 'AFTER' = 'AFTER',
    options: Editor.Edition.InsertBlockOptions = {},
  ): boolean {
    if (this.editionContext.debug) {
      Logger.trace('SuggestionsManipulator insertBlock', ctx);
    }

    if (!this.editionContext.DataManager) {
      return false;
    }

    const structureModel = this.editionContext.DataManager?.structure.structureModel;

    // IMPORTANT: avoid outdated data
    let baseModel = this.editionContext.DataManager.nodes.getNodeModelById(ctx.range.start.b);
    let baseData = baseModel?.selectedData();

    if (
      !baseModel ||
      !baseData ||
      !this.editionContext.DataManager ||
      !structureModel ||
      !NodeUtils.isBlockTypeData(newBlockData)
    ) {
      return false;
    }

    // is insertion allowed
    if (!NodeUtils.isBlockInsertionAllowed(baseData, ctx.range.start.p, newBlockData)) {
      throw new Error('Element insertion not allowed!!' + newBlockData.type);
    }

    let closestBlock = NodeUtils.closestOfTypeByPath(baseData, ctx.range.start.p, [
      ...NodeUtils.BLOCK_TYPES,
    ]);

    if (!closestBlock) {
      return false;
    }

    const result = NodeUtils.getParentChildInfoByPath(baseData, closestBlock.path);

    let blockData: Editor.Data.Node.Data = closestBlock.data;
    let blockDataPath: Editor.Selection.Path = closestBlock.path;

    let sblingData: Editor.Data.Node.Data | null | undefined;

    if (NodeUtils.isDoubleTypeData(result?.parentData)) {
      options.outsideContainer = true;
    }

    if (blockDataPath.length === 0 || options.outsideContainer) {
      let siblingBlock: Editor.Data.Node.Model | undefined;
      if (position === 'AFTER') {
        // INSERT AFTER
        siblingBlock = this.editionContext.DataManager.nodes.getNextModelById(baseModel.id);
      } else {
        // INSERT BEFORE
        siblingBlock = this.editionContext.DataManager.nodes.getPreviousModelById(baseModel.id);
      }
      sblingData = siblingBlock?.selectedData();
    } else {
      let blockIndex = Number(blockDataPath[blockDataPath.length - 1]);
      if (!isNaN(blockIndex)) {
        if (position === 'AFTER') {
          sblingData = result?.parentData.childNodes?.[blockIndex + 1];
        } else {
          sblingData = result?.parentData.childNodes?.[blockIndex - 1];
        }
      }
    }

    let blockRefId = baseModel.id;

    // copy task
    if (sblingData?.tasks?.[0] != null && sblingData?.tasks?.[0] === blockData.tasks?.[0]) {
      if (newBlockData.tasks) {
        newBlockData.tasks.push(sblingData.tasks[0]);
      } else {
        newBlockData.tasks = [sblingData.tasks[0]];
      }
    }

    let dataToInsert: Editor.Data.Node.Data | undefined;
    if (!NodeUtils.isBlockTextData(newBlockData)) {
      dataToInsert = this.buildNewTrackedInsert(newBlockData, ctx.suggestionRef);
    } else if (newBlockData.childNodes) {
      const trackInsert = this.buildNewTrackedInsert(newBlockData.childNodes, ctx.suggestionRef);
      if (trackInsert) {
        trackInsert.parent_id = newBlockData.id;
        newBlockData.childNodes = [trackInsert];
        dataToInsert = newBlockData;
      }
    } else {
      dataToInsert = newBlockData;
    }

    if (blockDataPath.length === 0 || options.outsideContainer) {
      // insert block in documentx

      if (NodeUtils.isTableData(newBlockData)) {
        // check if needs to insert a paragraph
        if (NodeUtils.isTableData(blockData)) {
          const paragraphData = NodeDataBuilder.buildParagraph({
            parent_id: baseData.parent_id,
          });

          if (paragraphData?.id) {
            const op = new InsertBlockOperation(
              this.editionContext.DataManager,
              structureModel,
              paragraphData,
              blockRefId,
              position,
            );

            op.apply();

            blockRefId = paragraphData.id;
          }
        } else if (!sblingData || NodeUtils.isTableData(sblingData)) {
          const paragraphData = NodeDataBuilder.buildParagraph({
            parent_id: baseData.parent_id,
          });

          if (paragraphData?.id) {
            const op = new InsertBlockOperation(
              this.editionContext.DataManager,
              structureModel,
              paragraphData,
              blockRefId,
              position,
            );

            op.apply();
          }
        }
      }

      if (dataToInsert) {
        const op = new InsertBlockOperation(
          this.editionContext.DataManager,
          structureModel,
          dataToInsert,
          blockRefId,
          position,
        );
        op.apply();

        // update range position
        const resultPath = op.getAdjustedPath();
        if (resultPath && dataToInsert.id) {
          ctx.range.updateRangePositions({
            b: dataToInsert.id,
            p: resultPath,
          });

          let previousModel;
          let previousData;
          if (position === 'AFTER') {
            previousModel = baseModel;
            previousData = blockData;
          } else {
            previousModel = this.editionContext.DataManager.nodes.getPreviousModelById(
              dataToInsert.id,
            );
            previousData = previousModel?.selectedData();
          }

          baseModel = this.editionContext.DataManager.nodes.getNodeModelById(dataToInsert.id);
          baseData = baseModel?.selectedData();
          if (baseModel && baseData) {
            ctx.setModelAndData(baseModel, baseData);

            ctx.addSuggestionLocation(baseModel.id, ['childNodes', 0]);

            // insert split marker
            if (previousModel && NodeUtils.isParagraphData(previousData)) {
              this.handleInsertSplitMarker(
                previousModel,
                ['childNodes', previousData.childNodes?.length || 0],
                baseModel,
                ['childNodes', 0],
                ctx.suggestionRef,
              );
            }
          }
        }
      }
    } else {
      // insert block inside container
      let blockIndex = Number(blockDataPath[blockDataPath.length - 1]);
      if (isNaN(blockIndex)) {
        return false;
      }

      if (NodeUtils.isTableData(newBlockData)) {
        // check if needs to insert a paragraph
        if (NodeUtils.isTableData(blockData)) {
          const paragraphData = NodeDataBuilder.buildParagraph({
            parent_id: blockData.parent_id,
          });

          let pathToInsert = [...blockDataPath];
          if (position === 'AFTER') {
            blockIndex = blockIndex + 1;
            pathToInsert[pathToInsert.length - 1] = blockIndex;
          }
          if (paragraphData) {
            const op = new InsertElementOperation(baseModel, pathToInsert, paragraphData);
            op.apply();
          }
        } else if (!sblingData || NodeUtils.isTableData(sblingData)) {
          const paragraphData = NodeDataBuilder.buildParagraph({
            parent_id: blockData.parent_id,
          });

          let pathToInsert = [...blockDataPath];
          if (position === 'AFTER') {
            pathToInsert[pathToInsert.length - 1] = blockIndex + 1;
          }

          if (paragraphData) {
            const op = new InsertElementOperation(baseModel, pathToInsert, paragraphData);
            op.apply();
          }
        }
      }

      let pathToInsert = [...blockDataPath];
      if (position === 'AFTER') {
        pathToInsert[pathToInsert.length - 1] = blockIndex + 1;
      }

      if (dataToInsert) {
        const op = new InsertElementOperation(baseModel, pathToInsert, dataToInsert, {
          pathFix: 'TEXT_END',
        });
        op.apply();

        // update range position
        const resultPath = op.getAdjustedPath();
        if (resultPath) {
          ctx.range.updateRangePositions({
            b: baseModel.id,
            p: resultPath,
          });
        }

        ctx.addSuggestionLocation(baseModel.id, [...pathToInsert, 'childNodes', 0]);

        let previousData;
        let previousPath;

        if (position === 'AFTER') {
          previousData = blockData;
          previousPath = blockDataPath;
        } else {
          previousPath = pathToInsert;
          let offset = +previousPath[previousPath.length - 1];
          if (!isNaN(offset)) {
            previousPath[previousPath.length - 1] = offset - 1;
          }
          previousData = NodeUtils.getChildDataByPath(baseData, previousPath);
        }

        if (previousData && previousPath) {
          this.handleInsertSplitMarker(
            baseModel,
            [...previousPath, 'childNodes', previousData.childNodes?.length || 0],
            baseModel,
            [...pathToInsert, 'childNodes', 0],
            ctx.suggestionRef,
          );
        }
      }
    }

    return true;
  }
}
