import { CSSProperties } from 'react';
import useTextBodyProperties from './../useTextBodyProperties';

import TextParagraph from './TextParagraph/TextParagraph';

import styles from './TextBody.module.scss';
import TextBodyProvider from './TextBodyContext';
import { useSlideData } from '../../SlideData';

type TextBodyProps = {
  text: Presentation.Data.TextBody;
  /**Define css properties for the whole text that will be applied for each paragraph of the text
   * These properties will override the already defined properties of the paragraphs' style
   * */
  textStyle?: CSSProperties;
};

const TextBody = ({ text, textStyle }: TextBodyProps) => {
  const style = useTextBodyProperties(text);
  const { isThumbnailSlide } = useSlideData();

  return (
    <TextBodyProvider text={text} textStyle={textStyle}>
      <div
        style={style}
        className={styles.textbody}
        id={isThumbnailSlide ? `thumbnail-${text.id}` : text.id}
        data-type="textbody"
      >
        {text.childNodes?.map((p, pIndex) => (
          <TextParagraph key={p.id} paragraph={p} paragraphIndex={pIndex} />
        ))}
      </div>
    </TextBodyProvider>
  );
};

export default TextBody;
