import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export type RightPanel = 'details' | 'comments' | 'tasks' | 'versionhistory' | null;
type PresentationSliceState = {
  presentationId: ObjectId | null;
  showNav: boolean;
  showNotes: boolean;
  zoom: number;
  rightPanel: RightPanel;
  currentSlide: number;
  lastSlideViewed: number | null;
  creating: {
    slide: boolean;
    type: 'comment' | 'task';
    anchor?: Presentation.Common.PresentationAnchor;
  } | null;
  cursorMode: 'normal' | 'pan';
  versionHistory: boolean;
  loadedVersion: number | null;
  elementToFocus?: {
    objectType: 'comment' | 'task' | 'suggestion' | 'node';
    objectId: ObjectId;
    documentId: ObjectId;
  };
  selectedShape: string | null;
  selectedCard: string | null;
  unsupportedElements: string[];
  fontsBySlide: {
    [x: string]:
      | {
          [x: string]: boolean;
        }
      | undefined;
  };
};

const SLICE_NAME = 'presentation';
const initialState: PresentationSliceState = {
  presentationId: null,
  showNav: false,
  showNotes: false,
  zoom: 1,
  rightPanel: null,
  currentSlide: 1,
  lastSlideViewed: null,
  creating: null,
  cursorMode: 'normal',
  versionHistory: false,
  loadedVersion: null,
  selectedShape: null,
  selectedCard: null,
  unsupportedElements: [],
  fontsBySlide: {},
};

const PresentationSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setPresentationId: (state, action: PayloadAction<PresentationSliceState['presentationId']>) => {
      state.presentationId = action.payload;
    },
    setShowNav: (state, action: PayloadAction<boolean>) => {
      state.showNav = action.payload;
    },
    setShowNotes: (state, action: PayloadAction<boolean>) => {
      state.showNotes = action.payload;
    },
    setZoom: (state, action: PayloadAction<number>) => {
      let newZoomValue = action.payload;
      const defaultZoomValue = [
        '0.1',
        '0.25',
        '0.5',
        '0.75',
        '1',
        '1.25',
        '1.5',
        '2',
        '4',
        '8',
        '16',
        '24',
        '32',
        '64',
      ];
      if (isNaN(newZoomValue)) {
        state.zoom = 1;
      } else {
        newZoomValue = Math.max(0.1, Math.min(4, action.payload));

        if (
          defaultZoomValue.filter((defaultValue) => Number(defaultValue) === action.payload)
            .length === 0
        ) {
          const multipiedValue = newZoomValue * 100;
          const roundValue = Math.round(multipiedValue / 10) * 10;
          newZoomValue = roundValue / 100;
        }

        state.zoom = newZoomValue;
      }
    },
    setRightPanel: (state, action: PayloadAction<RightPanel>) => {
      state.rightPanel = action.payload;
    },
    setCurrentSlide: (state, action: PayloadAction<number>) => {
      //Track the last viewed slide
      state.lastSlideViewed = state.currentSlide;

      //Reset unsupported elements as they are defined by slide
      state.unsupportedElements = [];

      state.currentSlide = action.payload;
    },
    setCreating: (state, action: PayloadAction<PresentationSliceState['creating']>) => {
      if (!action.payload) {
        state.creating = null;
        return;
      }

      state.creating = {
        ...action.payload,
        anchor: action.payload.anchor && JSON.parse(JSON.stringify(action.payload.anchor)),
      };
    },
    setCursorMode: (state, action: PayloadAction<PresentationSliceState['cursorMode']>) => {
      state.cursorMode = action.payload;
    },
    setVersionHistory: (state, action: PayloadAction<PresentationSliceState['versionHistory']>) => {
      state.versionHistory = action.payload;
    },
    setLoadedVersion: (state, action: PayloadAction<PresentationSliceState['loadedVersion']>) => {
      state.loadedVersion = action.payload;
    },
    setElementToFocus: (state, action: PayloadAction<PresentationSliceState['elementToFocus']>) => {
      state.elementToFocus = action.payload;
    },
    setSelectedShape: (state, action: PayloadAction<PresentationSliceState['selectedShape']>) => {
      state.selectedShape = action.payload;
    },
    setSelectedCard: (state, action: PayloadAction<PresentationSliceState['selectedCard']>) => {
      state.selectedCard = action.payload;
      state.selectedShape = null;
      state.creating = null;
    },
    addUnsupportedElement: (state, action: PayloadAction<string>) => {
      state.unsupportedElements.push(action.payload);
    },
    setSlideFont: (state, action: PayloadAction<{ slideId: string; font: string }>) => {
      const { slideId, font } = action.payload;

      const slideFonts = state.fontsBySlide[slideId];

      state.fontsBySlide = {
        ...state.fontsBySlide,
        [slideId]: {
          ...slideFonts,
          [font]: true,
        },
      };
    },
  },
});

const persistConfig = {
  key: 'general',
  storage,
  whitelist: ['elementToFocus'],
};

const PresentationReducer = persistReducer(persistConfig, PresentationSlice.reducer);

export const {
  setPresentationId,
  setShowNav,
  setShowNotes,
  setZoom,
  setRightPanel,
  setCurrentSlide,
  setCreating,
  setCursorMode,
  setVersionHistory,
  setLoadedVersion,
  setElementToFocus,
  setSelectedShape,
  setSelectedCard,
} = PresentationSlice.actions;

/**
 * Use this callback instead of the action directly to avoid unnecessary multiple redux requests
 * if the data its already stored
 */
export const setSlideFont =
  ({ slideId, font }: { slideId: string; font: string }) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const slideFonts = getState().presentation.general.fontsBySlide[slideId];

    if (slideFonts?.[font]) {
      return;
    }

    dispatch(PresentationSlice.actions.setSlideFont({ slideId, font }));
  };

export const addUnsupportedElement =
  (element: string) => (dispatch: Dispatch, getState: () => RootState) => {
    const unsupportedElements = getState().presentation.general.unsupportedElements;

    const splitWords = element.split(' ');

    const formatedElement = splitWords
      .map((word) =>
        word
          /**
           * Two possibilities to split the string:
           *  Split when non-digit character is followed by digit
           *  Split when lowercase alphabetic character is followed by uppercase alphabetic character
           */
          .split(/(?<=\D)(?=\d)|(?<=[a-z])(?=[A-Z])/)
          .map((subWord) => subWord.charAt(0).toUpperCase() + subWord.slice(1))
          .join(' '),
      )
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    if (!unsupportedElements.includes(formatedElement)) {
      dispatch(PresentationSlice.actions.addUnsupportedElement(formatedElement));
    }
  };

export default PresentationReducer;
