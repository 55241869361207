import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { BaseOperation } from './BaseOperation';
import { RealtimeOpsBuilder } from '_common/services/Realtime';

export class InsertElementOperation extends BaseOperation<Editor.Data.Node.Model> {
  protected path: Editor.Selection.Path;
  private elementData: Editor.Data.Node.Data;
  private options: Editor.Edition.InsertContentOptions;

  constructor(
    model: Editor.Data.Node.Model,
    path: Editor.Selection.Path,
    elementData: Editor.Data.Node.Data,
    options?: Editor.Edition.InsertContentOptions,
  ) {
    super(model);
    this.path = path;
    this.elementData = elementData;

    this.options = {
      pathFix: 'AFTER',
      mergeText: true,
      allowAll: false,
      ...options,
    };
    this.build();
  }

  private adjustPathToContent(pathToElement: Editor.Selection.Path): Editor.Selection.Path {
    if (this.options.pathFix === 'TEXT_END') {
      if (NodeUtils.isTextData(this.elementData)) {
        pathToElement.push('content');
        pathToElement.push(this.elementData.content.length);
      } else {
        let childNodes: Editor.Data.Node.Data[] | undefined = this.elementData.childNodes;

        while (childNodes) {
          let childOffset = 0;
          if (childNodes.length > 0) {
            childOffset = childNodes.length - 1;
          }

          const lastChild: Editor.Data.Node.Data = childNodes[childOffset];

          if (NodeUtils.isTextData(lastChild)) {
            pathToElement.push('childNodes');
            pathToElement.push(childOffset);
            pathToElement.push('content');
            pathToElement.push(lastChild.content.length);
            childNodes = undefined;
          } else if (
            NodeUtils.isSupportedInlineData(lastChild) /* && check if is editable */ &&
            !NodeUtils.isNonEditableInlineData(lastChild) &&
            lastChild.childNodes
          ) {
            pathToElement.push('childNodes');
            pathToElement.push(childOffset);
            childNodes = lastChild.childNodes;
          } else {
            pathToElement.push('childNodes');
            pathToElement.push(childOffset + 1);
            break;
          }
        }
      }
    } else if (this.options.pathFix === 'AFTER') {
      let childOffset = Number(pathToElement[pathToElement.length - 1]);
      if (!isNaN(childOffset)) {
        pathToElement[pathToElement.length - 1] = childOffset + 1;
      }
    }

    return pathToElement;
  }

  private insertInChildNodes() {
    const pathLenth = this.path.length;
    let pathToParent = this.path.slice(0, pathLenth - 2);
    let childOffset = Number(this.path[pathLenth - 1]);

    const parentdata = this.model.getChildDataByPath(pathToParent);

    if (NodeUtils.isElementData(parentdata) && !isNaN(childOffset)) {
      const previousElement = parentdata.childNodes?.[childOffset - 1];
      const nextElement = parentdata.childNodes?.[childOffset];
      if (
        NodeUtils.isTextData(this.elementData) &&
        (NodeUtils.isTextData(previousElement) || NodeUtils.isTextData(nextElement)) &&
        this.options.mergeText
      ) {
        if (NodeUtils.isTextData(previousElement) && NodeUtils.isTextData(nextElement)) {
          // if previous and next are text elements join them
          let path: Editor.Selection.Path = [
            ...pathToParent,
            'childNodes',
            childOffset - 1,
            'content',
            previousElement.content.length,
          ];
          this.ops.push(RealtimeOpsBuilder.stringInsert(this.elementData.content, path));

          this.preOpPath = [...path];
          this.preOpPosition = {
            b: this.model.id,
            p: [...path],
          };
          this.resultPath = [
            ...pathToParent,
            'childNodes',
            childOffset - 1,
            'content',
            previousElement.content.length + this.elementData.content.length,
          ];

          path = [
            ...pathToParent,
            'childNodes',
            childOffset - 1,
            'content',
            previousElement.content.length + this.elementData.content.length,
          ];
          this.ops.push(RealtimeOpsBuilder.stringInsert(nextElement.content, path));

          this.ops.push(
            RealtimeOpsBuilder.listDelete(nextElement, [
              ...pathToParent,
              'childNodes',
              childOffset,
            ]),
          );
        } else if (NodeUtils.isTextData(previousElement)) {
          // insert text into previous text
          const path: Editor.Selection.Path = [
            ...pathToParent,
            'childNodes',
            childOffset - 1,
            'content',
            previousElement.content.length,
          ];
          this.ops.push(RealtimeOpsBuilder.stringInsert(this.elementData.content, path));
          this.preOpPath = [...path];
          this.preOpPosition = {
            b: this.model.id,
            p: [...path],
          };
          this.resultPath = [
            ...pathToParent,
            'childNodes',
            childOffset - 1,
            'content',
            previousElement.content.length + this.elementData.content.length,
          ];
        } else if (NodeUtils.isTextData(nextElement)) {
          // insert text into next text
          const path: Editor.Selection.Path = [
            ...pathToParent,
            'childNodes',
            childOffset,
            'content',
            0,
          ];
          this.ops.push(RealtimeOpsBuilder.stringInsert(this.elementData.content, path));
          this.preOpPath = [...path];
          this.preOpPosition = {
            b: this.model.id,
            p: [...path],
          };
          this.resultPath = [
            ...pathToParent,
            'childNodes',
            childOffset,
            'content',
            0 + this.elementData.content.length,
          ];
        }
      } else {
        // insert text as child element
        if (!NodeUtils.isTextData(this.elementData)) {
          this.elementData.parent_id = parentdata.id;
        }

        this.ops.push(RealtimeOpsBuilder.listInsert(this.elementData, this.path));
        this.preOpPath = [...this.path];
        this.preOpPosition = {
          b: this.model.id,
          p: [...this.path],
        };

        this.resultPath = this.adjustPathToContent([...this.path]);
      }
    }
  }

  private insertInContent() {
    const pathLenth = this.path.length;
    let pathToParent = this.path.slice(0, pathLenth - 4);
    let childOffset = Number(this.path[pathLenth - 3]);
    let contentOffset = Number(this.path[pathLenth - 1]);

    const parentData = this.model.getChildDataByPath(pathToParent);
    const textData = parentData.childNodes?.[childOffset];

    if (NodeUtils.isElementData(parentData) && !isNaN(childOffset)) {
      if (NodeUtils.isTextData(textData) && !isNaN(contentOffset)) {
        if (NodeUtils.isTextData(this.elementData) && this.options.mergeText) {
          // element to insert is text

          this.ops.push(RealtimeOpsBuilder.stringInsert(this.elementData.content, this.path));
          this.preOpPath = [...this.path];
          this.preOpPosition = {
            b: this.model.id,
            p: [...this.path],
          };
          this.resultPath = [
            ...pathToParent,
            'childNodes',
            childOffset,
            'content',
            contentOffset + this.elementData.content.length,
          ];
        } else {
          // element to insert is not text

          const contentLength = textData.content.length;
          let contentToSplit: string | undefined;

          let path: Editor.Selection.Path;

          if (contentOffset === 0) {
            path = [...pathToParent, 'childNodes', childOffset];
          } else if (contentOffset === contentLength) {
            path = [...pathToParent, 'childNodes', childOffset + 1];
          } else {
            contentToSplit = textData.content.slice(contentOffset, contentLength);

            if (contentToSplit.length) {
              this.ops.push(RealtimeOpsBuilder.stringDelete(contentToSplit, this.path));
            }

            path = [...pathToParent, 'childNodes', childOffset + 1];
          }

          if (!NodeUtils.isTextData(this.elementData)) {
            this.elementData.parent_id = parentData.id;
          }

          this.ops.push(RealtimeOpsBuilder.listInsert(this.elementData, path));

          this.preOpPath = [...path];
          this.preOpPosition = {
            b: this.model.id,
            p: [...path],
          };

          this.resultPath = this.adjustPathToContent([...path]);

          if (contentToSplit?.length) {
            // create text element
            const textData = NodeDataBuilder.buildData({
              type: 'text',
              content: contentToSplit,
            });

            this.ops.push(
              RealtimeOpsBuilder.listInsert(textData, [
                ...pathToParent,
                'childNodes',
                childOffset + 2,
              ]),
            );
          }
        }
      } else {
        this.path = this.path.slice(0, this.path.length - 2);
        this.insertInChildNodes();
      }
    }
  }

  protected build(): Editor.Edition.IOperationBuilder {
    const pathLenth = this.path.length;

    // validations
    if (
      !this.options.allowAll &&
      NodeUtils.isTextData(this.elementData) &&
      this.elementData.content.length === 0
    ) {
      return this;
    }

    if (this.path[pathLenth - 2] === 'childNodes') {
      this.insertInChildNodes();
    } else if (this.path[pathLenth - 2] === 'content') {
      this.insertInContent();
    }

    return this;
  }
}
