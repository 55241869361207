import { useEffect, useMemo } from 'react';
import useOutline from '../../useOutline';
import useChartColor from './useChartColor';
import { useSlideData } from 'Presentation/Slides/Slide/SlideData';
import useUnsupportedProperties from './useUnsupportedProperties';

const useChartPlotArea = (
  plotArea?: Presentation.Data.ChartShape['chartSpace']['chart']['plotArea'],
) => {
  const { addUnsupportedElement } = useSlideData();
  const { chartColor } = useChartColor();
  const { parseOutline } = useOutline();
  const { handleUnsupportedShapeEffects } = useUnsupportedProperties();

  useEffect(() => {
    //TODO:PRESENTATION:UNSUPPORTED:CHART:PLOTAREA:EFFECTS
    handleUnsupportedShapeEffects(plotArea?.properties, 'Chart - Plot Area');

    //TODO:PRESENTATION:UNSUPPORTED:CHART:SERIE:EFFECTS
    plotArea?.chartTypes?.forEach((charType) => {
      charType.ser.forEach((serie) => {
        handleUnsupportedShapeEffects(serie.properties, 'Chart - Serie');
      });
    });
  }, [plotArea]);

  const backgroundColor = useMemo(() => {
    switch (plotArea?.properties.fill?.type) {
      case 'picture':
        if (plotArea.properties.fill.tile) {
          //TODO:PRESENTATION:UNSUPPORTED:PLOTAREA:FILL:TEXTURE
          addUnsupportedElement('Chart - Plot Area - Texture Fill');
        } else {
          //TODO:PRESENTATION:UNSUPPORTED:PLOTAREA:FILL:PICTURE
          addUnsupportedElement('Chart - Plot Area - Picture Fill');
        }
        return 'transparent';
      case 'pattern':
        //TODO:PRESENTATION:UNSUPPORTED:PLOTAREA:FILL:PATTERN
        addUnsupportedElement('Chart - Plot Area - Pattern Fill');
        return 'transparent';
      default:
        return chartColor(plotArea?.properties.fill);
    }
  }, [plotArea?.properties.fill]);

  const borderColor = useMemo(() => {
    switch (plotArea?.properties.ln?.fill?.type) {
      case 'solid':
        //TODO:PRESENTATION:UNSUPPORTED:PLOTAREA:LINE:SOLID
        addUnsupportedElement('Chart - Plot Area - Solid Line');
        break;
      case 'gradient':
        //TODO:PRESENTATION:UNSUPPORTED:PLOTAREA:LINE:GRADIENT
        addUnsupportedElement('Chart - Plot Area - Gradient Line');
        break;
    }
  }, [plotArea?.properties.ln?.fill]);

  if (plotArea) {
    const values = parseOutline(plotArea.properties.ln);

    return {
      show:
        plotArea.properties.fill?.type === 'none' || !plotArea.properties.fill?.type ? false : true,
      backgroundColor,
      borderColor,
      borderWidth: values.strokeWidth,
      borderType: values.strokeDasharray?.split(',').map((value) => +value),
      cap: values.strokeLinecap,
      join: values.strokeLinejoin,
      miterLimit: values.strokeMiterlimit,
      containLabel: true,
    };
  }
  return null;
};

export default useChartPlotArea;
